import { useContext } from "react"
import { FirmContext } from "../../../../contexts/FirmContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import Container from "../components/Container"
import Footer from "../components/Footer"
import AttitudeToRisk from "./AttitudeToRisk"
import SensitivityToLoss from "./SensitivityToLoss"

const RiskChartsPage = ({ page, client, household }: { page: number; client: Client; household: Household }) => {
  const { firm } = useContext(FirmContext)
  const theme = useTheme()
  return (
    <Container id="risk-charts-report-page" className="flex flex-col px-10 pt-10 gap-y-3 relative w-full">
      {theme.reports?.riskChartsPage?.showPreferencesHeader && (
        <div className="mb-5">
          <h2 className="mb-1 text-main-600 text-sm leading-4 tracking-wide uppercase">Risk Preferences</h2>
          <h3 className="text-main-600 font-semibold text-p">
            Two important components to understanding you and your comfort with investment risk are your Attitude to Risk and your Sensitivity to Loss.
          </h3>
        </div>
      )}
      <AttitudeToRisk client={client} household={household} page={page} />
      <SensitivityToLoss client={client} household={household} page={page} />
      <div className="relative h-full">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default RiskChartsPage
