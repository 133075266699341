import { t } from "@lingui/macro"
import { clsx } from "clsx"
import React, { ReactNode } from "react"
import { useTheme } from "../../contexts/ThemeContext"
import { shortRound } from "../../lib/numbers"
import NumberInput from "../NumberInput/NumberInput"
import Select from "../Select/Select"
import { tt } from "../../lib/translations"
import ErrorMessage from "../Error/ErrorMessage"

export type FrequencyOption = "weekly" | "fortnightly" | "monthly" | "quarterly" | "annually"

interface Props {
  id: string
  onAmountChange: (value?: number) => void
  onFrequencyChange?: (value: FrequencyOption) => void
  className?: string
  amountValue?: number
  customNote?: ReactNode | string
  frequencyOptions?: FrequencySelect[]
  frequencyValue?: string
  isError?: boolean
  error?: ReactNode | string
  label?: ReactNode | string
  max?: number
  min?: number
  placeholder?: string
  inputClassName?: string
  inputFieldClassName?: string
  selectContainerClassName?: string
  selectClassName?: string
}

export interface FrequencySelect {
  id: string
  label: string
  value: FrequencyOption
}

const AmountFrequencyInput: React.FC<Props> = ({
  amountValue,
  customNote,
  frequencyValue,
  frequencyOptions,
  id,
  className,
  isError,
  error,
  label,
  max,
  min,
  onAmountChange,
  onFrequencyChange,
  placeholder,
  inputClassName,
  inputFieldClassName,
  selectContainerClassName,
  selectClassName
}) => {
  const theme = useTheme()
  const currency = t({ id: "currency-symbol" })
  const errorText =
    min !== undefined && max !== undefined
      ? tt({
          id: `amount-input-range-error-text-${id}`,
          message: `Please add a value between ${currency + shortRound(min).value + (shortRound(min).unit ?? "")} and ${
            currency + shortRound(max).value + (shortRound(max).unit ?? "")
          }`
        })
      : undefined
  const options = frequencyOptions ?? [
    {
      id: "option-1",
      label: tt({ id: "frequency-option-weekly" }),
      value: "weekly"
    },
    {
      id: "option-2",
      label: tt({ id: "frequency-option-fortnightly" }),
      value: "fortnightly"
    },
    {
      id: "option-3",
      label: tt({ id: "frequency-option-monthly" }),
      value: "monthly"
    },
    {
      id: "option-4",
      label: tt({ id: "frequency-option-annually" }),
      value: "annually"
    }
  ]

  return (
    <fieldset className="w-full">
      <legend className="text-sec leading-4 font-semibold text-grey-600 mb-1">{label}</legend>
      <div className={clsx("amount-frequency-input flex flex-row", { "shadow-err border-red-500": isError || !!error }, className)}>
        <div className="flex flex-col">
          {label && (
            <label htmlFor={`${id}-input`} className="sr-only">
              {label}
            </label>
          )}
          <NumberInput
            locale={theme.defaultLocale}
            name={`${id}-input`}
            onChange={onAmountChange}
            className={clsx("flex-grow", inputClassName)}
            inputClassName={inputFieldClassName}
            placeholder={placeholder}
            prefix={<div className="pl-3 text-sec font-bold">{currency}</div>}
            value={amountValue}
          />
        </div>
        {frequencyValue && onFrequencyChange && (
          <div className={clsx("flex flex-col", selectContainerClassName)}>
            <label htmlFor={`${id}-select`} className="sr-only">
              Amount frequency
            </label>
            <Select
              id={`${id}-select`}
              className={selectClassName}
              inputClassName="border-l-0 !pl-2.5 !pr-4.5 font-bold text-sm"
              options={options}
              onChange={({ target: { value } }) => {
                onFrequencyChange(value as FrequencyOption)
              }}
              value={frequencyValue}
            />
          </div>
        )}
      </div>
      {customNote && <p className="savings-contribution-note text-sm leading-2 text-grey-300 mt-1 block">{customNote}</p>}
      <div role="alert">{(isError || error) && <ErrorMessage id="amount-frequency" message={error ?? errorText} />}</div>
    </fieldset>
  )
}

export default AmountFrequencyInput
