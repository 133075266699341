import React, { useState, useRef } from "react"
export type Code = {
  length: number
  label?: string
  loading?: boolean
  onComplete: (v: any) => void
}
const InputCode = ({ length, loading, onComplete }: Code) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""))
  const inputs = useRef<(HTMLInputElement | null)[]>([])

  const processInput = (evt: React.ChangeEvent<HTMLInputElement>, slot: number) => {
    const num = evt.target.value
    if (/[^0-9]/.test(num)) return
    const newCode = [...code]
    newCode[slot] = num
    setCode(newCode)
    if (slot !== length - 1) {
      inputs.current[slot + 1]?.focus()
    }
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""))
    }
  }
  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, slot: number) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code]
      newCode[slot - 1] = ""
      setCode(newCode)
      inputs.current[slot - 1]?.focus()
    }
  }

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const paste = e.clipboardData.getData("text").slice(0, length)
    if (/[^0-9]/.test(paste)) return

    const newCode = paste.split("").slice(0, length)
    setCode([...newCode, ...code.slice(newCode.length)])

    // Focus next input after the last pasted input
    const nextInput = inputs.current[newCode.length]
    if (nextInput) {
      nextInput.focus()
    } else {
      onComplete(newCode.join(""))
    }
  }

  return (
    <div className="">
      <div className="flex gap-[10px] justify-center">
        {code.map((num, idx) => {
          return (
            <input
              className="code-input border-0.5 h-[48px] w-[48px] text-center outline-none border-interactive-500 focus:outline-none focus:shadow-focus"
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
              onPaste={onPaste}
            />
          )
        })}
      </div>
    </div>
  )
}
export default InputCode