import { useMemo } from "react"
import { tt } from "../../../../lib/translations"
import { Client, Goal, GoalDetail } from "../../../../models/Client"
import { Household } from "../../../../models/Household"

type PFSGoalLabelProps = {
  type: Goal
  goalDetail?: GoalDetail
  clientOrHousehold?: Client | Household
}

const isHousehold = (value: any): value is Household => {
  return value && typeof value === "object" && "members" in value
}

const PFSGoalLabel: React.FC<PFSGoalLabelProps> = ({ type, goalDetail, clientOrHousehold }) => {
  const label = useMemo(() => {
    if (type === "createOwnGoal" && goalDetail) {
      return `${goalDetail?.name} goal contributions`
    }
    if (type === "education") {
      if (goalDetail?.recipient?.value === "me") {
        return "Education goal contributions"
      }
      if (isHousehold(clientOrHousehold)) {
        switch (goalDetail?.recipient?.value) {
          case "member1":
            return `${clientOrHousehold.members?.[0]?.client.firstName}'s education goal contributions`
          case "member2":
            return `${clientOrHousehold.members?.[1]?.client.firstName}'s education goal contributions`
          default:
            return "Education goal contributions"
        }
      }
      switch (goalDetail?.recipient?.value) {
        case "other":
        case "child":
        case "grandchild":
          return `${goalDetail?.recipient?.name}'s education goal contributions`
        default:
          return "Education goal contributions"
      }
    }
    return tt({ id: `financial-statement-expenses-goals-${type}-label` })
  }, [type, goalDetail, clientOrHousehold])

  return <span>{label}</span>
}

export default PFSGoalLabel
