import React, { useEffect, useMemo, useRef, useState } from "react"
import { longRound, shortRound } from "../../../../../lib/numbers"
import { useTheme } from "../../../../../contexts/ThemeContext"
import useNumberFormatter from "../../../../../hooks/useNumberFormatter"
import { tt } from "../../../../../lib/translations"

interface DataPointProps {
  forReport?: boolean
  style?: {
    fill: string
  }
  x?: number
  y?: number
  chartWidth: number
  paddingRight: number
  datum?: { x: number; y: number }
  setTargetDateLine: React.Dispatch<
    React.SetStateAction<{
      x: number
      y: number
    }>
  >
  currency?: string
  showCurrencyUnit?: boolean
  isFormatCurrency?: boolean
  shouldLongRound?: boolean
}
const DataPoint = ({ x, y, chartWidth, paddingRight, datum, setTargetDateLine, currency, showCurrencyUnit, isFormatCurrency, shouldLongRound }: DataPointProps) => {
  const theme = useTheme()
  const textRef = useRef<SVGTextElement>(null)
  const [width, setWidth] = useState(50)
  const height = 17
  const offSet = height / 2
  const gap = 2
  const { value, unit } = useMemo(() => shortRound(datum?.y ?? 0), [datum?.y])
  const formatCurrency = useNumberFormatter(theme.defaultLocale ?? "")

  const tooltipXpos = x! + width + paddingRight
  const orientation = tooltipXpos > chartWidth ? x! - (width + offSet + gap) : x! + offSet + gap

  useEffect(() => {
    setTargetDateLine({ x: x!, y: y! })
  }, [setTargetDateLine, x, y])

  useEffect(() => {
    if (textRef.current) {
      const bbox = textRef.current.getBBox()
      setWidth(bbox.width + 12)
    }
  }, [])

  const amount = useMemo(() => {
    const valToFormat = datum?.y ?? 0
    return shouldLongRound ? longRound(valToFormat).toLocaleString() : isFormatCurrency ? formatCurrency.format(valToFormat) : value
  }, [datum?.y, formatCurrency, isFormatCurrency, value, shouldLongRound])

  return (
    <g>
      <circle className="goal-projection-chart-datapoint-outer-circle stroke-interactive-500 fill-none" strokeWidth={2} cx={x!} cy={y!} r={8} />
      <circle className="goal-projection-chart-datapoint-inner-circle fill-interactive-500" cx={x!} cy={y!} r={4} />
      <rect className="goal-projection-chart-datapoint-label fill-interactive-500" x={orientation} y={y! - offSet} width={width} height={height} rx={8} />
      <text
        className="goal-projection-chart-datapoint-tooltip-text fill-white font-semibold"
        dominantBaseline="middle"
        textAnchor="middle"
        x={orientation + width / 2}
        y={y! + 1}
        width={width}
        height={height}
        fontSize={10}
        ref={textRef}
        wordSpacing="-3"
      >
        {tt({
          id: "currency-amount",
          values: { currency, amount: `${amount}${showCurrencyUnit ? unit : ""}` }
        })}
      </text>
    </g>
  )
}

export default DataPoint
