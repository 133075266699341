import { useNavigate, useParams } from "react-router"
import Checkbox from "../components/Checkbox/Checkbox"
import useClient from "../../../hooks/useClient"
import arrowLeft from "../assets/images/arrow-left.svg"
import useHousehold from "../../../hooks/useHousehold"
import SidebarHeader from "../components/SidebarHeader/SidebarHeader"
import { useContext, useEffect, useMemo, useState } from "react"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { ClientUpdateRequest } from "../../../api/clients"
import { HouseholdUpdateRequest } from "../../../api/households"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import { latest } from "../../../lib/clients"
import ErrorMessage from "../../../components/Error/ErrorMessage"

const RMJRiskAttitudeSideBar = () => {
  const navigate = useNavigate()
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [reviewInvestments, setReviewInvestments] = useState<boolean>(false)
  const [status, setStatus] = useState<"updating" | "error">()
  const game = latest((client ?? household)!, "risk")
  const existing = useMemo(() => {
    const actionItems = game?.actionItems
    return actionItems?.find((item) => item.key === "review-your-current-investments")
  }, [game?.actionItems])

  useEffect(() => {
    setReviewInvestments(!!(existing && Boolean(existing.value)))
  }, [existing])
  const onNext = () => {
    if (reviewInvestments !== !!(existing && Boolean(existing.value))) {
      const actionItems = game?.actionItems
      const req: ClientUpdateRequest | HouseholdUpdateRequest = {
        actionItems: existing
          ? actionItems!.map((x) => (x.key === "review-your-current-investments" ? { ...x, value: reviewInvestments } : x))
          : [
              ...(actionItems || []),
              {
                key: "review-your-current-investments",
                value: reviewInvestments
              }
            ]
      }
      setStatus("updating")
      const updatePromise = client ? updateClient(client._id, req) : updateHousehold(household!._id, [], req)
      updatePromise
        .then(() => {
          navigate("../loss-sensitivity")
        })
        .catch(() => {
          setStatus("error")
          console.error("error updating action items")
        })
    } else {
      navigate("../loss-sensitivity")
    }
  }

  return (
    <div className="w-80 h-full bg-interactive-600 flex flex-1 flex-col justify-between">
      {(client || household) && (
        <>
          <div className="flex gap-x-2.5 overflow-y-auto no-scrollbar">
            <div className="flex flex-col">
              {client && <SidebarHeader client={client} />}
              {household && <SidebarHeader household={household} />}
              <div className="sidebar-section">
                <h3 className="sidebar-section-title text-sec">Attitude to Risk score</h3>
                <p className="sidebar-section-text text-sec">
                  Your Attitude to Risk score reflects the level of risk you are willing to take compared with other investors.
                </p>
              </div>
              {client && (
                <div className="sidebar-section">
                  <h3 className="sidebar-section-title text-sec">Discuss</h3>
                  {client.existingClient ? (
                    <p className="sidebar-section-text text-sec">
                      Your Attitude to Risk may have changed since the last time we checked in. Has your financial life changed in any way since the last time
                      we talked?
                    </p>
                  ) : (
                    <p className="sidebar-section-text text-sec">How would you describe the way you take investment risk today?</p>
                  )}
                </div>
              )}
              {household && (
                <div className="sidebar-section">
                  <h3 className="sidebar-section-title text-sec">Discuss</h3>
                  {household.existingClient ? (
                    <p className="sidebar-section-text text-sec">
                      Your Attitude to Risk may have changed since the last time we checked in. Has your financial life changed in any way since the last time
                      we talked?
                    </p>
                  ) : (
                    <p className="sidebar-section-text text-sec">How would you describe the way you take investment risk today?</p>
                  )}
                </div>
              )}
              <div className="sidebar-section">
                <h3 className="sidebar-section-title text-sec">Actions</h3>
                <Checkbox
                  name="review-investment"
                  label={<span className="text-sec">Review your current investments</span>}
                  checked={reviewInvestments}
                  onChange={() => setReviewInvestments(!reviewInvestments)}
                />
              </div>
            </div>
          </div>
          <div role="alert">
            {status === "error" && <ErrorMessage id="rmj-risk-attitude-side-bar" message="Error updating the details" />}
          </div>
          <div className="px-4 pb-4 w-full flex justify-between items-center">
            <button onClick={() => navigate(-1)} className="cursor-pointer w-10">
              <img className="mt-3" src={arrowLeft} alt="arrow left icon" />
            </button>
            <button className="btn btn-medium btn-primary-alt mt-4 h-14 grow" onClick={onNext} disabled={status === "updating"}>
              {status === "updating" ? <Loading type="dots" /> : "Next"}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default RMJRiskAttitudeSideBar
