import React, { useCallback, useMemo, ReactNode, useState, ButtonHTMLAttributes, useContext, useEffect } from "react"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./DatePicker.css"
import { addYears, format, isValid, parse } from "date-fns"
import { DATE_FORMAT, range, subtractYears } from "../../lib/date"
import calendar from "./calendar.svg"
import dropdownArrow from "./dropdown-arrow.svg"
import clsx from "clsx"
import { FirmContext } from "../../contexts/FirmContext"
import ErrorMessage from "../Error/ErrorMessage"
interface Props {
  id: string
  isDisabled?: boolean
  dateFormat?: string
  error?: string
  label?: string | ReactNode
  yearsInAdvance?: number
  yearsInPast?: number
  onChange: (value: Date | null, rawDate?: string) => void
  onChangeRaw?: (value: string) => void
  value: Date | null
  placeholder?: string | ReactNode
  labelClassName?: string
  customInput?: ReactNode
  defaultSelectedDate?: Date
  isInline?: boolean
  popperPlacement?: PopperPlacement
  inputClassName?: string
}

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

type PopperPlacement =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "right"
  | "right-start"
  | "right-end"
  | "left"
  | "left-start"
  | "left-end"

const DatePicker = ({
  id,
  isDisabled = false,
  dateFormat = DATE_FORMAT,
  error,
  label,
  yearsInAdvance = 40,
  yearsInPast = 100,
  onChange,
  onChangeRaw,
  placeholder: _placeholder,
  value,
  labelClassName,
  customInput,
  defaultSelectedDate,
  isInline = false,
  popperPlacement = "bottom-end",
  inputClassName
}: Props) => {
  const { firm } = useContext(FirmContext)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedRawDate, setSelectedRawDate] = useState<string>(value ? format(value, firm?.uiConfig?.dateFormat ?? dateFormat) : "")
  const years = useMemo(() => range(new Date().getFullYear() - yearsInPast, yearsInPast + yearsInAdvance + 1), [yearsInPast, yearsInAdvance])

  useEffect(() => {
    const dateFormatPattern = firm?.uiConfig?.dateFormat ?? dateFormat
    const parsedDate = selectedRawDate ? parse(selectedRawDate, dateFormatPattern, new Date()) : null
    const isRawDateValid = parsedDate && isValid(parsedDate) && format(parsedDate, dateFormatPattern) === selectedRawDate
    if (value && (!selectedRawDate || (isRawDateValid && format(value, dateFormatPattern) !== selectedRawDate))) {
      setSelectedRawDate(format(value, dateFormatPattern))
    } else if (!value) {
      setSelectedRawDate("")
    }
  }, [dateFormat, firm?.uiConfig?.dateFormat, selectedRawDate, value])

  const getYear = useCallback((date: Date) => {
    return date.getFullYear()
  }, [])

  const getMonth = useCallback((date: Date) => {
    return date.getMonth()
  }, [])

  const onToggleOpen = () => {
    setIsOpen((prev) => !isDisabled && !prev)
  }

  const CustomInput1 = (props: React.DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, _ref: React.Ref<HTMLButtonElement>) => {
    return (
      <button {...props} onClick={onToggleOpen}>
        {customInput}
      </button>
    )
  }

  return (
    <div className={clsx("date-picker", isInline ? "inline-block" : "flex flex-col flex-shrink-0 w-full")}>
      {label && (
        <label className={clsx(labelClassName, "date-picker-label w-full flex-shrink-0 text-sec mb-1", isDisabled && "text-main-200")} htmlFor={id}>
          {label}{" "}
        </label>
      )}
      <div className="relative">
        <ReactDatePicker
          className={clsx(
            "date-picker-wrapper w-full h-full flex items-center text-p bg-white overflow-hidden flex-grow outline-none",
            {
              "shadow-err border-red-500": !!error,
              "border-main-400": !error && !isDisabled,
              "border-main-200 text-main-200": isDisabled,
              "text-main-500": !isDisabled
            },
            isInline ? "" : "border focus:shadow-focus px-2 py-3 pr-10",
            inputClassName
          )}
          wrapperClassName="react-date-picker-wrapper-custom"
          popperClassName="!z-50"
          dateFormat={firm?.uiConfig?.dateFormat ?? dateFormat}
          placeholderText={(firm?.uiConfig?.dateFormat ?? dateFormat).toLowerCase()}
          disabled={isDisabled}
          dropdownMode="select"
          customInput={customInput && React.createElement(React.forwardRef(CustomInput1))}
          id={id}
          minDate={subtractYears(new Date(), yearsInPast)}
          maxDate={addYears(new Date(), yearsInAdvance)}
          onChange={(val, event) => {
            onChange(val, !isInline ? (event?.target as HTMLInputElement).value : undefined)
          }}
          // onChangeRaw={(raw) => {
          //   const parsedDate = parse(raw.target.value, dateFormat, new Date())
          //   console.log("raw iinput", raw.target.value, parsedDate, isValid(parsedDate))
          //   if (isValid(parsedDate)) {
          //     onChange(parsedDate)
          //   }
          // }}
          onChangeRaw={
            onChangeRaw
              ? (event) => {
                  setSelectedRawDate(event.target.value)
                  onChangeRaw(event.target.value)
                }
              : undefined
          }
          onClickOutside={() => setIsOpen(false)}
          onFocus={() => setIsOpen(false)}
          onSelect={() => setIsOpen(false)}
          open={isOpen}
          popperModifiers={[
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: true,
                altAxis: false
              }
            },
            {
              name: "flip",
              options: {
                fallbackPlacements: ["bottom-end", "top-end"],
                padding: 8
              }
            }
          ]}
          portalId="root-portal"
          popperPlacement={popperPlacement as PopperPlacement}
          renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
            <div className="flex justify-between items-center gap-2 pt-3">
              <div className="flex justify-start w-full ml-4 gap-1">
                <select
                  className="w-6/12 appearance-none text-sec font-bold text-interactive-500 bg-no-repeat bg-right bg-[transparent] focus:outline-2 focus:outline-grape-300 pr-3 py-1"
                  onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                  style={{ backgroundImage: `url(${dropdownArrow})`, backgroundPosition: "right 8px center" }}
                  value={months[getMonth(date)]}
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  className="w-4/12 appearance-none text-sec font-bold text-interactive-500 bg-no-repeat bg-right bg-[transparent] focus:outline-2 focus:outline-grape-300 px-3 py-1"
                  onChange={({ target: { value } }) => changeYear(parseInt(value))}
                  style={{ backgroundImage: `url(${dropdownArrow})`, backgroundPosition: "right 8px center" }}
                  value={getYear(date)}
                >
                  {years.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex mx-4 gap-2">
                <button
                  type="button"
                  className="focus:outline-2 focus:outline-grape-300 w-6 h-6 flex items-center justify-center pr-[1px]"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.666 4.33318H2.75598L5.17798 1.42652C5.41398 1.14385 5.37531 0.723184 5.09264 0.48785C4.80931 0.25185 4.38931 0.290517 4.15398 0.573184L0.820644 4.57318C0.794644 4.60452 0.781311 4.64118 0.761978 4.67585C0.745978 4.70385 0.726644 4.72785 0.714644 4.75852C0.684644 4.83518 0.666644 4.91585 0.666644 4.99718C0.666644 4.99785 0.665977 4.99918 0.665977 4.99985C0.665977 5.00052 0.666644 5.00185 0.666644 5.00252C0.666644 5.08385 0.684644 5.16452 0.714644 5.24118C0.726644 5.27185 0.745978 5.29585 0.761978 5.32385C0.781311 5.35852 0.794644 5.39518 0.820644 5.42652L4.15398 9.42652C4.28598 9.58452 4.47531 9.66652 4.66598 9.66652C4.81664 9.66652 4.96798 9.61585 5.09264 9.51185C5.37531 9.27652 5.41398 8.85585 5.17798 8.57318L2.75598 5.66652H10.666C11.034 5.66652 11.3326 5.36785 11.3326 4.99985C11.3326 4.63185 11.034 4.33318 10.666 4.33318Z"
                      fill="#31366B"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  className="focus:outline-2 focus:outline-grape-300 w-6 h-6 flex items-center justify-center pl-[1px]"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.33398 5.66685L9.24398 5.66685L6.82198 8.57351C6.58598 8.85618 6.62465 9.27685 6.90732 9.51218C7.19065 9.74818 7.61065 9.70951 7.84598 9.42685L11.1793 5.42685C11.2053 5.39551 11.2187 5.35885 11.238 5.32418C11.254 5.29618 11.2733 5.27218 11.2853 5.24151C11.3153 5.16485 11.3333 5.08418 11.3333 5.00285C11.3333 5.00218 11.334 5.00085 11.334 5.00018C11.334 4.99951 11.3333 4.99818 11.3333 4.99751C11.3333 4.91618 11.3153 4.83551 11.2853 4.75885C11.2733 4.72818 11.254 4.70418 11.238 4.67618C11.2187 4.64151 11.2053 4.60485 11.1793 4.57351L7.84598 0.573513C7.71398 0.415513 7.52465 0.333514 7.33398 0.333514C7.18332 0.333514 7.03198 0.38418 6.90732 0.488181C6.62465 0.723514 6.58598 1.14418 6.82198 1.42685L9.24398 4.33351L1.33398 4.33351C0.965985 4.33351 0.667318 4.63218 0.667318 5.00018C0.667318 5.36818 0.965985 5.66685 1.33398 5.66685Z"
                      fill="#31366B"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
          selected={value || defaultSelectedDate}
          showMonthDropdown
          showPopperArrow={false}
          showYearDropdown
          value={selectedRawDate}
        />
        {!customInput && (
          <button
            className={clsx("absolute top-0 right-0 h-full flex items-center px-4 py-3", isDisabled && "opacity-25")}
            type="button"
            disabled={isDisabled}
            onClick={onToggleOpen}
          >
            <img alt="" src={calendar} />
          </button>
        )}
      </div>
      <div role="alert">{error && <ErrorMessage id="date-picker" message={error} />}</div>
    </div>
  )
}

export default DatePicker
