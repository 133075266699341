import { clsx } from "clsx"
import { AnimatePresence } from "framer-motion"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { useBlocker } from "react-router"
import axiosInstance from "../../../../api/axiosInstance"
import AlertPopover, { AlertPopoverOptions } from "../../../../components/AlertPopover/AlertPopover"
import ClientStatusAlert, { StatusAlert } from "../../../../components/ClientProfile/ClientStatusRow/ClientStatusAlert"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import Modal from "../../../../components/Modal/Modal"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import useClientFinances from "../../../../hooks/useClientFinances"
import { usePreventUnload } from "../../../../hooks/usePreventUnload"
import { customDateFormat } from "../../../../lib/date"
import { deepEqual } from "../../../../lib/utils"
import { Client, GoalsDetails, MAX_AMOUNT, PersonalFinancialDetails } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import downloadIcon from "../../../advisor/assets/images/download.svg"
import warning from "../../ImportClientsPage/assets/warning.svg"
import AssetsList from "./AssetsList/AssetsList"
import ExpensesList from "./ExpensesList/ExpensesList"
import IncomeList from "./IncomeList/IncomeList"
import LiabilitiesList from "./LiabilitiesList/LiabilitiesList"
import CommentsSection from "../components/Comments/CommentsSection"
import ErrorMessage from "../../../../components/Error/ErrorMessage"
import { AuthContext } from "../../../../views/auth/AuthContext"

const PFSTab = ({ client, household, isEmbedded }: { client?: Client; household?: Household; isEmbedded?: boolean }) => {
  const { replace, updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const { sessionInfo } = useContext(AuthContext)
  const clientOrHousehold = client ?? household
  const lastUpdatedDate = clientOrHousehold?.pfs?.updatedAt
  const hasBeenUpdated = lastUpdatedDate || (clientOrHousehold?.goals?.goalDetails?.length ?? 0) > 0
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false)
  const finances = useClientFinances(clientOrHousehold)
  const [infoAlert, setInfoAlert] = useState<StatusAlert>()
  const [PFSData, setPFSData] = useState<PersonalFinancialDetails>()
  const [goalsData, setGoalsData] = useState<GoalsDetails>()

  const { mutate: update, status: updateState } = useMutation({
    mutationFn: () => {
      return household
        ? updateHousehold(
            household!._id!,
            household.members.map(() => ({ pfs: { ...PFSData }, goals: { ...goalsData } })),
            { pfs: { ...PFSData }, goals: { ...goalsData } }
          ).then(() => setIsEditing(false))
        : updateClient(client!._id, { pfs: { ...PFSData }, goals: { ...goalsData } }).then(() => setIsEditing(false))
    }
  })

  useEffect(() => {
    const { assets, liabilities, expenses, income } = clientOrHousehold?.pfs || {}
    setPFSData({ assets, liabilities, income, expenses })
  }, [clientOrHousehold?.pfs])

  useEffect(() => {
    setGoalsData(clientOrHousehold?.goals)
  }, [clientOrHousehold?.goals])

  const generateReport = useCallback(() => {
    setIsGeneratingReport(true)
    setInfoAlert({
      message: "Generating...",
      timeout: false,
      type: "activity"
    })
    axiosInstance
      .post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/${household ? "household" : "client"}/${clientOrHousehold?._id}/generate-report/pfs`, {})
      .then((res) => res.data as Client | Household)
      .then((data) => {
        replace(data)
        setIsGeneratingReport(false)
        setInfoAlert(undefined)
      })
      .catch((err) => {
        console.error("Error generating the report", err)
        setInfoAlert({
          message: "Error generating the report",
          timeout: true,
          onClose: () => setInfoAlert(undefined),
          type: "error"
        })
      })
  }, [clientOrHousehold?._id, household, replace])

  const alertPopoverOptions: AlertPopoverOptions | undefined = useMemo(() => {
    if (infoAlert) {
      return {
        content: <ClientStatusAlert type={infoAlert.type} message={infoAlert.message} />,
        onClose: infoAlert.onClose,
        timeoutMS: infoAlert.timeout === false ? null : 5000,
        location: infoAlert.location
      }
    }
  }, [infoAlert])

  const hasChanged = useMemo(() => !deepEqual(clientOrHousehold?.pfs, PFSData), [clientOrHousehold?.pfs, PFSData])
  const blocker = useBlocker(() => isEditing && hasChanged)
  usePreventUnload(isEditing && hasChanged)

  const [error, setError] = useState<string>()
  const onSave = () => {
    if (
      !(
        (PFSData?.assets?.filter((a) => a.amount && a.amount > MAX_AMOUNT).length ?? 0) > 0 ||
        (PFSData?.liabilities?.filter((a) => a.amount && a.amount > MAX_AMOUNT).length ?? 0) > 0 ||
        (PFSData?.income?.filter((a) => a.amount && a.amount > MAX_AMOUNT).length ?? 0) > 0 ||
        (PFSData?.expenses?.filter((a) => a.amount && a.amount > MAX_AMOUNT).length ?? 0) > 0
      )
    ) {
      update()
    } else {
      setError("Please fix the above errors and try again")
    }
  }

  const reportUrl = clientOrHousehold?.pfs?.reportUrl
    ? `${clientOrHousehold?.pfs?.reportUrl}${
        isEmbedded ? `&_ssoToken=${sessionInfo?.idToken}&_ssoAccessToken=${sessionInfo?.accessToken}&_ssoRefreshToken=${sessionInfo?.refreshToken}` : ""
      }`
    : null

  return (
    <div className={clsx("pfs-tab mb-24", isEmbedded ? "px-3" : "px-16")}>
      <div>
        <h1 className="sr-only">Personal financial statement</h1>
        {hasBeenUpdated && (
          <section className="pt-10">
            <div className="flex justify-between items-center">
              {lastUpdatedDate && <p>Statement last updated {customDateFormat(lastUpdatedDate)}</p>}
              {reportUrl ? (
                <a
                  href={reportUrl}
                  target="_blank"
                  rel="noreferrer"
                  aria-disabled={!lastUpdatedDate}
                  className={clsx(
                    "btn btn-text btn-text-medium flex gap-2 items-center text-sec font-bold",
                    !lastUpdatedDate && "opacity-50 hover:bg-transparent"
                  )}
                >
                  <span>View report</span>
                  <img src={downloadIcon} alt="" aria-hidden />
                </a>
              ) : (
                <AlertPopover options={alertPopoverOptions} show={!!infoAlert}>
                  <button
                    className="btn btn-text btn-text-medium flex gap-2 items-center text-sec font-bold"
                    onClick={generateReport}
                    disabled={isGeneratingReport}
                  >
                    <span>Generate report</span>
                    <img src={downloadIcon} alt="" aria-hidden />
                  </button>
                </AlertPopover>
              )}
            </div>
          </section>
        )}
        <section className="flex flex-col gap-6 py-10">
          <div className="flex justify-between items-start w-full gap-y-6">
            <h2 className="reports-pfs-net-worth-page-title text-h3 font-semibold mb-1">Financial Net worth statement</h2>
            {lastUpdatedDate && (
              <div className="flex flex-col">
                <p className="text-h2 font-semibold text-main-400">{finances.formatted.netWorth}</p>
                <p className="text-sm text-right">Net worth</p>
              </div>
            )}
          </div>
          <div className="flex gap-9">
            <AssetsList client={client} household={household} pfsData={PFSData} onChange={setPFSData} isEditing={isEditing} />
            <LiabilitiesList client={client} household={household} pfsData={PFSData} onChange={setPFSData} isEditing={isEditing} />
          </div>
        </section>
        <hr className="border-surface-300" />
        <section className="flex flex-col gap-6 py-10">
          <div className="flex justify-between items-start w-full gap-y-6">
            <h2 className="reports-pfs-net-worth-page-title text-h3 font-semibold mb-1">Income statement</h2>
            {hasBeenUpdated && (
              <div className="flex flex-col">
                <p className="text-h2 font-semibold text-main-400">{finances.formatted.balance}</p>
                <p className="text-sm text-right">{finances.balanceLabel}</p>
              </div>
            )}
          </div>
          <div className="flex gap-9">
            <IncomeList client={client} household={household} pfsData={PFSData} onChange={setPFSData} isEditing={isEditing} />
            <ExpensesList
              client={client}
              household={household}
              pfsData={PFSData}
              goalsData={goalsData}
              onChange={setPFSData}
              onGoalsChange={setGoalsData}
              isEditing={isEditing}
            />
          </div>
        </section>
        <hr className="border-surface-300" />
        <CommentsSection gameType="pfs" client={client} household={household} />
      </div>
      <div className="absolute bottom-0 left-0 bg-white w-full py-4 px-6 shadow-300 text-right">
        {isEditing ? (
          <div className="flex justify-end items-center gap-x-5">
            <div role="alert">
              {updateState === "error" || (error && <ErrorMessage id="pfs-tab" message={error || "Error saving the details, please try again"} />)}
            </div>
            <button
              className="btn btn-secondary btn-medium w-24"
              onClick={() => {
                setPFSData(clientOrHousehold?.pfs)
                setIsEditing(false)
              }}
            >
              Cancel
            </button>
            <button className="btn btn-primary btn-medium w-24" onClick={() => onSave()} disabled={updateState === "loading" || !hasChanged}>
              {updateState === "loading" ? <Loading type="dots" /> : <>Save</>}
            </button>
          </div>
        ) : (
          <button
            className="btn btn-secondary btn-medium w-24"
            disabled={isGeneratingReport}
            onClick={() => {
              setError(undefined)
              setIsEditing(true)
            }}
          >
            Edit
          </button>
        )}
      </div>
      <AnimatePresence>
        {blocker.state === "blocked" && (
          <Modal className="mx-0" contentClassName="w-modal !px-10" handleClose={() => blocker.reset()}>
            <div className="reprofile-modal-wrapper relative flex flex-col gap-y-6">
              <img className="h-16" src={warning} alt="" aria-hidden />
              <h2 className="text-h2 text-center font-semibold text-main-600">You have unsaved changes</h2>
              <p className="text-p text-center text-main-600">This page contains unsaved changes that will be lost. Do you still wish to leave the page?</p>
              <div className="w-full flex justify-center gap-4">
                <button onClick={() => blocker.reset()} className="btn btn-secondary btn-medium w-44">
                  Cancel
                </button>
                <button onClick={() => blocker.proceed()} className="btn btn-primary btn-medium w-44">
                  Confirm
                </button>
              </div>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default PFSTab
