import { useCallback, useContext, useEffect, useState } from "react"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import ErrorMessage from "../../../../components/Error/ErrorMessage"
import RadioButton from "../../../../components/RadioButton/RadioButton"
import Select from "../../../../components/Select/Select"
import TextInput from "../../../../components/TextInput/TextInput"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Client, EducationType, GoalDetail, RecipientType } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import CardTitle from "./components/CardTitle"
import clsx from "clsx"

type Errors = {
  priority?: string
  goalName?: string
  recipientType?: string
  receipientName?: string
  educationType?: string
}

interface Props {
  currentGoal: GoalDetail
  client?: Client
  household?: Household
  handleClose: () => void
  onUpdate?: (goal: GoalDetail) => void
}

const EditDetails = ({ currentGoal, client, household, handleClose, onUpdate }: Props) => {
  const clientOrHousehold = client ?? household
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const theme = useTheme()
  const [goalDetails, setGoalDetails] = useState<GoalDetail[]>(clientOrHousehold?.goals?.goalDetails ?? [])
  const [errors, setErrors] = useState<Errors>()
  const [shouldValidate, setShouldValidate] = useState<boolean>()
  const [isSaving, setIsSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const [goalName, setGoalName] = useState<string>()
  const [priority, setPriority] = useState<"essential" | "important" | "aspirational" | undefined>(currentGoal.priority)
  const [recipientType, setRecipientType] = useState<RecipientType>()
  const [recipientName, setRecipientName] = useState<string>()
  const [educationType, setEducationType] = useState<EducationType | "" | undefined>("")

  const educationTypeOptions = [
    { id: "no-education-type", value: "", label: "Education type", disabled: true },
    { id: "private-primary", value: "private-primary", label: "Private primary school" },
    { id: "private-secondary", value: "private-secondary", label: "Private secondary school" },
    { id: "vocational-trade", value: "vocational-trade", label: "Vocational/trade" },
    { id: "higher-education", value: "higher-education", label: "Higher education" },
    { id: "other", value: "other", label: "Other" }
  ]

  const validate = useCallback(() => {
    const errs: Errors = {
      priority: !priority ? "Please select a priority for this goal" : undefined,
      goalName: currentGoal?.type === "createOwnGoal" && !goalName?.trim() ? "Please enter a name for this goal" : undefined, // TODO validate char limit
      recipientType: currentGoal?.type === "education" && !recipientType ? "Please select a goal recipient" : undefined,
      receipientName:
        currentGoal?.type === "education" &&
        (recipientType === "child" || recipientType === "grandchild" || recipientType === "other") &&
        !recipientName?.trim()
          ? "Please enter a name"
          : undefined,
      educationType: currentGoal?.type === "education" && !educationType ? "Please select an education type" : undefined
    }
    return errs
  }, [currentGoal?.type, educationType, goalName, priority, recipientName, recipientType])

  const onSave = () => {
    setShouldValidate(true)
    setErrorMessage(undefined)
    const errs = validate()
    setErrors(errs)
    if (Object.values(errs).every((x) => x === null || x === undefined || x === "")) {
      setIsSaving(true)
      const goalData: GoalDetail = {
        ...currentGoal,
        priority,
        name: goalName,
        ...(currentGoal.type === "education" ? { educationType: educationType || undefined, recipient: { name: recipientName, value: recipientType } } : {}),
        ...(currentGoal.type === "education" || currentGoal.type === "createOwnGoal"
          ? !currentGoal.id
            ? { id: String(goalDetails.filter((g) => g.type === currentGoal.type).length + 1) }
            : {}
          : { id: "" })
      }
      const updatedGoalDetails = goalDetails.some((g) => g.type === currentGoal.type && g.id === currentGoal.id)
        ? goalDetails.map((g) => (g.type === currentGoal.type && g.id === currentGoal.id ? goalData : g))
        : [...goalDetails, goalData]

      const sortedGoals = [...updatedGoalDetails].sort((a, b) => {
        const indexA = theme.goals?.findIndex((g) => g.type === a.type) ?? 0
        const indexB = theme.goals?.findIndex((g) => g.type === b.type) ?? 0
        return indexA - indexB
      })
      const goals = {
        ...clientOrHousehold?.goals,
        goalDetails: sortedGoals
      }
      ;(household ? updateHousehold(household!._id!, [], { goals }) : updateClient(client!._id, { goals }))
        .then(() => {
          setIsSaving(false)
          onUpdate?.(goalData)
          handleClose()
        })
        .catch((err) => {
          console.error("error saving the goal details", err)
          setErrorMessage("Error saving the goal details. Please try again.")
        })
    }
  }

  useEffect(() => {
    setGoalName(currentGoal?.name)
    setPriority(currentGoal?.priority)
    setRecipientType(currentGoal?.recipient?.value)
    setRecipientName(currentGoal?.recipient?.name)
    setEducationType(currentGoal?.educationType)
  }, [currentGoal?.educationType, currentGoal?.name, currentGoal?.priority, currentGoal?.recipient?.name, currentGoal?.recipient?.value])

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validate()
      setErrors(nextErrors)
    }
  }, [shouldValidate, validate])

  useEffect(() => {
    setGoalDetails(clientOrHousehold?.goals?.goalDetails ?? [])
  }, [clientOrHousehold?.goals?.goalDetails, goalDetails.length])

  useEffect(() => {
    if (recipientType === "me" || recipientType === "member1" || recipientType === "member2") {
      setRecipientName(undefined)
    }
  }, [recipientType])

  return (
    <div className="w-full flex flex-col gap-6">
      <h1 className="text-left text-h2 font-semibold">
        <CardTitle client={client} household={household} goalDetails={goalDetails} goal={currentGoal} />
      </h1>
      {currentGoal?.type === "createOwnGoal" && (
        <TextInput label="Goal name" name="goal-name" note="Maximum 30 characters" error={errors?.goalName} onChange={setGoalName} value={goalName} />
      )}
      {currentGoal?.type === "education" && (
        <>
          <fieldset>
            <legend className="text-sec font-semibold leading-4 mb-2">Who is this goal for?</legend>
            <div className="flex gap-5">
              {household ? (
                household.members.map((m, i) => (
                  <RadioButton
                    key={i}
                    name="recipient"
                    id={m.client._id}
                    label={m.client.firstName}
                    checked={recipientType === `member${i + 1}`}
                    error={!!errors?.recipientType}
                    value={`member${i + 1}`}
                    onChange={() => setRecipientType(i === 0 ? "member1" : "member2")}
                  />
                ))
              ) : (
                <RadioButton
                  name="recipient"
                  id="me"
                  label="Myself"
                  checked={recipientType === "me"}
                  error={!!errors?.recipientType}
                  value="me"
                  onChange={() => setRecipientType("me")}
                />
              )}
              <RadioButton
                name="recipient"
                id="child"
                label="Child"
                checked={recipientType === "child"}
                error={!!errors?.recipientType}
                value="child"
                onChange={() => setRecipientType("child")}
              />
              <RadioButton
                name="recipient"
                id="grandchild"
                label="Grandchild"
                checked={recipientType === "grandchild"}
                error={!!errors?.recipientType}
                value="grandchild"
                onChange={() => setRecipientType("grandchild")}
              />
              <RadioButton
                name="recipient"
                id="other"
                label="Other"
                checked={recipientType === "other"}
                error={!!errors?.recipientType}
                value="other"
                onChange={() => setRecipientType("other")}
              />
            </div>
            <div role="alert">{errors?.recipientType && <ErrorMessage id="edit-details" message={errors.recipientType} />}</div>
          </fieldset>
          {recipientType &&
            recipientType !== "me" &&
            recipientType !== "member1" &&
            recipientType !== "member2" && ( // We know the client names, don't ask for it
              <TextInput
                name="recipient-name"
                label="What's this person's name?"
                note="Maximum 30 characters"
                error={errors?.receipientName}
                onChange={setRecipientName}
                value={recipientName}
              />
            )}
          <div>
            <Select
              id="no-education-type"
              options={educationTypeOptions}
              label="What type of education are you saving for?"
              onChange={({ target: { value } }) => {
                setEducationType(value as EducationType)
              }}
              error={errors?.educationType}
              value={educationType}
              inputClassName={clsx("bg-[length:2rem_2rem]", { "!text-main-300": !educationType })}
            />
          </div>
        </>
      )}
      <fieldset>
        <legend className="text-sec leading-4 font-semibold mb-2">What's the priority of this goal?</legend>
        <div className="flex gap-5">
          <RadioButton
            name="priority"
            id="essential"
            label="Essential"
            checked={priority === "essential"}
            error={!!errors?.priority}
            value="essential"
            onChange={() => setPriority("essential")}
          />
          <RadioButton
            name="priority"
            id="important"
            label="Important"
            checked={priority === "important"}
            error={!!errors?.priority}
            value="important"
            onChange={() => setPriority("important")}
          />
          <RadioButton
            name="priority"
            id="aspirational"
            label="Aspirational"
            checked={priority === "aspirational"}
            error={!!errors?.priority}
            value="aspirational"
            onChange={() => setPriority("aspirational")}
          />
        </div>
        <div role="alert">{errors?.priority && <ErrorMessage id="goals-tab" message={errors.priority} />}</div>
      </fieldset>
      <div className="flex justify-center gap-5">
        <button className="btn btn-secondary btn-medium w-44" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-primary btn-medium w-44" onClick={onSave} disabled={isSaving}>
          {isSaving ? <Loading type="dots" /> : <>Save</>}
        </button>
      </div>
      <div role="alert" className="sr-only">{errorMessage && <ErrorMessage id="goals-tab-edit-goal" message={errorMessage} />}</div>
    </div>
  )
}

export default EditDetails
