import { Theme } from "../theme"
import welcome from "../../pages/advisor/tour/card-1.png"
import insights from "../../pages/advisor/tour/card-2.png"
import educate from "../../pages/advisor/tour/card-3.png"
import { messages } from "../messages/en"
import { messages as en } from "./locales/en"
import termsOfService from "./markdown/termsOfService"
import privacyPolicy from "./markdown/privacyPolicy"
import billingPolicy from "./markdown/billingPolicy"
import expandCollapseIcon from "./assets/icons/arrowhead-right.svg"
import successImage from "./assets/images/success.svg"
import riskHero from "./assets/images/risk-hero-white.svg"
import goalsHero from "./assets/images/goals-hero-white.svg"
import pfsHero from "./assets/images/pfs-hero-white.svg"
import liquidityHero from "./assets/images/liquidity-hero-white.svg"
import taxHero from "./assets/images/tax-hero-white.svg"
import esgHero from "./assets/images/esg-hero-white.svg"
import retirementHero from "./assets/images/retirement-hero-white.svg"

import riskHeroSecondary from "./assets/images/risk-hero-brown.svg"
import goalsHeroSecondary from "./assets/images/goals-hero-brown.svg"
import pfsHeroSecondary from "./assets/images/pfs-hero-brown.svg"
import liquidityHeroSecondary from "./assets/images/liquidity-hero-brown.svg"
import taxHeroSecondary from "./assets/images/tax-hero-brown.svg"
import esgHeroSecondary from "./assets/images/esg-hero-brown.svg"
import retirementHeroSecondary from "./assets/images/retirement-hero-brown.svg"

import retirementIcon from "./assets/images/goals/retirement.svg"
import homeIcon from "./assets/images/goals/home.svg"
import educationIcon from "./assets/images/goals/education.svg"
import travelIcon from "./assets/images/goals/travel.svg"
import wealthIcon from "./assets/images/goals/wealth.svg"
import ownIcon from "./assets/images/goals/own.svg"

import retirementReportIcon from "./assets/images/goals/reports/retirement.svg"
import homeReportIcon from "./assets/images/goals/reports/home.svg"
import educationReportIcon from "./assets/images/goals/reports/education.svg"
import travelReportIcon from "./assets/images/goals/reports/travel.svg"
import wealthReportIcon from "./assets/images/goals/reports/wealth.svg"
import ownReportIcon from "./assets/images/goals/reports/own.svg"
import diversityCauseIcon from "./assets/images/causes/diversity/hero.svg"
import climateCauseIcon from "./assets/images/causes/climate/hero.svg"
import peopleCauseIcon from "./assets/images/causes/people/hero.svg"
import ethicalBehaviourCauseIcon from "./assets/images/causes/ethical-behaviour/hero.svg"
import naturalResourcesCauseIcon from "./assets/images/causes/natural-resources/hero.svg"
import faithIcon from "./assets/images/causes/faith/hero.svg"
import publicServicesIcon from "./assets/images/causes/public-services/hero.svg"

import sdg3Icon from "./assets/images/causes/people/devGoals/1/03-good-health-wellbeing.svg"
import sdg4Icon from "./assets/images/causes/public-services/devGoals/1/04-quality-education.svg"
import sdg5Icon from "./assets/images/causes/diversity/devGoals/1/05-Gender-equality.svg"
import sdg6Icon from "./assets/images/causes/climate/devGoals/1/06-Clean-water-sanitation.svg"
import sdg7Icon from "./assets/images/causes/climate/devGoals/2/07-Affordable-clean-energy.svg"
import sdg8Icon from "./assets/images/causes/public-services/devGoals/3/08-Decent-work-economic.svg"
import sdg9Icon from "./assets/images/causes/ethical-behaviour/devGoals/1/09-industry-innovation.svg"
import sdg10Icon from "./assets/images/causes/diversity/devGoals/3/10-Reduced-inequality.svg"
import sdg11Icon from "./assets/images/causes/people/devGoals/3/11-sustainable-cities.svg"
import sdg12Icon from "./assets/images/causes/natural-resources/devGoals/1/12-responsible-consumption.svg"
import sdg13Icon from "./assets/images/causes/climate/devGoals/3/13-climate-action.svg"
import sdg14Icon from "./assets/images/causes/natural-resources/devGoals/2/14-life-below-water.svg"
import sdg15Icon from "./assets/images/causes/natural-resources/devGoals/3/15-life-on-land.svg"
import sdg16Icon from "./assets/images/causes/faith/devGoals/1/16-peace-justice.svg"
import sdg17Icon from "./assets/images/causes/ethical-behaviour/devGoals/3/17-partnership-for-goals.svg"

const defaultTheme: Theme = {
  id: "default",
  gameType: "risk",
  causes: {
    S1: {
      cause0: {
        id: "sustainability",
        barColor: "#EE7002",
        title: "Multiple areas",
        isDefault: true,
        icon: "",
        secondaryIcon: "",
        persona: {
          name: "Sustainability Champion",
          title: "You are a Sustainability Champion",
          description:
            "Sustainability Champions are concerned with aligning their investments with a broad range of environmental, social, and governance (ESG) issues."
        },
        themeItems: [
          {
            title: "Combatting climate change"
          },
          {
            title: "Human rights"
          },
          {
            title: "Diversity in leadership"
          },
          {
            title: "Reduction of pollution"
          }
        ],
        items: [
          {
            title: "Combatting climate change"
          },
          {
            title: "Human rights"
          },
          {
            title: "Diversity in leadership"
          },
          {
            title: "Reduction of pollution"
          }
        ]
      },
      cause1: {
        id: "climate",
        barColor: "#3EA385",
        title: "Addressing climate change",
        description: "Companies that contribute to a low carbon economy and deal effectively with the risks of climate change.",
        themeIndicatorTitle: "Global temperature rise",
        persona: {
          name: "Climate Champion",
          title: "You are a Climate Champion",
          icon: climateCauseIcon,
          description:
            "Climate Champions are concerned with issues such as global warming, changes in weather patterns, and the impact of these shifts on people and the planet."
        },
        themeItems: [
          {
            title: "Cleaner energy technologies"
          },
          {
            title: "Reduction of the carbon intensity of manufacturing"
          },
          {
            title: "Sustainable building materials"
          },
          {
            title: "Bringing about biodiversity improvements"
          },
          {
            title: "The production of green buildings"
          },
          {
            title: "Sustainable agriculture"
          }
        ],
        items: [
          {
            title: "Produce renewable energy"
          },
          {
            title: "Reduce pollution or energy usage"
          },
          {
            title: "Lower carbon emissions"
          }
        ],
        learn: {
          devGoals: {
            description: "This value aligns with the following SDG:",
            items: [
              {
                description: "Clean water and sanitation",
                icon: sdg6Icon
              },
              {
                description: "Affordable and clean energy",
                icon: sdg7Icon
              },
              {
                description: "Climate action",
                icon: sdg13Icon
              }
            ]
          }
        }
      },
      cause2: {
        id: "faith",
        barColor: "#D49358",
        title: "Aligning with a faith",
        description: "Companies that uphold and encourage the values of one’s faith.",
        persona: {
          name: "Faith Champion",
          title: "You are a Faith Champion",
          icon: faithIcon,
          description: ""
        },
        items: [
          {
            title: "Promote religious freedom"
          },
          {
            title: "Adhere to moral and ethical values"
          },
          {
            title: "Participate in social activities"
          },
          {
            title: "Uphold family values"
          },
          {
            title: "Promote interfaith dialogue"
          }
        ],
        learn: {
          devGoals: {
            description: "This value aligns with the following SDG:",
            items: [
              {
                description: "Peace, justice and strong institutions",
                icon: sdg16Icon
              }
            ]
          }
        }
      },
      cause3: {
        id: "ethical-behaviour",
        barColor: "#8B85C6",
        title: "Behaving ethically",
        description: "Companies that treat their people well and conduct their business ethically.",
        themeIndicatorTitle: "Better work standards",
        persona: {
          name: "Ethical Behaviour Champion",
          title: "You are an Ethical Behaviour Champion",
          icon: ethicalBehaviourCauseIcon,
          description: "Ethical Behaviour Champions are concerned with promoting fair and sustainable business practices and protecting human rights."
        },
        themeItems: [
          {
            title: "Promotion of anti-bribery and fraud"
          },
          {
            title: "Promotion of anti-money laundering"
          },
          {
            title: "Data privacy and security"
          },
          {
            title: "Improvement of working conditions"
          },
          {
            title: "Protection of shareholder rights"
          },
          {
            title: "Improvement of board practices"
          }
        ],
        items: [
          {
            title: "Uphold Board of Directors’ independence"
          },
          {
            title: "Protect shareholder rights"
          },
          {
            title: "Improve product quality and safety"
          },
          {
            title: "Promote anti-bribery and anti-money laundering"
          },
          {
            title: "Improve human rights"
          }
        ],
        learn: {
          devGoals: {
            description: "This value aligns with the following SDG:",
            items: [
              {
                description: "Industry, innovation and infrastructure",
                icon: sdg9Icon
              },
              {
                description: "Peace, justice and strong institutions",
                icon: sdg16Icon
              },
              {
                description: "Partnerships for the goals",
                icon: sdg17Icon
              }
            ]
          }
        }
      },
      cause4: {
        id: "public-services",
        barColor: "#85B65F",
        title: "Delivering quality public services",
        description: "Companies that contribute to quality public infrastructure and social services.",
        persona: {
          name: "Public Services Champion",
          title: "You are a Public Services Champion",
          icon: publicServicesIcon,
          description: ""
        },
        items: [
          {
            title: "Increase access to quality education"
          },
          {
            title: "Improve access to healthcare"
          },
          {
            title: "Deliver quality social services"
          },
          {
            title: "Deliver labor and employment services."
          }
        ],
        learn: {
          devGoals: {
            description: "This value aligns with the following SDG:",
            items: [
              {
                description: "Quality education",
                icon: sdg4Icon
              },
              {
                description: "Good health and well-being",
                icon: sdg3Icon
              },
              {
                description: "Decent work and economic growth",
                icon: sdg8Icon
              },
              {
                description: "Sustainable cities and communities",
                icon: sdg11Icon
              }
            ]
          }
        }
      },
      cause5: {
        id: "diversity",
        barColor: "#E26969",
        title: "Increasing diversity & inclusion",
        description: "Companies that prioritize and promote diversity and inclusion.",
        themeIndicatorTitle: "Women on boards",
        persona: {
          name: "Diversity Champion",
          title: "You are a Diversity Champion",
          icon: diversityCauseIcon,
          description: "Diversity Champions are concerned with equal opportunities for all across education, income, and living standards."
        },
        themeItems: [
          {
            title: "Increasing gender diversity in leadership"
          },
          {
            title: "Increasing racial diversity in leadership"
          },
          {
            title: "Increasing ethnic diversity in leadership"
          },
          {
            title: "Promotion of inclusive social and economic growth"
          }
        ],
        items: [
          {
            title: "Foster diversity policies and programs"
          },
          {
            title: "Promote women on the board of directors"
          },
          {
            title: "Improve employee benefits and labor rights"
          }
        ],
        learn: {
          devGoals: {
            description: "This value aligns with the following SDG:",
            items: [
              {
                description: "Gender equality",
                icon: sdg5Icon
              },
              {
                description: "Decent work and economic growth",
                icon: sdg8Icon
              },
              {
                description: "Reduced inequalities",
                icon: sdg10Icon
              }
            ]
          }
        }
      },
      cause6: {
        id: "natural-resources",
        barColor: "#6DA2DA",
        title: "Protecting natural resources",
        description: "Companies that help to protect and conserve our precious natural resources.",
        themeIndicatorTitle: "Toxic waste emissions",
        persona: {
          name: "Natural Capital Champion",
          title: "You are a Natural Capital Champion",
          icon: naturalResourcesCauseIcon,
          description: "Natural Capital Champions are concerned with protecting Earth's natural resources, preventing pollution, and reducing waste."
        },
        themeItems: [
          {
            title: "Protecting water resources"
          },
          {
            title: "Reduction of pollution"
          },
          {
            title: "Conservation of marine resources"
          },
          {
            title: "Reduction of toxic emissions waste"
          },
          {
            title: "Reduction of electronic waste"
          },
          {
            title: "Sustainable use of land resources"
          }
        ],
        items: [
          {
            title: "Protect water resources",
            description:
              "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss."
          },
          {
            title: "Prevent pollution",
            description: "Conserve and sustainably use the oceans, seas and marine resources for sustainable development."
          },
          {
            title: "Conserve of marine resources",
            description: "Ensure sustainable consumption and production patterns."
          },
          {
            title: "Reduce toxic emissions waste"
          },
          {
            title: "Sustainably use land resources"
          },
          {
            title: "Minimise biodiversity loss"
          }
        ],
        learn: {
          devGoals: {
            description: "This value aligns with the following SDG:",
            items: [
              {
                description: "Responsible consumption and production",
                icon: sdg12Icon
              },
              {
                description: "Life below water",
                icon: sdg14Icon
              },
              {
                description: "Life on land",
                icon: sdg15Icon
              }
            ]
          }
        }
      },
      cause7: {
        id: "people",
        barColor: "#D96CA1",
        title: "Supporting people’s basic needs",
        description: "Companies that help support people by meeting their basic needs.",
        themeIndicatorTitle: "Food security in developing countries",
        persona: {
          name: "People Champion",
          title: "You are a People Champion",
          icon: peopleCauseIcon,
          description: "People Champions are concerned with access to essential goods and services, such as food, housing, and health care."
        },
        themeItems: [
          {
            title: "Access to healthcare"
          },
          {
            title: "Affordable housing"
          },
          {
            title: "Access to quality education"
          },
          {
            title: "Provision of nutritional food and supplements"
          },
          {
            title: "Provision of clean water and sanitation"
          },
          {
            title: "Product quality and safety"
          }
        ],
        items: [
          {
            title: "Provide nutritional food and supplements"
          },
          {
            title: "Provide clean water and sanitation"
          },
          {
            title: "Increase internet connectivity"
          },
          {
            title: "Build affordable housing"
          }
        ],
        learn: {
          devGoals: {
            description: "This value aligns with the following SDG:",
            items: [
              {
                description: "Good health and well-being",
                icon: sdg3Icon
              },
              {
                description: "Clean water and sanitation",
                icon: sdg6Icon
              },
              {
                description: "Sustainable cities and communities",
                icon: sdg11Icon
              }
            ]
          }
        }
      }
    },
    S2: {
      cause0: {
        id: "generating-returns",
        title: "Generating returns"
      },
      cause1: {
        id: "reflecting-core-values",
        title: "Reflecting core values"
      }
    }
  },
  games: [
    { type: "pfs", color: "#6DA2DA", hero: pfsHero, secondaryLogo: pfsHeroSecondary },
    { type: "goals", color: "#EB8F39", hero: goalsHero, secondaryLogo: goalsHeroSecondary },
    { type: "risk", color: "#008091", hero: riskHero, secondaryLogo: riskHeroSecondary },
    { type: "liquidity", color: "#C19F70", hero: liquidityHero, secondaryLogo: liquidityHeroSecondary },
    { type: "esg", color: "#85B65F", hero: esgHero, secondaryLogo: esgHeroSecondary },
    { type: "retirement", color: "#8B85C6", hero: retirementHero, secondaryLogo: retirementHeroSecondary },
    { type: "tax", color: "#28A4AC", hero: taxHero, secondaryLogo: taxHeroSecondary }
  ],
  goals: [
    { type: "retirementIncome", icon: retirementIcon, color: ["#8B85C6"], reportIcon: retirementReportIcon },
    { type: "buyAHome", icon: homeIcon, color: ["#D96CA1"], reportIcon: homeReportIcon },
    { type: "education", icon: educationIcon, color: ["#3EA385"], reportIcon: educationReportIcon },
    { type: "travel", icon: travelIcon, color: ["#E26969"], reportIcon: travelReportIcon },
    { type: "wealthAccumulation", icon: wealthIcon, color: ["#2F76BF"], reportIcon: wealthReportIcon },
    { type: "createOwnGoal", icon: ownIcon, color: ["#C48144", "#E9C950", "#85B65F", "#8B85C6", "#D96CA1", "#6DA2DA", "#E26969", "#3EA385"], reportIcon: ownReportIcon }
  ],
  colors: {
    assetClassConfigurations: {
      growth: { color: "#006171", label: "Growth" },
      income: { color: "#28A4AC", label: "Income" },
      cash: { color: "#E5C8A1", label: "Cash" }
    },
    avatarColorByStatus: false,
    avatarColors: ["#694589", "#CA5CDF"],
    esgDonutColors: {
      sustainabilityMetrics: ["#849E42"]
    },
    avatarTextColor: "white",
    riskComfortChartColors: {
      dataPoints: ["#CEF1F4", "#ACDCDF", "#69C8CD", "#28A4AC", "#008091", "#006171", "#0D4355", "#1C3236"],
      line: "#33B0E5"
    },
    scoreChartColor: "#7882D1"
  },
  fingerprint: {
    openGameLinksInline: true
  },
  navbar: {
    links: {
      clients: true,
      firms: false,
      analytics: true,
      team: true,
      import: false,
      archive: true,
      admin: {
        assets: true,
        portfolios: true,
        manage: true
      }
    },
    moreDropdown: {
      show: false,
      links: {
        import: true,
        archive: true
      }
    },
    hamburgerMenu: {
      show: true,
      links: {
        personalDetails: true,
        twoFactorAuth: JSON.parse(import.meta.env.VITE_APP_2FA_ENABLE ?? "false"),
        changePassword: true,
        contactSupport: true,
        knowledgeHub: true,
        billing: true,
        logout: true
      }
    }
  },
  onboarding: {
    screens: {
      1: {
        title: "Welcome to Capital Preferences' Risk Profiling Suite",
        image: welcome,
        description: "Quickly and easily reveal your clients' preferences through our gamified experiences"
      },
      2: {
        title: "Get better insights and provide defendable advice",
        image: insights,
        description: "Provide deeper client insights and automatically generate client-friendly reports for compliance and tracking."
      },
      3: {
        title: "Engage and educate your clients",
        image: educate,
        description: "Show your clients how they can balance their personal Risk Comfort alongside their financial goals."
      }
    }
  },
  pages: {
    clientsList: {
      clientActions: {
        show: true,
        links: {
          archive: true,
          view: true
        }
      },
      columns: {
        primaryAdviser: true
      }
    },
    createClient: {
      showClientTypeSelection: true,
      individual: {
        formFields: {
          showExistingClientSwitch: true,
          showDOB: true
        }
      }
    },
    profile: {
      multiActivityView: "cards",
      sections: {
        activityAndNotes: {
          show: true,
          showBottomBorder: true
        },
        altruismScore: {
          show: false,
          showBottomBorder: false
        },
        clientStatus: {
          show: true,
          showBottomBorder: true,
          showButtons: {
            presentResults: true,
            previewReport: true
          }
        },
        clientWarning: {
          show: true,
          showBottomBorder: true
        },
        fundsMatch: {
          show: false,
          showBottomBorder: false
        },
        personaSummary: {
          show: false,
          showBottomBorder: false
        },
        portfolioCompare: {
          show: false,
          showBottomBorder: false
        },
        regulatoryReporting: {
          show: true,
          showBottomBorder: false
        },
        reportHistory: {
          show: true,
          showBottomBorder: true
        },
        scoreTrends: {
          show: true,
          showBottomBorder: true
        },
        selectedPortfolio: {
          show: true,
          showBottomBorder: true
        }
      },
      sidebar: {
        isCollapsible: true,
        expandCollapseIcon,
        personalDetails: {
          age: true,
          investmentAmount: true
        },
        menu: {
          clientProfile: true,
          goalProjector: true,
          contact: true,
          createHousehold: true,
          editClientDetails: {
            showLink: true,
            modal: {
              fields: {
                dateOfBirth: true
              }
            }
          },
          portfolioAnalyser: false,
          results: true
        }
      },
      resultsTabs: {
        risk: ["viewReport", "comfortZone", "attitudeToRisk", "sensitivityToLoss", "decisionPattern"],
        esg: ["viewReport", "causeScores", "sustainabilityMindset", "investmentThemes", "comments"]
      }
    },
    importModal: {
      images: {
        successImage
      }
    },
    resultsPage: {
      investmentValues: {
        enabled: true,
        viewReport: {
          show: false
        },
        mindset: {
          dataPoint: {
            showAsAvatarBadge: true
          },
          description: {
            show: false
          }
        }
      }
    }
  },
  crmRoutes: {
    investmentValues: {
      enabled: true
    }
  },
  charts: {
    comfortMatch: {
      highlightComfortZone: true,
      useAvatarIcons: true,
      showLegend: true
    },
    sensitivityToLoss: {
      showScoreAsDataPoint: false,
      showAsSpectrum: false,
      description: {
        show: false
      },
      note: {
        show: false
      },
      score: {
        show: true
      }
    },
  },
  reports: {
    coverPage: {
      disclaimer: {
        show: true
      },
      logosBar: {
        position: "top"
      },
      reportFlag: {
        show: false,
        position: "bottom",
        showDate: true
      },
      showDate: true
    },
    riskChartsPage: {
      lineChart: {
        showLegend: true,
        showIcon: true,
        showTitle: true
      },
      showPreferencesHeader: true
    },
    comfortMatchPage: {
      showDescription: true,
      showLegend: true,
      listView: {
        showInfo: true
      }
    },
    riskSummary: {
      showDescription1: true,
      usePersonalisedProfileTitle: true
    },
    decisionPatternPage: {
      decisionsTable: {
        show: true,
        showNamesInFooter: false
      },
      riskProfileSection: {
        show: true
      },
      showDescriptionByConsistency: true
    },
    pages: {
      risk: ["cover", "riskProfile", "riskDimensions", "decisionPattern", "riskCharts", "comfortMatch", "goalProjector"],
      pfs: ["pfsCover", "pfsNetWorth", "pfsIncomeExpenses"]
    },
    footer: {
      showDisclaimer: false
    }
  },
  statuses: {
    "New client": {
      classNames: "bg-tag-purple/10 text-tag-purple/100"
    },
    "New household": {
      classNames: "bg-tag-purple/10 text-tag-purple/100"
    },
    "Activity pending": {
      classNames: "bg-tag-orange/10 text-tag-orange/100"
    },
    "New results available": {
      classNames: "bg-tag-green/10 text-tag-green/100"
    },
    "Up to date": {
      classNames: "bg-tag-purple/10 text-tag-purple/100"
    },
    "Up for review": {
      classNames: "bg-tag-orange/10 text-tag-orange/100"
    },
    "Archived": {
      classNames: "bg-tag-purple/10 text-tag-purple/100"
    }
  },
  messages: {
    en: {
      ...messages,
      ...en
    }
    // en_AU: en,
    // en_GB: en
  },
  tours: {
    createClients: {
      show: true
    },
    gameComplete: {
      show: true
    },
    newClient: {
      show: true
    }
  },
  policies: {
    billing: {
      link: "https://capitalpreferences.com.au/billing-policy",
      markdown: {
        version: "2024-08-14",
        content: billingPolicy
      },
      enabled: true
    },
    tos: {
      markdown: {
        version: "2024-08-20",
        content: termsOfService
      },
      enabled: true
    },
    privacy: {
      markdown: {
        version: "2024-08-20",
        content: privacyPolicy
      },
      enabled: true
    }
  }
}

export default defaultTheme as Theme
