import { AnimatePresence } from "framer-motion"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router"
import useClient from "../../../hooks/useClient"
import arrowLeft from "../assets/images/arrow-left.svg"
import menuIcon from "./assets/menu.svg"
import useHousehold from "../../../hooks/useHousehold"
import SidebarHeader from "../components/SidebarHeader/SidebarHeader"
import RMJFinishModal from "../components/RMJFinishModal/RMJFinishModal"
import { FirmContext } from "../../../contexts/FirmContext"
import { portfolioComfortMatch } from "../portfolioComfort/comfortMatch"
import RadioButton from "../../../components/RadioButton/RadioButton"
import infoAlert from "../../../assets/icons/info-alert-dark.svg"
import infoAlertLight from "../../../assets/icons/info-alert.svg"
import RMJPortfolioComfortMoreInfoModal from "../portfolioComfort/RMJPortfolioComfortMoreInfoModal/RMJPortfolioComfortMoreInfoModal"
import { latest } from "../../../lib/clients"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { SelectedGoal } from "../../advisor/Results/Goals/GoalsAdd"
import { useTheme } from "../../../contexts/ThemeContext"
import { Client, GoalDetail, GoalType } from "../../../models/Client"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import ErrorMessage from "../../../components/Error/ErrorMessage"
import { useMutation } from "react-query"
import { Household } from "../../../models/Household"
import { AxiosError } from "axios"
import { GoalPrioritiesOrder } from "../../advisor/Results/Goals/GoalsTab"
import { RMJourneyContext } from "../../../contexts/RMJourneyContext"

const RMJGoalExplorerSideBar = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const clientOrHousehold = client ?? household
  const { firm } = useContext(FirmContext)
  const {
    selectedPortfolios,
    updateSelectedPortfolio,
    goalExplorer: { selectedGoal, setShouldValidate, shouldValidate, selectedGoals }
  } = useContext(RMJourneyContext)
  const navigate = useNavigate()
  const theme = useTheme()
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false)
  const [portfolio, setPortfolio] = useState<string | null>()
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false)
  const comfortMatch = useMemo(() => portfolioComfortMatch(clientOrHousehold), [clientOrHousehold])
  const [errorMessage, setErrorMessage] = useState<string>()
  const sortedGoalDetails = useMemo(
    () =>
      [...(clientOrHousehold?.goals?.goalDetails ?? [])].sort((a, b) => {
        const priorityA = a.priority ? GoalPrioritiesOrder.indexOf(a.priority) : Infinity
        const priorityB = b.priority ? GoalPrioritiesOrder.indexOf(b.priority) : Infinity
        return priorityA - priorityB
      }),
    [clientOrHousehold?.goals?.goalDetails]
  )

  const goal = useMemo(
    () =>
      selectedGoal
        ? clientOrHousehold?.goals?.goalDetails?.find((goal) => goal.type === selectedGoal.type && goal.id === selectedGoal.id)
        : sortedGoalDetails?.[0],
    [clientOrHousehold?.goals?.goalDetails, selectedGoal, sortedGoalDetails]
  )

  const game = latest(clientOrHousehold!, "risk")

  useEffect(
    () =>
      setPortfolio(selectedPortfolios[`${goal?.type}_${goal?.id}`] !== undefined ? selectedPortfolios[`${goal?.type}_${goal?.id}`] : goal?.selectedPortfolio),
    [goal?.id, goal?.selectedPortfolio, goal?.type, selectedPortfolios]
  )

  const allPortfoliosSelected = useMemo(
    () =>
      clientOrHousehold?.goals?.goalDetails?.every(
        (goalDetail) => goalDetail.selectedPortfolio !== undefined || selectedPortfolios[`${goalDetail.type}_${goalDetail.id}`] !== undefined
      ),
    [clientOrHousehold?.goals?.goalDetails, selectedPortfolios]
  )

  const allGoalDetailsUpdated = useMemo(() => {
    return clientOrHousehold?.goals?.goalDetails?.every((goalDetail) => !!goalDetail.targetAmount?.value)
  }, [clientOrHousehold?.goals?.goalDetails])

  const { mutate: update, status: saveGoalsStatus } = useMutation<Client | Household | null, AxiosError<{ message?: string }>, { goalDetails: GoalDetail[] }>({
    mutationFn: ({ goalDetails }: { goalDetails: GoalDetail[] }) =>
      household ? updateHousehold(household!._id!, [], { goals: { goalDetails } }) : updateClient(client!._id, { goals: { goalDetails } })
  })

  const onChange = (selectedPortfolio: string | null) => {
    setPortfolio(selectedPortfolio)
    updateSelectedPortfolio({ goalType: goal?.type, goalId: goal?.id, portfolio: selectedPortfolio })
  }

  const saveGoals = useCallback(() => {
    setErrorMessage(undefined)
    const sorted = [...selectedGoals].sort((a, b) => {
      const indexA = theme.goals?.findIndex((goal) => goal.type === a.goalType)
      const indexB = theme.goals?.findIndex((goal) => goal.type === b.goalType)
      return indexA - indexB
    })
    const goalDetails = sorted.flatMap(({ goalType, quantity }) => {
      if (goalType === "education" || goalType === "createOwnGoal") {
        return Array.from({ length: quantity }, (_, index) => ({
          id: (index + 1).toString(),
          type: goalType,
          selected: true,
          completed: false
        }))
      } else {
        return {
          id: "",
          type: goalType as GoalType,
          selected: true,
          completed: false
        }
      }
    })
    update({ goalDetails })
  }, [selectedGoals, theme.goals, update])

  return (
    <div className="w-80 shrink-0 h-full bg-interactive-600 flex flex-1 flex-col justify-between">
      {(client || household) && (
        <>
          <div className="flex gap-x-2.5 overflow-y-auto no-scrollbar">
            <div className="flex flex-col w-full">
              {client && <SidebarHeader client={client} />}
              {household && <SidebarHeader household={household} />}
              <div className="sidebar-section">
                <h3 className="sidebar-section-title text-sec">Goal Projector</h3>
                <p className="sidebar-section-text text-sec">
                  Our Goal Projector illustrates how you might achieve your financial goals and stay comfortable with the level of investment risk.
                </p>
              </div>
              {clientOrHousehold?.goals?.goalDetails && clientOrHousehold?.goals?.goalDetails.length > 0 && (
                <div className="sidebar-section text-white">
                  <h3 className="sidebar-section-title text-sec">Select investment risk level for this goal</h3>
                  <RadioButton
                    checked={portfolio === null}
                    id="no-investment"
                    invertColors={true}
                    label={
                      <div className="flex flex-row items-center text-sec">
                        <div className={`mr-2 ${shouldValidate && portfolio === undefined ? "text-alt-critical" : "text-white"}`}>No selected investment</div>
                      </div>
                    }
                    isDark={true}
                    error={shouldValidate && portfolio === undefined}
                    name="no-investment"
                    onChange={() => onChange(null)}
                    className="mb-2 text-white items-center"
                  />
                  {firm?.modelPortfolios?.map((modelPortfolio) => (
                    <RadioButton
                      key={modelPortfolio.id}
                      checked={portfolio === modelPortfolio.id}
                      id={modelPortfolio.id}
                      invertColors={true}
                      label={
                        <div className="flex flex-row items-center text-sec">
                          <div className={`mr-2 ${shouldValidate && portfolio === undefined ? "text-alt-critical" : "text-white"}`}>{modelPortfolio.name}</div>
                        </div>
                      }
                      isDark={true}
                      error={shouldValidate && portfolio === undefined}
                      name={modelPortfolio.id}
                      onChange={() => onChange(modelPortfolio.id)}
                      className="mb-2 text-white items-center"
                    />
                  ))}
                  {shouldValidate && portfolio === undefined && (
                    <div className="flex items-center mt-1" role="alert">
                      <img alt="" className="mr-1" src={infoAlert} aria-hidden />
                      <p className="text-sm text-alt-critical">Please select an investment risk</p>
                    </div>
                  )}
                  <button
                    className="btn btn-text btn-small btn-secondary btn-alt-text text-white text-xs px-3 flex gap-x-1 items-center"
                    onClick={() => setIsMoreInfoModalOpen(true)}
                  >
                    <img src={menuIcon} aria-hidden />
                    View strategic asset allocation
                  </button>
                  <div role="alert" className="sr-only">
                    {(errorMessage || saveGoalsStatus === "error") && (
                      <ErrorMessage id="goal-explorer-save-goals" message="Error saving the goals. Please try again." />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="px-4 pb-4 w-full flex justify-between items-center">
            <div
              onClick={() => {
                navigate(`/${client ? "clients" : "households"}/${clientOrHousehold?._id}/rm/portfolio-comfort`)
              }}
              className="cursor-pointer w-10"
            >
              <img className="mt-3" src={arrowLeft} alt="arrow left icon" />
            </div>
            {clientOrHousehold?.goals?.goalDetails?.length ? (
              <div className="w-full relative">
                {shouldValidate && (!allPortfoliosSelected || !allGoalDetailsUpdated) && (
                  <div className="flex flex-row gap-x-1.5 bg-white text-sm px-2 py-1.5 absolute bottom-16 left-0 right-0 justify-center" role="alert">
                    <img alt="" className="h-5 w-5" src={infoAlertLight} aria-hidden />
                    <p>
                      {!allPortfoliosSelected ? "Please select an investment risk level for all your goals" : "Please update your plan details for each goal"}
                    </p>
                  </div>
                )}
                <button
                  className="btn btn-medium btn-primary-alt mt-4 w-full h-14"
                  type="button"
                  onClick={() => {
                    setShouldValidate(true)
                    if (allPortfoliosSelected && allGoalDetailsUpdated) {
                      setIsFinishModalOpen(true)
                    }
                  }}
                >
                  Finish
                </button>
              </div>
            ) : (
              <button
                disabled={!selectedGoals || (selectedGoals as SelectedGoal[]).length === 0}
                className="btn btn-medium btn-primary-alt mt-4 w-full h-14"
                type="button"
                onClick={saveGoals}
              >
                {saveGoalsStatus === "loading" ? <Loading type="dots" /> : "Next"}
              </button>
            )}
          </div>
        </>
      )}
      <AnimatePresence>
        {isFinishModalOpen && (
          <RMJFinishModal
            infoAlert="Please note, content from Goal Explorer page will not be included in the PDF report"
            onClose={() => setIsFinishModalOpen(false)}
            portfolio={portfolio}
            goalExplorerCompleted={true}
          />
        )}
        {isMoreInfoModalOpen && (
          <RMJPortfolioComfortMoreInfoModal
            assetClasses={game?.assetClasses}
            comfortMatch={comfortMatch}
            onClose={() => setIsMoreInfoModalOpen(false)}
            portfolioMappings={game?.portfolioMappings}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default RMJGoalExplorerSideBar
