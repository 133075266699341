import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { LegendData } from "../components/LineChart/LineChart"
import { createClientDataPoints } from "../utils/charts"
import { SENSITIVITY_TO_LOSS_DATA } from "./assets/sensitivityToLossData"
import { tt } from "../../../lib/translations"
import { Theme } from "../../../config/theme"
import { latest } from "../../../lib/clients"

export type LossSensitivityDetails =
  | {
    title: string
    graphTitle: string
    description?: string
    reportNote?: string | null
    results?: string
    rmjNote?: string | null
  }
  | undefined

export const calculateLossAversionScore = (value: number | undefined) => {
  return value === undefined ? undefined : value <= 19 ? "low" : value <= 49 ? "mid" : "high"
}

export const lossSensitivityDetails = (client?: Client, household?: Household, translationContext: "advisor" | "client" = "client"): LossSensitivityDetails => {
  const getLossSensitivityDetails = (client: Client) => {
    const lossAversion = latest(client, "risk")?.risk?.results?.R?.lossAversion
    const graphTitle = tt({
      id: `sensitivity-to-loss-graph-title-individual-${translationContext}`,
      message: "Your Sensitivity to Loss compared to other investors",
      values: { firstName: client.firstName }
    })

    if (lossAversion === undefined || lossAversion === null) {
      return undefined
    }
    if (lossAversion <= 19) {
      return {
        title: tt({
          id: `sensitivity-to-loss-title-low-individual-${translationContext}`,
          message: "You understand that markets can be volatile",
          values: { firstName: client.firstName }
        }),
        description: tt({
          id: `sensitivity-to-loss-description-low-individual-${translationContext}`,
          message: "You understand that markets can be volatile.",
          values: { firstName: client.firstName }
        }),
        reportNote: undefined,
        results: tt({
          id: `sensitivity-to-loss-results-low-individual-${translationContext}`,
          message: "While nobody likes short-term losses, your decisions suggest that you’ll stay composed when markets are volatile.",
          values: { firstName: client.firstName }
        }),
        rmjNote: lossSensitivityNoteContent(client, undefined, translationContext),
        graphTitle
      }
    } else if (lossAversion <= 39) {
      return {
        title: tt({
          id: `sensitivity-to-loss-title-medium-individual-${translationContext}`,
          message: "You may feel somewhat unsettled when markets are volatile",
          values: { firstName: client.firstName }
        }),
        description: tt({
          id: `sensitivity-to-loss-description-medium-individual-${translationContext}`,
          message: "You may feel somewhat unsettled when markets are volatile.",
          values: { firstName: client.firstName }
        }),
        reportNote: tt({
          id: "sensitivity-to-loss-report-note-individual-medium",
          message: "We should discuss how best to navigate market volatility for the benefit of your long-term goals."
        }),
        results: tt({
          id: `sensitivity-to-loss-results-medium-individual-${translationContext}`,
          message: "You risked different amounts of money as the gains changed between rounds in the activity. This suggests you might feel somewhat unsettled during periods of market volatility.",
          values: { firstName: client.firstName }
        }),
        rmjNote: lossSensitivityNoteContent(client, undefined, translationContext),
        graphTitle
      }
    } else {
      return {
        title: tt({
          id: `sensitivity-to-loss-client-title-high-individual-${translationContext}`,
          message: "You may feel unsettled when markets are volatile",
          values: { firstName: client.firstName }
        }),
        description: tt({
          id: `sensitivity-to-loss-description-high-individual-${translationContext}`,
          message: "You may feel unsettled when markets are volatile.",
          values: { firstName: client.firstName }
        }),
        reportNote: tt({
          id: "sensitivity-to-loss-report-note-individual-high",
          message: "We should discuss how best to navigate market volatility for the benefit of your long-term goals."
        }),
        results: tt({
          id: `sensitivity-to-loss-results-high-individual-${translationContext}`,
          message: "You put less of your money at risk when the gain that was offered was low. This suggests you might feel unsettled during periods of market volatility.",
          values: { firstName: client.firstName }
        }),
        rmjNote: lossSensitivityNoteContent(client, undefined, translationContext),
        graphTitle
      }
    }
  }

  if (client) {
    return getLossSensitivityDetails(client)
  } else if (household) {
    const completedMembers = household.members.filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
    if (completedMembers.length === 1) {
      return getLossSensitivityDetails(completedMembers[0].client)
    } else if (completedMembers.length > 1) {
      const lossAversionLevels = completedMembers.map((member) => latest(member.client, "risk")?.risk?.results?.R?.lossAversion)
      const allDefined = lossAversionLevels.every((level) => level !== undefined && level !== null)
      const graphTitle = tt({
        id: `sensitivity-to-loss-graph-title-household-${translationContext}`,
        message: "Your Sensitivity to Loss compared to other investors",
        values: { firstName: completedMembers[0].client.firstName, secondName: completedMembers[1].client.firstName }
      })

      if (allDefined) {
        const [lossAversion1, lossAversion2] = lossAversionLevels
        if (lossAversion1 <= 19 && lossAversion2 <= 19) {
          return {
            title: tt({
              id: `sensitivity-to-loss-client-title-low-household-${translationContext}`,
              message: "You both understand that markets can be volatile",
              values: { firstName: completedMembers[0].client.firstName, secondName: completedMembers[1].client.firstName }
            }),
            graphTitle
          }
        } else if (Math.abs(lossAversion1 - lossAversion2) > 10) {
          return {
            title: tt({
              id: `sensitivity-to-loss-client-title-different-household-${translationContext}`,
              message: "You have different levels of loss sensitivity",
              values: { firstName: completedMembers[0].client.firstName, secondName: completedMembers[1].client.firstName }
            }),
            rmjNote: lossSensitivityNoteContent(undefined, household, translationContext),
            graphTitle
          }
        } else {
          return {
            title: tt({
              id: `sensitivity-to-loss-client-title-high-household-${translationContext}`,
              message: "You may both feel uncomfortable when markets are volatile",
              values: { firstName: completedMembers[0].client.firstName, secondName: completedMembers[1].client.firstName }
            }),
            rmjNote: lossSensitivityNoteContent(undefined, household, translationContext),
            graphTitle
          }
        }
      }
    }
  }
  return undefined
}

export const lossSensitivityNoteContent = (client?: Client, household?: Household, translationContext: "client" | "advisor" = "client") => {
  let noteText
  if (client) {
    const game = latest(client, "risk")
    const individualLossAversion = calculateLossAversionScore(game?.risk.results?.R.lossAversion)
    if (individualLossAversion) {
      noteText =
        individualLossAversion === "low"
          ? tt({
            id: `sensitivity-to-loss-note-low-individual-${translationContext}`,
            message: "While nobody likes short-term losses, your decisions suggest you'll stay composed when markets are volatile.",
            values: { firstName: client.firstName, lossAversion: game?.risk.results?.R.lossAversion }
          })
          : individualLossAversion === "mid"
            ? tt({
              id: `sensitivity-to-loss-note-mid-individual-${translationContext}`,
              message:
                "Your decisions suggest that during periods of market volatility you may want to mitigate risk. We should discuss our plans for volatile times so we can best support you.",
              values: { firstName: client.firstName, lossAversion: game?.risk.results?.R.lossAversion }
            })
            : individualLossAversion === "high"
              ? tt({
                id: `sensitivity-to-loss-note-high-individual-${translationContext}`,
                message:
                  "Your decisions suggest that during periods of market volatility you may want to de-risk. We should discuss our plans for volatile times so we can best support you.",
                values: { firstName: client.firstName, lossAversion: game?.risk.results?.R.lossAversion }
              })
              : null
    }
  } else if (household) {
    const householdLossAversions = household?.members.map(({ client }) => calculateLossAversionScore(latest(client, "risk")?.risk.results?.R.lossAversion))
    if (householdLossAversions?.every((element) => element !== undefined) && [...new Set(householdLossAversions)].length === 1) {
      noteText =
        householdLossAversions[0] === "low"
          ? tt({
            id: `sensitivity-to-loss-note-low-household-${translationContext}`,
            message: "While nobody likes short-term losses, your decisions suggest you'll both stay composed when markets are volatile.",
            values: { firstName: household.members[0].client.firstName, secondName: household.members[1].client.firstName }
          })
          : householdLossAversions[0] === "mid"
            ? tt({
              id: `sensitivity-to-loss-note-mid-household-${translationContext}`,
              message:
                "Your decisions suggest that during periods of market volatility you may both want to mitigate risk. We should discuss our plans for volatile times so we can best support you.",
              values: { firstName: household.members[0].client.firstName, secondName: household.members[1].client.firstName }
            })
            : householdLossAversions[0] === "high"
              ? tt({
                id: `sensitivity-to-loss-note-household-high-${translationContext}`,
                message:
                  "Your decisions suggest that during periods of market volatility you may both want to de-risk. We should discuss our plans for volatile times so we can best support you.",
                values: { firstName: household.members[0].client.firstName, secondName: household.members[1].client.firstName }
              })
              : undefined
    }
  }
  return noteText
}

export const lossSensitivityChartData = (theme: Theme, client?: Client, household?: Household) => {
  if (client || household) {
    const scores = client
      ? [latest(client, "risk")?.risk?.results?.R?.lossAversion]
      : household?.members
        .filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
        .map(({ client }) => latest(client, "risk")?.risk?.results?.R?.lossAversion)
    const sensitivityScoreXYaxisValue = scores?.map((s) => SENSITIVITY_TO_LOSS_DATA.find((_, i) => i >= s!)!) ?? [] //SENSITIVITY_TO_LOSS_DATA.filter((xVal, i) => scores && scores?.indexOf(i) > -1)
    const xAxis = {
      labels: [
        { id: "1", text: "0" },
        { id: "2", text: "10" },
        { id: "3", text: "20" },
        { id: "4", text: "30" },
        { id: "5", text: "40" },
        { id: "6", text: "50" },
        { id: "7", text: "60" },
        { id: "8", text: "70" },
        { id: "9", text: "80" },
        { id: "10", text: "90" },
        { id: "11", text: "100" }
      ],
      title: tt({ id: "sensitivity-to-loss-x-axis-title", message: "Sensitivity to Loss score" })
    }
    const yAxis = {
      labels: [
        { id: "1", text: "0%" },
        { id: "2", text: "10%" },
        { id: "3", text: "20%" },
        { id: "4", text: "30%" },
        { id: "5", text: "40%" },
        { id: "6", text: "50%" }
      ],
      title: tt({ id: "sensitivity-to-loss-y-axis-title", message: "Percentage of investors" })
    }
    const highlightDataPoints = client
      ? createClientDataPoints({ clients: [client], theme: theme, values: sensitivityScoreXYaxisValue })
      : createClientDataPoints({
        clients: household?.members.filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played).map(({ client }) => client),
        theme: theme,
        values: sensitivityScoreXYaxisValue
      })

    const legend: LegendData[] | undefined = client
      ? [
        {
          color: highlightDataPoints[0].color,
          id: `data-point-${client?.firstName}`,
          text: client?.firstName
        }
      ]
      : household?.members
        .filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
        .map(({ client }, i) => {
          return {
            color: highlightDataPoints[i].color,
            id: `data-point-${client?.firstName}`,
            text: client?.firstName
          }
        })

    return {
      sensitivityToLossData: SENSITIVITY_TO_LOSS_DATA,
      sensitivityScoreXYaxisValue,
      highlightDataPoints,
      legend: legend ?? undefined,
      xAxis,
      yAxis
    }
  }
}
