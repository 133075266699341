const RetirementIcon = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.85106 6.03971L11.272 7.01845C11.438 7.06594 11.6156 7.02775 11.7499 6.91569C11.8842 6.80362 11.959 6.63064 11.9513 6.45108L11.9512 6.44892L11.951 6.44469L11.9503 6.4317C11.9497 6.42106 11.9488 6.40647 11.9475 6.38818C11.9449 6.35159 11.9407 6.30009 11.9342 6.23549C11.921 6.10639 11.8982 5.9243 11.8593 5.70382C11.7816 5.26426 11.6382 4.66435 11.3739 4.02449C10.8441 2.74197 9.81086 1.26515 7.83533 0.653158C5.74183 0.00462076 4.01663 0.683982 2.84296 1.49886C2.25764 1.90525 1.80206 2.34939 1.49268 2.69147C1.33752 2.86303 1.21784 3.01034 1.13574 3.11652C1.09466 3.16966 1.06289 3.21262 1.0407 3.24331C1.0296 3.25866 1.02088 3.27096 1.01459 3.27994L1.00697 3.2909L1.00452 3.29446L1.00364 3.29575C0.901827 3.44544 0.875868 3.63875 0.935204 3.81189C0.994539 3.98503 1.13104 4.11713 1.30079 4.1657L6.83953 5.75032L5.72941 8.98917H3.74543L1.22011 6.11542C1.01899 5.88655 0.678398 5.8714 0.459388 6.08159C0.240377 6.29178 0.225881 6.64771 0.427009 6.87659L3.13899 9.96275L2.30853 11.17C2.13496 11.4223 2.18998 11.7739 2.43141 11.9553C2.67285 12.1366 3.00928 12.0791 3.18285 11.8268L4.36079 10.1145H8.66236C8.66464 10.1183 8.66699 10.1221 8.6694 10.1259L9.74521 11.8194C9.88889 12.0455 10.2164 12.1277 10.4767 12.0029C10.7371 11.8781 10.8316 11.5935 10.688 11.3674L9.89203 10.1145H10.9093C11.2066 10.1145 11.4477 9.86258 11.4477 9.55183C11.4477 9.24108 11.2066 8.98917 10.9093 8.98917H6.84013L7.85106 6.03971Z"
        fill="white"
      />
    </svg>
  )
}

export default RetirementIcon
