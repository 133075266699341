import { AxiosError } from "axios"
import clsx from "clsx"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import axiosInstance from "../../../api/axiosInstance"
import { useValidPassword } from "../../../hooks/useAuthHooks"
import { Password } from "../../../views/auth/AuthComponents"
import { AuthContext, SessionInfo } from "../../../views/auth/AuthContext"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import ErrorMessage from "../../../components/Error/ErrorMessage"

export type Errors = {
  currentPassword?: string
  newPassword?: string
  confirmNewPassword?: string
  passwordUpdateError?: boolean
}

const ChangePassword: React.FunctionComponent<{ handleClose: React.UIEventHandler }> = ({ handleClose }) => {
  const [errors, setErrors] = useState<Errors>({})
  const [screen, setScreen] = useState<"changePassword" | "passwordUpdateSuccess">("changePassword")
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const { signOut, signedIn } = useContext(AuthContext)

  const { password: currentPassword, setPassword: setCurrentPassword } = useValidPassword("")
  const { password: newPassword, setPassword: setNewPassword, passwordIsValid: newPasswordIsValid } = useValidPassword("")
  const { password: confirmNewPassword, setPassword: setConfirmNewPassword, passwordIsValid: confirmNewPasswordIsValid } = useValidPassword("")

  const validateForm = useCallback(
    (): Errors => ({
      currentPassword: !currentPassword?.trim() ? "Enter password" : undefined,
      newPassword:
        !newPassword?.trim() || !newPasswordIsValid
          ? "Please create a password with a minimum of 8 characters, including at least one uppercase letter, one number, and one special character."
          : undefined,
      confirmNewPassword: newPassword
        ? confirmNewPassword?.trim() && newPassword?.trim() !== confirmNewPassword?.trim()
          ? "Passwords do not match"
          : undefined
        : ""
    }),
    [confirmNewPassword, currentPassword, newPassword, newPasswordIsValid]
  )

  const mutation = useMutation<
    SessionInfo,
    AxiosError<string>,
    {
      currentPassword: string
      newPassword: string
    }
  >(
    async ({ currentPassword, newPassword }) => {
      return axiosInstance
        .post<SessionInfo>(`${import.meta.env.VITE_APP_API_BASE}/api/auth/change-password`, {
          currentPassword,
          newPassword
        })
        .then((res) => res.data)
    },
    {
      onSuccess: () => {
        console.log("password updated")
        setScreen("passwordUpdateSuccess")
      },
      onError: (err) => {
        console.log(err)
        setErrors((prev) => ({ ...prev, passwordUpdateError: true }))
      }
    }
  )

  const saveChanges = () => {
    setShouldValidate(true)
    const errs = validateForm()
    setErrors(errs)
    if (Object.values(errs).every((x) => x === null || x === undefined || x === "")) {
      mutation.mutate({ currentPassword, newPassword })
    }
  }

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validateForm()
      setErrors(nextErrors)
    }
  }, [shouldValidate, validateForm])

  // useEffect(() => {

  // }, [screen, mutation.data, mutation.isSuccess, signedIn])

  const screens = {
    changePassword: (
      <form className="change-password-form flex flex-col gap-6">
        <h1 className="modal-title">Change password</h1>
        <div className="change-password-input-wrapper w-full m-auto flex flex-col gap-6">
          <div>
            <Password id="currentPassword" label="Current password" passwordIsValid={true} password={currentPassword} placeholder="Current password" setPassword={setCurrentPassword} />
            <div role="alert">{errors.currentPassword && <ErrorMessage id="change-password-current" message={errors.currentPassword} />}</div>
          </div>
          <div>
            <Password id="newPassword" label="New password" passwordIsValid={newPasswordIsValid} password={newPassword} placeholder="New password" setPassword={setNewPassword} />
            <div role="alert">{errors.newPassword && <ErrorMessage id="change-password-new" message={errors.newPassword} />}</div>
          </div>
          <div>
            <Password
              id="confirmNewPassword"
              label="Confirm password"
              placeholder="Confirm password"
              passwordIsValid={confirmNewPasswordIsValid}
              password={confirmNewPassword}
              setPassword={setConfirmNewPassword}
            />
            <div role="alert">{errors.confirmNewPassword && <ErrorMessage id="change-password-confirm-new" message={errors.confirmNewPassword} />}</div>
            <div role="alert">
              {errors.passwordUpdateError && (
                <ErrorMessage
                  id="change-password-update"
                  message={`Error updating the password. Please check the details again. (${String(
                    mutation.error?.response?.data ?? mutation.error?.response
                  )})`}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-4 justify-center w-full">
          <button type="button" className="btn btn-secondary btn-medium text-sec w-44" onClick={handleClose}>
            Cancel
          </button>
          <button
            type="button"
            className={clsx("btn btn-primary btn-medium text-sec hover:bg-interactive-600 hover:cursor-pointer w-44", {})}
            onClick={saveChanges}
          >
            {mutation.isLoading ? <Loading type="dots" /> : <>Save changes</>}
          </button>
        </div>
      </form>
    ),
    passwordUpdateSuccess: (
      <div className="flex flex-col gap-y-4">
        <h4 className="text-h4">Success</h4>
        <p className="font-normal text-p leading-5">
          Your password has been changed. All sessions have been terminated. You should sign out now and log back in again with your new password.
        </p>
        <div className="flex gap-x-4">
          <button className="btn btn-primary btn-medium" onClick={signOut}>
            Sign me out
          </button>
          <button
            className="btn btn-secondary btn-medium"
            onClick={() => {
              if (mutation.isSuccess && mutation.data) {
                signedIn(mutation.data)
              }
            }}
          >
            Stay signed in
          </button>
        </div>
      </div>
    )
  }

  return <div className="w-[500px]">{screens[screen]}</div>
}

export default ChangePassword
