import { Trans } from "@lingui/macro"
import { clsx } from "clsx"
import { useContext, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import alert from "../../../../assets/icons/info-alert.svg"
import { useTheme } from "../../../../contexts/ThemeContext"
import { areDecisionsIncoherent, latest } from "../../../../lib/clients"
import { customDateFormat } from "../../../../lib/date"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { AuthContext } from "../../../../views/auth/AuthContext"
import downloadIcon from "../../../advisor/assets/images/download.svg"
import RMJLossSensitivity from "../../../rmjourney/lossSensitivity/RMJLossSensitivity"
import RMJRiskAttitude from "../../../rmjourney/riskAttitude/RMJRiskAttitude"
import RMJYourDecisions from "../../../rmjourney/yourDecisions/RMJYourDecisions"
import RiskPersonaSummary from "../RiskPersonaSummary/RiskPersonaSummary"
import Section from "../Section"
import RiskComfortZone from "./RiskComfortZone/RiskComfortZone"
import InconsistentDecisionsWarning from "../../components/InconsistentDecisionsWarning/InconsistentDecisionsWarning"

const RiskResults = ({ client, household }: { client?: Client; household?: Household }) => {
  const theme = useTheme()
  const { sessionInfo } = useContext(AuthContext)
  const latestClientRiskGame = latest(client!, "risk")
  const latestHouseholdRiskGames = useMemo(() => {
    return household?.members.map((member) => latest(member.client!, "risk")) ?? []
  }, [household?.members])

  const completedMembers = household && household.members.filter((_, index) => latestHouseholdRiskGames[index]?.played)
  const incompleteMembers = household && household.members.filter((_, index) => !latestHouseholdRiskGames[index]?.played)
  const [queryParams] = useSearchParams()
  const isEmbedded = !!queryParams.get("facet")

  const householdStatus = useMemo(() => {
    if (!household) return null
    if (completedMembers!.length === household.members.length) {
      const lastPlayedGame = latestHouseholdRiskGames
        .filter((game) => game!.played)
        .sort((a, b) => new Date(b!.played).getTime() - new Date(a!.played).getTime())[0]
      return `Household profile completed on ${customDateFormat(lastPlayedGame!.played, "d MMM yyyy")}`
    } else {
      const completedText = completedMembers! // If we're on this tab, at least one member has completed the risk game
        .map((member) => {
          const playedDate = latestHouseholdRiskGames.find((game) => game?.played)?.played
          return `${member.client.firstName} completed the risk activity on ${customDateFormat(playedDate!, "d MMM yyyy")}`
        })
        .join(". ")
      const pendingText = incompleteMembers!.map((member) => `${member.client.firstName}'s risk activity is still pending.`).join(" ") // If completed members are less than 2, then at least one is incomplete
      return `${completedText}.${incompleteMembers!.length > 0 ? ` ${pendingText}` : ""}`
    }
  }, [completedMembers, household, incompleteMembers, latestHouseholdRiskGames])

  const gameReportUrl = `${
    household && completedMembers?.length === household.members.length ? latest(household, "risk")?.gameReportUrl : latestClientRiskGame?.gameReportUrl
  }${isEmbedded ? `&_ssoToken=${sessionInfo?.idToken}&_ssoAccessToken=${sessionInfo?.accessToken}&_ssoRefreshToken=${sessionInfo?.refreshToken}` : ""}`

  const householdInconsistentMemberNames = useMemo(
    () =>
      completedMembers
        ?.filter(({ client }) => areDecisionsIncoherent(latest(client, "risk")))
        .map(({ client }) => client?.firstName)
        .filter(Boolean),
    [completedMembers]
  )

  return (
    <div className="risk-tab">
      <h1 className="sr-only">Risk</h1>
      <Section className="pb-0 flex justify-between items-center" showSeparator={false} isEmbedded={isEmbedded}>
        <div className="flex flex-col gap-y-2">
          <p>
            {client && `${client?.firstName}'s profile completed on ${customDateFormat(latestClientRiskGame!.played)}`}
            {household && householdStatus}
          </p>
          {client && areDecisionsIncoherent(latestClientRiskGame) && <InconsistentDecisionsWarning type="client" names={[client.firstName]} />}
          {household && !!householdInconsistentMemberNames?.length && <InconsistentDecisionsWarning type="household" names={householdInconsistentMemberNames} />}
        </div>
        <a
          href={gameReportUrl}
          target="_blank"
          rel="noreferrer"
          aria-disabled={household && (completedMembers?.length ?? 0) < household.members.length}
          className={clsx(
            "btn btn-text btn-text-medium flex gap-2 items-center text-sec font-bold",
            household && (completedMembers?.length ?? 0) < household.members.length && "opacity-50 hover:bg-transparent"
          )}
        >
          <span>View report</span>
          <img src={downloadIcon} alt="" aria-hidden />
        </a>
      </Section>
      {theme.pages.profile.resultsTabs?.risk?.map((section, i) => {
        if (section === "riskPersonaSummary") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.risk?.length !== i + 1} isEmbedded={isEmbedded}>
              <RiskPersonaSummary client={client!} game={latestClientRiskGame!} />
              {areDecisionsIncoherent(latestClientRiskGame) && (
                <div className="p-3 mb-6 bg-surface-100">
                  <div className="flex gap-x-3">
                    <img src={theme.reports?.riskSummary?.inconsistentIcon ?? alert} alt="warning" />
                    <p className="text-sm">
                      <Trans id="report-summary-page-inconsistent-note">
                        We noticed some inconsistencies in how you took risk between the six scenarios. Please consider retaking the risk activity to ensure we
                        get the best read of your preferences. Your advisor will assist you with this.
                      </Trans>
                    </p>
                  </div>
                </div>
              )}
            </Section>
          )
        } else if (section === "decisionPattern") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.risk?.length !== i + 1} isEmbedded={isEmbedded}>
              <RMJYourDecisions translationContext="advisor" />
            </Section>
          )
        } else if (section === "attitudeToRisk") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.risk?.length !== i + 1} isEmbedded={isEmbedded}>
              <RMJRiskAttitude translationContext="advisor" />
            </Section>
          )
        } else if (section === "sensitivityToLoss") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.risk?.length !== i + 1} isEmbedded={isEmbedded}>
              <RMJLossSensitivity translationContext="advisor" />
            </Section>
          )
        } else if (section === "comfortZone") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.risk?.length !== i + 1} isEmbedded={isEmbedded}>
              <RiskComfortZone client={client!} game={latestClientRiskGame!} />
            </Section>
          )
        }
      })}
    </div>
  )
}

export default RiskResults
