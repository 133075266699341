import React, { createContext, useContext, useState } from 'react'

type Product = 'lead-gen' | 'prma' | 'efp' | 'advisor' | undefined

interface ProductContextValue {
  product: Product
  setProduct: (product: Product) => void
}

const ProductContext = createContext<ProductContextValue | undefined>(undefined)

const productFromStaticConfig = () => {
  const mapstr = import.meta.env.VITE_APP_PRODUCT_MAP || ""
  const map = Object.fromEntries(
    mapstr.split(',').map(entry => entry.split(':'))
  )
  const hostname = window.location.hostname
  return map[hostname] || import.meta.env.VITE_APP_PRODUCT_DEFAULT
}

export const ProductProvider = ({ children }: { children: React.ReactNode }) => {

  const [product, setProduct] = useState<Product>(productFromStaticConfig())

  return (
    <ProductContext.Provider value={{ product, setProduct }}>
      {children}
    </ProductContext.Provider>
  )
}

export const useProduct = () => {
  const context = useContext(ProductContext)
  if (!context) {
    throw new Error('useProduct must be used within a ProductProvider')
  }
  return context
}
