import React from "react"
import { format } from "date-fns"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { Firm } from "../../../../models/Firm"
import { clsx } from "clsx"
import { useTheme } from "../../../../contexts/ThemeContext"
import nz from "../../../../lib/nz"

const Footer = ({
  pageNum,
  client,
  household,
  firm,
  className,
  textClassName,
  borderClassName
}: {
  pageNum: number
  client: Client
  household: Household
  firm?: Firm
  className?: string
  textClassName?: string
  borderClassName?: string
}) => {
  const theme = useTheme()
  return (
    <div className={clsx("reports-footer absolute bottom-0 w-full", textClassName || "text-surface-500", className)}>
      <hr className={clsx("border-t-1", borderClassName || "border-t-surface-500")} />
      <div className="flex w-full items-center justify-between text-xxs mt-6 mb-10">
        <div className="font-bold">{`${pageNum} ${
          theme.reports?.footer?.showFirmName === undefined || theme.reports?.footer?.showFirmName ? " | " + firm?.name : ""
        }`}</div>
        <div className="flex gap-1">
          {theme.reports?.footer?.showClientName && (
            <span>
              {client && <span>{`${nz(client.firstName, "")} ${nz(client.lastName, "")}`.trim()} | </span>}
              {household && <span>{household.name} | </span>}
            </span>
          )}
          <span>
            {format(new Date(), "dd MMMM yyyy")} | Copyright © {format(new Date(), "yyyy")} Capital Preferences, Ltd.
          </span>
          {theme.reports?.footer?.showDisclaimer && <span>| For Internal Use Only. Not for Distribution.</span>}
        </div>
      </div>
    </div>
  )
}

export default Footer
