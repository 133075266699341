import { Trans } from "@lingui/macro"
import clsx from "clsx"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTheme } from "../../../../../contexts/ThemeContext"
import datapointIcon from "./assets/datapoint.svg"
import { Client } from "../../../../../models/Client"
import { tt } from "../../../../../lib/translations"
import AvatarBadges from "../../../../../components/AvatarBadges/AvatarBadges"

const ValuesMindset = ({
  className,
  clientScores,
  forReport = false
}: {
  className?: string
  clientScores?: { client: Client; score: number }[]
  forReport?: boolean
}) => {
  const [graphWidth, setGraphWidth] = useState(0)
  const graphRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const descriptions = useMemo(
    () =>
      clientScores?.map(({ score }) => {
        const mindset = score < 33 ? "low" : score < 67 ? "medium" : "high"
        const message =
          score < 33
            ? "Clients differ in how much importance they place on reflecting their values in their investments. Your decisions showed us that we should continue to prioritize generating returns as we develop your investment strategy."
            : score < 67
            ? "Clients differ in how much importance they place on reflecting their interests in their investments. Your decisions show that you want to continue to prioritize returns while also looking for opportunities to reflect what you care about."
            : "Clients differ in how much importance they place on reflecting their interests in their investments. Your decisions show that it’s important to reflect what you care about as your investment strategy develops."

        return tt({ id: `report-values-mindset-${mindset}-description`, message })
      }),
    [clientScores]
  )

  useEffect(() => {
    const localRef = graphRef.current
    if (localRef) {
      const divResizeObserver = new ResizeObserver((e) => {
        setGraphWidth(e[0].contentRect.width)
      })

      divResizeObserver.observe(localRef)
      return () => {
        divResizeObserver.unobserve(localRef)
      }
    }
  }, [])

  const offset = (16 / graphWidth) * 100

  const clientDisplacements = useMemo(
    () => clientScores?.map(({ client, score }) => ({ displacement: ((graphWidth * (score / 100)) / graphWidth) * 100, client })),
    [clientScores, graphWidth]
  )

  return (
    <div className={clsx("flex-auto", className)}>
      <h2 className="mb-2 text-main-500 text-sec tracking-wide uppercase">Sustainability mindset</h2>
      <h3 className="text-h3 font-semibold mb-6">
        {tt({ id: "values-results-page-mindset-title", values: { names: clientScores?.map((sc) => sc.client.firstName).join(" and ") } })}
      </h3>
      {theme.pages.resultsPage?.investmentValues?.mindset?.description?.show && descriptions?.map((description, i) => <p key={i}>{description}</p>)}
      <div className="w-full relative mt-18 px-4">
        <div ref={graphRef} className="values-mindset-chart-line h-1 bg-surface-activity w-full" />
        <div className="flex flex-col items-center relative">
          {clientDisplacements?.map(({ client, displacement }, i) => {
            // Check if the previous displacement is close to the current one
            const prevDisplacement = i > 0 ? clientDisplacements[i - 1].displacement : null
            const isTooClose = prevDisplacement !== null && Math.abs(displacement - prevDisplacement) < 3
            return (
              <div
                key={i}
                className={clsx("absolute top-0 flex flex-col items-center -translate-y-full w-max", isTooClose ? "h-18" : "h-12 z-10")}
                style={{ left: `${displacement - offset}%` }}
              >
                {theme.pages.resultsPage?.investmentValues?.mindset?.dataPoint?.showAsAvatarBadge ? (
                  <AvatarBadges clients={[client]} colorStartIndex={i} size="small" />
                ) : (
                  <img className="w-8 h-8" src={theme.reports?.valuesMindset?.datapointIcon ?? datapointIcon} alt="" aria-hidden />
                )}
                <hr className="h-full border-0 border-l" style={{ color: theme.colors.avatarColors[i]}} />
                <div className="w-[5px] h-[5px] absolute bottom-0 rounded-full" style={{ backgroundColor: theme.colors.avatarColors[i]}} />
              </div>
            )
          })}
        </div>
        <div className="values-mindset-chart-labels mt-1 flex justify-between text-sm">
          <p className={clsx("text-left", { "w-18": forReport })}>Generating higher returns</p>
          <p className={clsx("text-center", { "w-12": forReport })}>Combines both</p>
          <p className={clsx("text-right", { "w-18": forReport })}>
            <Trans id="report-values-mindset-chart-label-right">Aligning to areas of interest</Trans>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ValuesMindset
