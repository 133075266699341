import { i18n } from "@lingui/core"
import { Trans } from "@lingui/macro"
import { useContext, useMemo } from "react"
import { ASSET_TYPES } from "../../../config/assetClasses"
import { FirmContext } from "../../../contexts/FirmContext"
import { useTheme } from "../../../contexts/ThemeContext"
import { latest } from "../../../lib/clients"
import { Client, PortfolioMapping } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { AssetClass, SubAssetClass } from "../../../models/InvestmentUniverse"
import { WeightedComponent } from "../../../models/PortfolioModel"
import currentPortfolio from "../../../pages/rmjourney/assets/images/current-portfolio.svg"
import riskComfortIcon from "../../../pages/rmjourney/assets/images/riskComfort.svg"
import RMJPortfolioComfortChart from "../../rmjourney/portfolioComfort/RMJPortfolioComfortChart/RMJPortfolioComfortChart"
import RMJPortfolioComfortMatchListView from "../../rmjourney/portfolioComfort/RMJPortfolioComfortMatchListView"
import Container from "./components/Container"
import Footer from "./components/Footer"

export const allAssetClasses = (assetClasses: AssetClass[]) =>
  assetClasses
    ?.flatMap((ac) => [ac, ...(ac.subAssetClasses || []).map((sac) => ({ ...sac, type: sac.type ?? ac.type }))] as (AssetClass | SubAssetClass)[])
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id!]: cur
      }),
      {} as { [key: string]: AssetClass | SubAssetClass }
    )

export const assetTypeWeights = (
  portfolioComponents: WeightedComponent[],
  allAssetClasses: {
    [key: string]: AssetClass | SubAssetClass
  }
) =>
  ASSET_TYPES.map((ac, jj) => ({
    assetClass: ac,
    colorIndex: jj,
    total: Math.round(portfolioComponents.filter((c) => allAssetClasses[c.id].type === ac).reduce((sum, x) => sum + x.weight, 0) * 100) / 100
  })).filter(({ total }) => total > 0)

const ComfortMatchPage = ({ page, client, household, postMeeting }: { page: number; client: Client; household: Household; postMeeting?: boolean }) => {
  const clientOrHousehold = client ?? household
  const game = latest(clientOrHousehold, "risk")
  const portfolioMappings = game?.portfolioMappings
  const { firm } = useContext(FirmContext)
  const theme = useTheme()

  const comfortMatches = useMemo(() => {
    return [...(portfolioMappings || [])].sort((a, b) => {
      const d = b.riskComfort! - a.riskComfort!
      return d ? d : (a.portfolio!.sd || 0) - (b.portfolio!.sd || 0)
    })
  }, [portfolioMappings])
  const comfortMatch = comfortMatches[0]

  const assetClasses = game?.assetClasses ? allAssetClasses(game.assetClasses) : {}

  const isComfortZone = theme.reports?.comfortMatchPage?.isComfortZone

  const sortedMappings = useMemo(() => {
    return [...(portfolioMappings ?? [])].sort((a, b) => (a.portfolio!.sd || 0) - (b.portfolio!.sd || 0))
  }, [portfolioMappings])

  const mappings = useMemo(
    () =>
      sortedMappings.reduce(
        (acc: { pre: PortfolioMapping[]; match: PortfolioMapping[]; post: PortfolioMapping[] }, mapping) => {
          const group = mapping.riskComfort! >= 60 ? "match" : acc.match.length > 0 ? "post" : "pre"
          acc[group].push(mapping)
          return acc
        },
        { pre: [], match: [], post: [] }
      ),
    [sortedMappings]
  )

  const portfolioTitle = useMemo(() => {
    if (isComfortZone) {
      const [lowerBound, higherBound] = [mappings.match[0], mappings.match[mappings.match.length - 1]]
      if (mappings.match.length === 1) {
        return `your comfort zone aligns with our ${comfortMatch.portfolio?.name} profiles.`
      } else if (mappings.match.length === 2) {
        return `your comfort zone aligns with our ${lowerBound.portfolio?.name} and ${higherBound.portfolio?.name} profiles.`
      } else if (mappings.match.length === 3) {
        return `your comfort zone spans our ${lowerBound.portfolio?.name} to ${higherBound.portfolio?.name} profiles.`
      }
    } else if (comfortMatch?.portfolio?.translationKey) {
      /* lingui-extract-ignore */
      return i18n._({ id: `report-summary-page-${comfortMatch.portfolio.translationKey}-title` })
    } else {
      return "Your Risk Comfort with each of our investment options"
    }
  }, [comfortMatch.portfolio?.name, comfortMatch.portfolio.translationKey, isComfortZone, mappings.match])

  return (
    <Container className="flex flex-col pt-10 relative px-10 comfort-chart-report-page">
      <div className="h-full">
        {portfolioMappings && (
          <>
            <h2 className="reports-comfort-match-page-title text-sm text-main-600 mb-1 uppercase">
              <Trans id="reports-comfort-match-page-title">Risk comfort</Trans>
            </h2>
            <h3 className="text-p font-semibold text-main-600">{portfolioTitle && portfolioTitle.charAt(0).toUpperCase() + portfolioTitle.slice(1)}</h3>
            {theme.reports?.comfortMatchPage?.showDescription && (
              <p className="text-sm font-normal leading-tight mt-4">
                <Trans id="comfort-chart-report-description">
                  Your Risk Comfort scores express how comfortable you’d be in each of our investment options, given the risk and return expectations. The
                  amount of risk required to achieve your goals may differ from where you are naturally most comfortable. It’s important to balance your risk
                  comfort with goal achievability to ensure a confident investing journey.
                </Trans>
                {theme.reports.comfortMatchPage.appendProfileToDescription && <span>{portfolioTitle}</span>}
              </p>
            )}
            <div className="relative h-75 grow w-full comfort-chart">
              <RMJPortfolioComfortChart
                allAssetClasses={assetClasses}
                client={client}
                household={household}
                portfolioMappings={game.portfolioMappings}
                comfortMatch={comfortMatch}
                forReport={true}
                isPostMeeting={postMeeting}
              />
            </div>
            {theme.reports?.comfortMatchPage?.showLegend && (
              <div className="flex justify-center text-sm mb-4 ml-12">
                {!postMeeting && (
                  <div className="flex items-center">
                    <img src={riskComfortIcon} aria-hidden className="risk-comfort-img w-3" />
                    <p className="pl-1">{client ? "Highest Risk Comfort" : "Household Risk Comfort"}</p>
                  </div>
                )}
                {clientOrHousehold.currentPortfolio && (
                  <div className="flex pl-4 items-center">
                    <img src={currentPortfolio} aria-hidden className="risk-comfort-img w-3" />
                    <p className="pl-1">Current Investment</p>
                  </div>
                )}
              </div>
            )}
            {theme.reports?.comfortMatchPage?.showDisclaimers && (
              <p className="reports-comfort-match-disclaimer text-sm">
                <Trans id="reports-comfort-match-disclaimer">
                  For illustrative purposes only. The illustration does not reflect the performance of any specific investment. Actual rates of return cannot be
                  predicted and will fluctuate. Your results may be more or less.
                </Trans>
              </p>
            )}
            {theme.reports?.comfortMatchPage?.listView?.showHeader && <h2 className="text-sec text-main-600 mb-1 uppercase mt-6">Comfort Zone</h2>}
            {theme.reports?.comfortMatchPage?.listView?.showTitle && (
              <h3 className="text-h3 font-semibold text-main-600">Your risk comfort across our investment options</h3>
            )}
            <RMJPortfolioComfortMatchListView
              forReport={true}
              client={client}
              household={household}
              comfortMatch={comfortMatch}
              portfolioMappings={portfolioMappings}
              allAssetClasses={assetClasses}
              postMeeting={postMeeting}
            />
            {theme.reports?.comfortMatchPage?.listView?.showInfo && (
              <p className="reports-comfort-match-list-view-note text-sm mt-5">
                <Trans id="reports-comfort-match-list-view-note">
                  The comfort zone highlights where you have a Risk Comfort score above 60%. Our selected investment might be outside your comfort zone, based
                  upon our assessment of your goals and constraints.
                </Trans>
              </p>
            )}
          </>
        )}
      </div>
      <div className="relative h-full">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default ComfortMatchPage
