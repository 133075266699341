import React, { UIEventHandler, useContext } from "react"
import { AuthContext } from "../../../views/auth/AuthContext"
import { useTheme } from "../../../contexts/ThemeContext"

const Signout: React.FunctionComponent<{ handleClose: UIEventHandler }> = ({ handleClose }) => {
  const theme = useTheme()
  const { signOut } = useContext(AuthContext)

  return (
    <div className="modal-container flex flex-col gap-6 items-center">
      {theme.navbar.hamburgerMenu.modals?.logout?.hero && <img src={theme.navbar.hamburgerMenu.modals?.logout?.hero} className="w-25 h-25" alt="" aria-hidden />}
      <h2 className="modal-title font-semibold text-h2 text-main-500">Are you sure you want to log out?</h2>
      <div className="text-main-500 font-normal text-center text-p">You'll be returned to the login screen.</div>
      <div className="flex gap-4 w-full m-auto">
        <button className="btn btn-secondary btn-medium text-sec w-44" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-primary btn-medium text-sec w-44" onClick={signOut}>
          Log out
        </button>
      </div>
    </div>
  )
}

export default Signout
