import clsx from "clsx"
import { ReactNode } from "react"

interface ToggleSwitchProps {
  className?: string
  label?: ReactNode | string
  onToggle: (value: string) => void
  selectedValue: string
  options: {
    label: string
    value: string
    altName?: string
    icons?: {
      active: string
      inactive: string
    }
  }[]
}

const ToggleSwitch = ({ className, label, selectedValue, onToggle, options }: ToggleSwitchProps) => {
  return (
    <fieldset className={clsx("flex justify-start", className)}>
      <legend className="text-sec font-semibold">{label}</legend>
      <div className="toggle-switch w-full h-auto flex border border-surface-200 bg-surface-200 rounded-2">
        {options.map((option, index) => (
          <label key={index} className="w-1/2 h-full flex">
            <input
              id="toggleOption"
              className="sr-only"
              type="radio"
              name="toggleOption"
              value={option.value}
              checked={selectedValue === option.value}
              onChange={() => onToggle(option.value)}
              aria-label={option.label}
            />
            <span
              className={clsx("w-full flex items-center text-p justify-center cursor-pointer font-semibold transition-all duration-500", {
                "text-interactive-500 bg-white shadow-100": selectedValue === option.value,
                "text-main-300": selectedValue !== option.value
              })}
            >
              {option.icons ? (
                selectedValue !== option.value ? (
                  <img alt={option.altName} src={option.icons.inactive} />
                ) : (
                  <img alt={option.altName} aria-hidden src={option.icons.active} />
                )
              ) : (
                option.label
              )}
            </span>
          </label>
        ))}
      </div>
    </fieldset>
  )
}

export default ToggleSwitch
