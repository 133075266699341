import clsx from "clsx"
import { useMemo, useState } from "react"
import { useTheme } from "../../../contexts/ThemeContext"
import { Client, PortfolioMapping } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { AssetClass, SubAssetClass } from "../../../models/InvestmentUniverse"
import ComfortMatchListViewPortfolioDetail from "./ComfortMatchListViewPortfolioDetail"
import infoIcon from "../assets/images/info-icon.svg"
import RMJTextTooltip from "../goalExplorer/components/RMJTextTooltip/RMJTextTooltip"

export const CLIENT_NAME_COLOUR_CLASSES = ["text-avatar-0-500", "text-avatar-1-500"]

const RMJPortfolioComfortMatchListView = ({
  allAssetClasses,
  comfortMatch,
  portfolioMappings,
  client,
  household,
  forReport,
  postMeeting
}: {
  allAssetClasses: {
    [key: string]: AssetClass | SubAssetClass
  }
  comfortMatch: PortfolioMapping
  portfolioMappings: PortfolioMapping[]
  client?: Client
  household?: Household
  forReport: boolean
  postMeeting?: boolean
}) => {
  const theme = useTheme()
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)
  const sortedMappings = useMemo(() => {
    return [...portfolioMappings].sort((a, b) => (a.portfolio!.sd || 0) - (b.portfolio!.sd || 0))
  }, [portfolioMappings])

  const mappings = useMemo(
    () =>
      sortedMappings.reduce(
        (acc: { pre: PortfolioMapping[]; match: PortfolioMapping[]; post: PortfolioMapping[] }, mapping) => {
          const group = mapping.riskComfort! >= 60 ? "match" : acc.match.length > 0 ? "post" : "pre"
          acc[group].push(mapping)
          return acc
        },
        { pre: [], match: [], post: [] }
      ),
    [sortedMappings]
  )

  return (
    <div className={clsx("w-full grid comfort-match-list-view", forReport ? "mb-2" : "mb-5")}>
      <div className="w-full grid grid-cols-10">
        <div className="col-start-9 col-span-1 text-center">
          <p className={clsx("text-main-600 font-semibold whitespace-nowrap", forReport ? "text-sm" : "text-sec")}>Risk comfort</p>
        </div>
      </div>
      {mappings.pre.map((portfolioMapping) => (
        <ComfortMatchListViewPortfolioDetail
          portfolioMapping={portfolioMapping}
          comfortMatch={comfortMatch}
          forReport={forReport}
          postMeeting={postMeeting}
          portfolioMappings={sortedMappings}
          allAssetClasses={allAssetClasses}
          client={client}
          household={household}
          key={portfolioMapping.portfolio.id}
        />
      ))}
      <div className="comfort-match-portfolio relative flex flex-col bg-interactive-100">
        {theme.charts?.comfortMatch?.highlightComfortZone && (
          <div
            className={clsx(
              "comfort-match-portfolio-label flex gap-x-1 items-center absolute rotate-90 right-0 top-1/2 -translate-y-1/2 h-max text-highlight-700 text-xs font-semibold text-center leading-xs",
              {
                "w-10 right-3 flex-col": mappings.match.length === 1
              }
            )}
          >
            Comfort zone
            {!forReport && (
              <div className="w-4 h-4" onMouseOver={() => setShowInfoTooltip(true)} onMouseOut={() => setShowInfoTooltip(false)}>
                <img className="cursor-pointer" src={infoIcon} aria-hidden />
              </div>
            )}
          </div>
        )}
        {showInfoTooltip && (
          <div className="absolute w-75 right-13 top-[47%] z-10">
            <RMJTextTooltip>
              <p className="text-sm leading-3">The comfort zone highlights where you have a Risk Comfort score of 60% or above.</p>
            </RMJTextTooltip>
          </div>
        )}
        {mappings.match.map((portfolioMapping) => (
          <ComfortMatchListViewPortfolioDetail
            portfolioMapping={portfolioMapping}
            comfortMatch={comfortMatch}
            forReport={forReport}
            postMeeting={postMeeting}
            portfolioMappings={sortedMappings}
            allAssetClasses={allAssetClasses}
            client={client}
            household={household}
            key={portfolioMapping.portfolio.id}
          />
        ))}
      </div>
      {mappings.post.map((portfolioMapping) => (
        <ComfortMatchListViewPortfolioDetail
          portfolioMapping={portfolioMapping}
          comfortMatch={comfortMatch}
          forReport={forReport}
          postMeeting={postMeeting}
          portfolioMappings={sortedMappings}
          allAssetClasses={allAssetClasses}
          client={client}
          household={household}
          key={portfolioMapping.portfolio.id}
        />
      ))}
    </div>
  )
}

export default RMJPortfolioComfortMatchListView
