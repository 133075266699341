import clsx from "clsx"

const PercentageBar = ({ percentage, label, forReport }: { percentage: number; label: string; forReport?: boolean }) => {
  return (
    <div className="pt-2">
      <p className="text-sm text-riskComfort-500">{label}</p>
      <div className={clsx("bg-surface-400 w-full", forReport ? "h-[5px]" : "h-2")}>
        <div className="bg-riskComfort-400 h-full" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  )
}

export default PercentageBar
