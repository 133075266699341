import clsx from "clsx"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useLocation, useParams } from "react-router"
import alert from "../../../assets/icons/info-alert.svg"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import RadioButton from "../../../components/RadioButton/RadioButton"
import { RMJourneyContext } from "../../../contexts/RMJourneyContext"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import { GoalDetail } from "../../../models/Client"
import AddGoal from "../../advisor/Results/Goals/AddGoal"
import CardTitle from "../../advisor/Results/Goals/components/CardTitle"
import GoalsAdd, { SelectedGoal } from "../../advisor/Results/Goals/GoalsAdd"
import RetirementIncome from "./retirementIncome/RetirementIncome"
import WealthAccumulation from "./wealthAccumulation/WealthAccumulation"

const GoalPrioritiesOrder = ["essential", "important", "aspirational"]

const RMJGoalExplorerPage = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const location = useLocation()
  const clientOrHousehold = client ?? household
  const [goalDetails, setGoalDetails] = useState<GoalDetail[]>(clientOrHousehold?.goals?.goalDetails ?? [])
  const { goalExplorer: { selectedGoal, setSelectedGoal, shouldValidate, selectedGoals, setSelectedGoals } } = useContext(RMJourneyContext)
  const { selectedPortfolios } = useContext(RMJourneyContext)

  const outsideIM = Boolean(
    (clientId && location.pathname.startsWith(`/clients/${clientId}/explore`)) ||
      (householdId && location.pathname.startsWith(`/households/${householdId}/explore`))
  )

  const sortedGoalDetails = useMemo(
    () =>
      [...goalDetails].sort((a, b) => {
        const priorityA = a.priority ? GoalPrioritiesOrder.indexOf(a.priority) : Infinity
        const priorityB = b.priority ? GoalPrioritiesOrder.indexOf(b.priority) : Infinity
        return priorityA - priorityB
      }),
    [goalDetails]
  )

  useEffect(() => {
    setGoalDetails(clientOrHousehold?.goals?.goalDetails ?? [])
  }, [clientOrHousehold?.goals?.goalDetails])

  useEffect(() => {
    if (!selectedGoal) {
      const goals =
      [...(clientOrHousehold?.goals?.goalDetails ?? [])].sort((a, b) => {
        const priorityA = a.priority ? GoalPrioritiesOrder.indexOf(a.priority) : Infinity
        const priorityB = b.priority ? GoalPrioritiesOrder.indexOf(b.priority) : Infinity
        return priorityA - priorityB
      }) ?? []
    setSelectedGoal(goals[0])
    }
  }, [clientOrHousehold?.goals?.goalDetails, selectedGoal, setSelectedGoal])

  const onChange = useCallback(
    (selectedGoals: SelectedGoal[]) => {
      setSelectedGoals(selectedGoals)
    },
    [setSelectedGoals]
  )

  return (
    <>
      {client || household ? (
        !goalDetails || goalDetails.length === 0 ? (
          <div className="flex flex-col py-9 px-10">
            <h2 className="text-sec uppercase mb-1">Goal Projector</h2>
            <h3 className="text-h3 font-semibold mb-10">Please select all the goals that are relevant to you</h3>
            <GoalsAdd onChange={onChange} selectedGoals={selectedGoals} />
          </div>
        ) : (
          <>
            <div className="flex flex-row justify-between items-center gap-x-6">
              <div className="flex flex-row w-full overflow-auto">
                {sortedGoalDetails.map((goal, i) => {
                  const isSelected = selectedGoal?.type === goal.type && selectedGoal.id === goal.id
                  return (
                    <div key={i}>
                      <label
                        htmlFor={goal.type + goal.id}
                        className={clsx(
                          "relative flex flex-row gap-x-1 items-center text-left text-sm py-4 pl-3 pr-2.5 cursor-pointer text-interactive-500 whitespace-nowrap",
                          {
                            "font-bold border-b-2 border-interactive-500": isSelected,
                            "font-semibold": !isSelected
                          }
                        )}
                      >
                        <RadioButton
                          name="selected-goal"
                          id={goal.type + goal.id}
                          className="hidden"
                          checked={isSelected}
                          onChange={() => {
                            setSelectedGoal(goal)
                          }}
                          aria-checked={isSelected}
                        />
                        <span id={goal.type + goal.id + "-label"}>
                          <CardTitle client={client} household={household} goalDetails={goalDetails} goal={goal} />
                        </span>
                        {shouldValidate &&
                          ((goal.selectedPortfolio === undefined && selectedPortfolios[`${goal?.type}_${goal?.id}`] === undefined) ||
                            !goal.targetAmount?.value) && <img src={alert} className="h-4 w-4" alt="No selected investment" />}
                      </label>
                    </div>
                  )
                })}
              </div>

              <AddGoal client={client} household={household} goalDetails={goalDetails} onUpdate={setSelectedGoal} className="!text-xs !font-bold" />
            </div>
            <div className=" w-full h-full flex flex-row">
              {(client || household) && selectedGoal ? (
                <>
                  {selectedGoal?.type === "retirementIncome" ? (
                    <div className="relative w-full h-full">
                      <RetirementIncome client={client} household={household} outsideIM={outsideIM} onDelete={() => setSelectedGoal(undefined)} />
                    </div>
                  ) : (
                    <div className="relative w-full">
                      <WealthAccumulation
                        client={client}
                        household={household}
                        outsideIM={outsideIM}
                        goalType={selectedGoal?.type}
                        goalId={selectedGoal?.id}
                        onDelete={() => setSelectedGoal(undefined)}
                      />
                    </div>
                  )}
                </>
              ) : (
                <Loading />
              )}
            </div>
          </>
        )
      ) : (
        <Loading />
      )}
    </>
  )
}

export default RMJGoalExplorerPage
