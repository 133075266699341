import React, { useCallback, useEffect, useRef, useState } from "react"
import { Household } from "../../../../../models/Household"
import { Client } from "../../../../../models/Client"
import { parseISO } from "date-fns"

interface Props {
  household?: Household
  client?: Client
  moneyRunoutYear: number
}

const Tooltip = ({ household, client, moneyRunoutYear }: Props) => {
  const [tooltipSize, setTooltipSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 })
  const tooltipRef = useRef<HTMLDivElement>(null)
  const offSetX = 24 + tooltipSize.width
  const offSetY = client ? 20 : 25
  const currentYear = new Date().getFullYear()
  const diff = moneyRunoutYear - currentYear
  useEffect(() => {
    if (tooltipRef.current) {
      setTooltipSize({
        width: tooltipRef.current.clientWidth,
        height: tooltipRef.current.clientHeight
      })
    }
  }, [])

  const infix = useCallback((age: number) => {
    return age >= 120 ? "+" : ""
  }, [])

  const clientAge = useCallback(
    (dob: string) => {
      return currentYear - parseISO(dob).getFullYear()
    },
    [currentYear]
  )
  return (
    <div
      ref={tooltipRef}
      style={{ transform: `translate(${offSetX}px,${-offSetY}px)` }}
      className="absolute top-1/2 right-1/2 bg-white px-3 py-3 text-sm text-main-500 shadow border border-interactive-200 w-max"
    >
      <div className="flex flex-col">
        {household &&
          household.members?.map(({ client }) => {
            const age = diff + clientAge(client.dob)

            return (
              <div key={client._id} className="flex justify-between gap-x-2">
                <p>{client.firstName}'s age</p>
                <p>
                  {age}
                  {infix(age)} yrs
                </p>
              </div>
            )
          })}
      </div>
      {client && (
        <div className="flex gap-x-2">
          <p>{client?.firstName}'s age</p>
          <p>
            {diff + clientAge(client.dob)}
            {infix(diff + clientAge(client.dob))} yrs
          </p>
        </div>
      )}
    </div>
  )
}

export default Tooltip
