import { useMemo } from "react"
import { Game } from "../models/Client"

const useRetirementRiskMindset = (games?: Game | Game[]) => {
  return useMemo(() => {
    const gameArray = Array.isArray(games) ? games : games ? [games] : []

    if (gameArray.length > 0 && gameArray.every((game) => game?.gameType === "retirement" && (game?.retirement?.decisions?.GI?.length ?? 0) > 0)) {
      const totalChoiceX = gameArray.reduce((sum, game) => sum + (game.retirement.decisions?.GI?.reduce((subSum, item) => subSum + item.choiceX!, 0) ?? 0), 0)
      const totalDecisions = gameArray.reduce((sum, game) => sum + (game.retirement.decisions?.GI?.length || 0), 0) || 1

      const averageChoiceX = Math.round(totalChoiceX / totalDecisions)
      const score = 100 - averageChoiceX
      const rating = score <= 50 ? "high" : "low"
      return { score, rating }
    }
    return undefined
  }, [games])
}

export default useRetirementRiskMindset
