import clsx from "clsx"
import { PropsWithChildren } from "react"

const Section: React.FC<PropsWithChildren<{ className?: string; showSeparator?: boolean; isEmbedded?: boolean }>> = ({
  children,
  className,
  showSeparator,
  isEmbedded
}) => {
  return (
    <>
      <section className={clsx("results-tab-section py-10", className, { "px-16": !isEmbedded })}>{children}</section>
      {showSeparator && <hr className="border-surface-300" />}
    </>
  )
}

export default Section
