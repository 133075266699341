import { useContext, useMemo, useState } from "react"
import { reprofile } from "../../../api/clients"
import refreshIcon from "../../../assets/icons/refresh.svg"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { useTheme } from "../../../contexts/ThemeContext"
import { TourControllerContext } from "../../../contexts/TourController"
import { areDecisionsIncoherent } from "../../../lib/clients"
import { customDateFormat } from "../../../lib/date"
import { Client, Game } from "../../../models/Client"
import { Household } from "../../../models/Household"
import Popover from "../../../pages/advisor/components/Popover/Popover"
import { AuthContext } from "../../../views/auth/AuthContext"
import AlertPopover, { AlertPopoverOptions } from "../../AlertPopover/AlertPopover"
import ClientStatusAlert, { StatusAlert } from "./ClientStatusAlert"
import { tt } from "../../../lib/translations"
import ErrorMessage from "../../Error/ErrorMessage"
import InconsistentDecisionsWarning from "../../../pages/advisor/components/InconsistentDecisionsWarning/InconsistentDecisionsWarning"

const ClientGameActivityRow = ({
  client,
  game,
  household,
  index,
  setShowReprofileModal
}: {
  client: Client
  game: Game
  household?: Household
  index?: number
  setShowReprofileModal: ({ client, game }: { client: Client; game: Game }) => void
}) => {
  const { firstName } = client
  const { sessionInfo } = useContext(AuthContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const theme = useTheme()
  const { tourSteps, progress, step, completeStep, isLastStep } = useContext(TourControllerContext)
  const [infoAlert, setInfoAlert] = useState<StatusAlert | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const warningMessage =
    "This profile showed inconsistencies. Please re-profile this client to get a clear read of their preferences before you present their results."
  const clientIndex = index ?? 0

  const onReprofile = () => {
    if (areDecisionsIncoherent(game)) {
      setInfoAlert({
        message: "Re-profiling...",
        timeout: false,
        type: "activity"
      })
      reprofile(sessionInfo!, client._id, game!.experimentGroupId!)
        .then((updatedClient) => {
          replace(updatedClient)
          setInfoAlert({
            message: "Re-invite client",
            onClose: () => setInfoAlert(undefined),
            type: "success"
          })
        })
        .catch((error) => {
          console.error("error updating client details", error)
          setInfoAlert(undefined)
          setErrorMessage("Error re-profiling")
        })
    } else {
      setShowReprofileModal({ client, game })
    }
  }

  const alertPopoverOptions: AlertPopoverOptions | undefined = useMemo(
    () =>
      infoAlert
        ? {
            content: <ClientStatusAlert type={infoAlert.type} message={infoAlert.message} />,
            onClose: infoAlert.onClose,
            timeoutMS: infoAlert.timeout === false ? null : 5000
          }
        : undefined,
    [infoAlert]
  )

  return (
    <>
      <div className="flex gap-3 py-3 items-center justify-between">
        <div className="flex gap-2 items-center text-p text-main-500">
          <span>
            {household
              ? tt({ id: `profile-page-household-member-${game.gameType}-completed`, values: { firstName, playedDate: customDateFormat(game.played) } })
              : tt({ id: `profile-page-client-${game.gameType}-completed`, values: { playedDate: customDateFormat(game.played) } })}
          </span>
          {areDecisionsIncoherent(game) && <InconsistentDecisionsWarning type="client" names={[client.firstName]} />}
          <p className="sr-only">{warningMessage}</p>
        </div>
        <AlertPopover options={alertPopoverOptions} show={!!infoAlert}>
          <Popover
            active={theme.tours.gameComplete.show && step.gameComplete === "Re-profile" && clientIndex === 0}
            content={
              <>
                <p className="font-semibold text-main-500 text-sec">Re-profile your client if you wish to override their previous results.</p>
                <div className="flex flex-row justify-between items-center mt-5">
                  <p className="text-main-400 text-sec">
                    {progress.gameComplete + 1} of {tourSteps.gameComplete.length}
                  </p>
                  <button className="btn btn-secondary btn-xs w-21" onClick={() => completeStep({ gameComplete: "Re-profile" })}>
                    {isLastStep("gameComplete") ? "Got it" : "Next"}
                  </button>
                </div>
              </>
            }
            position="left"
            onClose={() => completeStep({ gameComplete: "Re-profile" })}
          >
            <button className="btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={() => onReprofile()}>
              <img src={refreshIcon} alt="" aria-hidden />
              Re-profile
            </button>
          </Popover>
        </AlertPopover>
      </div>
      <div role="alert">{errorMessage && <ErrorMessage id="client-game-activity-row" message={errorMessage} />}</div>
    </>
  )
}

export default ClientGameActivityRow
