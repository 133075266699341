import LeadGenDemoPage from '../pages/demo/LeadGenDemoPage'
import EconomicFingerprintPage from '../pages/efp/EconomicFingerprintPage'
import AdvisorApp from './AdvisorApp'
import { useProduct } from '../contexts/ProductContext'
import PrivateMarketsApp from './PrivateMarketsApp'

const ProductRoutes = () => {
  const { product } = useProduct()

  switch (product) {
    case 'lead-gen':  return <LeadGenDemoPage />
    case 'prma':      return <PrivateMarketsApp />
    case 'efp':       return <EconomicFingerprintPage />
    
    // Future state 1 + 2:

    // case 'simulator': return <SimulatorApp />
    // case 'all':       return <>
    //   <Routes>
    //     <Route path="/prm" element={<PrivateMarketsApp />}/>
    //     <Route path="*" element={<AdvisorApp/>}/>
    //   </Routes>
    // </>
    
    case 'advisor':
    default:          return <AdvisorApp />
  }
}

export default ProductRoutes
