import { PropsWithChildren, createContext, useCallback, useState } from "react"
import { GoalDetail, GoalType } from "../models/Client"
import { SelectedGoal } from "../pages/advisor/Results/Goals/GoalsAdd"

type RMJourneyContextType = {
  selectedPortfolios: Record<string, string | null | undefined>
  updateSelectedPortfolio: (goalPortfolio: GoalPortfolio) => void
  goalExplorer: {
    selectedGoals: SelectedGoal[]
    setSelectedGoals: (goals: SelectedGoal[]) => void
    selectedGoal?: GoalDetail
    setSelectedGoal: (goal?: GoalDetail) => void
    shouldValidate: boolean
    setShouldValidate: (val: boolean) => void
  }
}

type GoalPortfolio = {
  goalType?: GoalType
  goalId?: string
  portfolio?: string | null
}

const defaults: RMJourneyContextType = {
  selectedPortfolios: {},
  updateSelectedPortfolio: () => {},
  goalExplorer: {
    selectedGoals: [],
    setSelectedGoals: () => {},
    setSelectedGoal: () => {},
    shouldValidate: false,
    setShouldValidate: () => {}
  }
}

export const RMJourneyContext = createContext<RMJourneyContextType>(defaults)

const RMJourneyContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [selectedPortfolios, setSelectedPortfolios] = useState<Record<string, string | null | undefined>>({})
  const [selectedGoal, setSelectedGoal] = useState<GoalDetail>()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [selectedGoals, setSelectedGoals] = useState<SelectedGoal[]>([])

  const updateSelectedPortfolio = useCallback((goalPortfolio: GoalPortfolio) => {
    setSelectedPortfolios((prev) => ({
      ...prev,
      [`${goalPortfolio?.goalType}_${goalPortfolio?.goalId}`]: goalPortfolio.portfolio
    }))
  }, [])

  return (
    <RMJourneyContext.Provider
      value={{ selectedPortfolios, updateSelectedPortfolio, goalExplorer: { selectedGoal, setSelectedGoal, shouldValidate, setShouldValidate, selectedGoals, setSelectedGoals } }}
    >
      {children}
    </RMJourneyContext.Provider>
  )
}

export default RMJourneyContextProvider
