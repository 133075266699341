import { useMemo } from "react"
import { latest } from "../../../../lib/clients"
import { Client, Game } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { customDateFormat } from "../../../../lib/date"
import ScoreChart from "../components/ScoreChart/ScoreChart"
import useTaxScore from "../../../../hooks/useTaxScore"
import clsx from "clsx"
import { tt } from "../../../../lib/translations"

import TaxIcon from "./assets/TaxIcon"

const TaxTab = ({ client, household, isEmbedded }: { client?: Client; household?: Household; isEmbedded?: boolean }) => {
  const clientOrHousehold = (client ?? household)!
  const game = latest(clientOrHousehold, "tax")

  const latestHouseholdGames = useMemo(() => {
    return household?.members.map((member) => latest(member.client!, "tax")) ?? []
  }, [household?.members])

  const lastUpdatedGame = household
    ? latestHouseholdGames.reduce(
        (latest, game) => (new Date(game?.played ?? 0) > new Date(latest?.played ?? 0) ? game : latest),
        null as (typeof latestHouseholdGames)[number] | null
      )
    : game
  const lastUpdatedDate = lastUpdatedGame?.played
  const completedMembers = household && household.members.filter((_, index) => latestHouseholdGames[index]?.played)

  const taxGames = household && completedMembers
    ? completedMembers?.length === 1
      ? latest(completedMembers[0].client, "tax")
      : completedMembers?.length === household.members.length
      ? latestHouseholdGames.filter((game): game is Game => !!game) // Filter out undefined values
      : undefined
    : game // Not a household, use client game

  const optimisationScore = useTaxScore(taxGames)

  const title = useMemo(() => {
    const name = completedMembers && completedMembers?.length === household.members.length ? "Household" : completedMembers?.length === 1 ? completedMembers[0].client.firstName : client?.firstName
    return `${completedMembers && completedMembers?.length === household.members.length ? name : name + "'s"} profile completed on ${customDateFormat(lastUpdatedDate!)}`
  }, [completedMembers, household?.members.length, client?.firstName, lastUpdatedDate])

  const names = household ? completedMembers?.map((m) => m.client.firstName).join(" and ") : client?.firstName
  const isPlural = completedMembers && completedMembers?.length === household.members.length

  return (
    <div className={clsx("tax-tab w-full flex flex-col items-start gap-10 py-10", { "px-16": !isEmbedded })}>
      <h1 className="sr-only">Retirement income</h1>
      <section className="flex justify-between items-center gap-2 w-full text-left">
        <p>{title}</p>
      </section>
      <section className="w-full flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <h2 className="text-emph uppercase">Tax optimization</h2>
          <p className="text-h3 font-semibold">
            {tt({ id: `tax-optimization-title-${optimisationScore?.rating}-${isPlural ? "plural" : "individual"}`, values: { names } })}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p>{tt({ id: `tax-optimization-description-${optimisationScore?.rating}-${isPlural ? "plural" : "individual"}`, values: { names } })}</p>
          <p>
            A tax-efficient portfolio employs tax-loss harvesting and asset allocation strategies to enhance after-tax returns while maintaining performance
            comparable to your current strategy.
          </p>
        </div>
      </section>
      <hr className="w-full border-surface-300" />
      <section className="w-full flex flex-col gap-6">
        <div className="flex justify-between">
          <div className="flex flex-col gap-1">
            <h2 className="text-emph uppercase">Tax efficiency score</h2>
            <p className="text-h3 font-semibold">
              {tt({ id: `tax-efficiency-score-title-${optimisationScore?.rating}-${isPlural ? "plural" : "individual"}`, values: { names } })}
            </p>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-h2 font-semibold text-main-400">{optimisationScore?.score}</span>
            <span className="text-sm">Tax efficiency score</span>
          </div>
        </div>
        {Number.isFinite(optimisationScore?.score) && (
          <div className="risk-attitude-line-chart w-full self-center !h-80 mb-6">
            <ScoreChart
              client={household ? completedMembers?.[0].client : client}
              score={optimisationScore!.score}
              title={`${names}'s overall preference for tax optimization`}
              color="#28A4AC"
              icon={<TaxIcon />}
            />
            <div className="flex justify-between items-center text-sec font-semi text-main-400">
              <span>Minimize benchmark deviation</span>
              <span>Minimize tax burden</span>
            </div>
          </div>
        )}
      </section>
    </div>
  )
}

export default TaxTab
