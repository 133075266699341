import { clsx } from "clsx"
import { useContext, useMemo, useState } from "react"
import { useTheme } from "../../../contexts/ThemeContext"
import { latest } from "../../../lib/clients"
import { customDateFormat } from "../../../lib/date"
import { tt } from "../../../lib/translations"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import downloadIcon from "../../advisor/assets/images/download.svg"
import { causeScoresForProfile, clientTopCause, clientTopCauseByGame } from "../../clients/components/Profile/causeScores"
import CauseScores from "../../clients/components/Profile/components/PersonaSummary/CauseScores"
import ValuesPersonaSummary from "./ValuesPersonaSummary"
import Section from "./Section"
import { AuthContext } from "../../../views/auth/AuthContext"
import { useSearchParams } from "react-router-dom"
import ValuesMindset from "../../clients/reports/components/ValuesMindset/ValuesMindset"
import CommentsSection from "./components/Comments/CommentsSection"
import checkmark from "../../advisor/assets/images/checkmark-brown.svg"
import infoIcon from "../../advisor/assets/images/info.svg"
import { AnimatePresence } from "framer-motion"
import Modal from "../../../components/Modal/Modal"
import { CauseDetail } from "./CauseDetail"

const InvestmentValuesTab = ({ client, household }: { client?: Client; household?: Household }) => {
  const theme = useTheme()
  const { sessionInfo } = useContext(AuthContext)
  const [queryParams] = useSearchParams()
  const isEmbedded = !!queryParams.get("facet")
  const [themesInfoModalOpen, setThemesInfoModalOpen] = useState(false)

  const game = latest(client!, "esg")
  const latestHouseholdGames = useMemo(() => {
    return household?.members.map(({ client }) => latest(client!, "esg")) ?? []
  }, [household?.members])

  const completedMembers = household && household.members.filter((_, index) => latestHouseholdGames[index]?.played)
  const incompleteMembers = household && household.members.filter((_, index) => !latestHouseholdGames[index]?.played)

  const householdStatus = useMemo(() => {
    if (!household) return null
    if (completedMembers!.length === household.members.length) {
      const lastPlayedGame = latestHouseholdGames
        .filter((game) => game!.played)
        .sort((a, b) => new Date(b!.played).getTime() - new Date(a!.played).getTime())[0]
      return `Household profile completed on ${customDateFormat(lastPlayedGame!.played, "d MMM yyyy")}`
    } else {
      const completedText = completedMembers! // If we're on this tab, at least one member has completed the values game
        .map((member) => {
          const playedDate = latestHouseholdGames.find((game) => game?.played)?.played
          return `${member.client.firstName} completed the investment values activity on ${customDateFormat(playedDate!, "d MMM yyyy")}`
        })
        .join(". ")
      const pendingText = incompleteMembers!.map((member) => `${member.client.firstName}'s investment values activity is still pending.`).join(" ") // If completed members are less than 2, then at least one is incomplete
      return `${completedText}.${incompleteMembers!.length > 0 ? ` ${pendingText}` : ""}`
    }
  }, [completedMembers, household, incompleteMembers, latestHouseholdGames])

  const clientScores = useMemo(
    () =>
      client
        ? [{ client, scores: causeScoresForProfile(client, theme) }]
        : household
        ? completedMembers?.map(({ client }) => ({
            client,
            scores: causeScoresForProfile(client, theme)
          }))
        : [],
    [client, completedMembers, household, theme]
  )

  const topCause = clientTopCauseByGame(game!, theme)
  const hasSdgItemsIcon = useMemo(() => topCause?.learn?.devGoals?.items.find((item) => !!item.icon), [topCause?.learn?.devGoals?.items])
  const gameReportUrl = `${household && completedMembers?.length === household.members.length ? latest(household, "esg")?.gameReportUrl : game?.gameReportUrl}${
    isEmbedded ? `&_ssoToken=${sessionInfo?.idToken}&_ssoAccessToken=${sessionInfo?.accessToken}&_ssoRefreshToken=${sessionInfo?.refreshToken}` : ""
  }`

  const altruismScores = client
    ? [{ client, score: Object.values(game?.esg?.results?.S2 ?? {})?.[1]?.score }]
    : household
    ? completedMembers?.map(({ client }) => ({ client, score: Object.values(latest(client, "esg")?.esg?.results?.S2 ?? {})?.[1]?.score }))
    : []

  const topCauses = useMemo(
    () =>
      client
        ? [{ client, topCause: clientTopCauseByGame(game!, theme) }]
        : completedMembers?.map(({ client }) => ({ client, topCause: clientTopCause(client, theme) })),
    [client, completedMembers, game, theme]
  )

  return (
    <div className="esg-tab my-10">
      <h1 className="sr-only">Investment Values</h1>
      <Section className="!py-0 flex justify-between items-center" showSeparator={false} isEmbedded={isEmbedded}>
        <p>
          {client && `${client?.firstName}'s profile completed on ${customDateFormat(game!.played)}`}
          {household && householdStatus}
        </p>
        {theme.pages.resultsPage?.investmentValues?.viewReport?.show && gameReportUrl && (
          <a
            href={gameReportUrl}
            target="_blank"
            rel="noreferrer"
            aria-disabled={household && (completedMembers?.length ?? 0) < household.members.length}
            className={clsx(
              "btn btn-text btn-text-medium flex gap-2 items-center text-sec font-bold",
              household && (completedMembers?.length ?? 0) < household.members.length && "opacity-50 hover:bg-transparent"
            )}
          >
            <span>View report</span>
            <img src={downloadIcon} alt="" aria-hidden />
          </a>
        )}
      </Section>
      {theme.pages.profile.resultsTabs?.esg?.map((section, i) => {
        if (section === "valuesPersonaSummary") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.esg?.length !== i + 1} isEmbedded={isEmbedded}>
              <ValuesPersonaSummary client={client!} topCause={topCause!} />
            </Section>
          )
        } else if (section === "causeScores") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.esg?.length !== i + 1} className="pb-7" isEmbedded={isEmbedded}>
              <h2 className="cause-scores-header text-main-500 tracking-wide text-sec uppercase mb-1">Areas of interest</h2>
              <h3 className="text-h3 font-semibold mb-3">
                {(client || (household && completedMembers?.length && completedMembers.length < household.members.length)) &&
                  tt({
                    id: `values-results-page-scores-individual-${client ? topCause?.id : clientTopCause(completedMembers![0].client, theme)?.id}-title`,
                    values: { name: client ? client.firstName : completedMembers![0].client.firstName }
                  })}
                {household &&
                  completedMembers?.length === household.members.length &&
                  tt({
                    id: "values-results-page-scores-household-title",
                    values: {
                      names: completedMembers?.map(({ client }) => client.firstName).join(" and ")
                    }
                  })}
              </h3>
              <div className="flex gap-x-9">
                {clientScores
                  ?.filter(({ scores }) => scores?.length)
                  .map(({ client, scores }) => (
                    <div key={client._id} className="flex flex-col w-full">
                      <p className="text-sec font-semibold mb-3">
                        {household &&
                          completedMembers?.length === household.members.length &&
                          tt({ id: `values-results-page-scores-individual-${clientTopCause(client, theme)?.id}-title`, values: { name: client.firstName } })}
                      </p>
                      <CauseScores scores={scores} />
                    </div>
                  ))}
              </div>
            </Section>
          )
        } else if (section === "interests") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.esg?.length !== i + 1} isEmbedded={isEmbedded}>
              <h2 className="interests-header text-main-600 font-semibold tracking-wide text-sec uppercase mb-1">Learning Opportunities</h2>
              <h3 className="text-h3 mb-6">{client!.firstName} told us they're interested in learning more about...</h3>
              <ul className="list-disc pl-6">
                {client?.interests?.map((interest) => (
                  <li key={interest} className="mb-2">
                    {tt({ id: `selected-interests-${interest}` })}
                  </li>
                ))}
              </ul>
            </Section>
          )
        } else if (section === "sustainabilityMindset") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.esg?.length !== i + 1} isEmbedded={isEmbedded}>
              <ValuesMindset clientScores={altruismScores} />
            </Section>
          )
        } else if (section === "investmentThemes") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.esg?.length !== i + 1} isEmbedded={isEmbedded}>
              <h2 className="investment-themes-header text-main-500 tracking-wide text-sec uppercase mb-1">
                {client && tt({ id: `values-results-page-investment-themes-${topCause?.id}-header` })}
                {household && "Investing in your areas of interest"}
              </h2>
              <div className="flex items-center mb-6 gap-x-1 p-1">
                <h3 className="text-h3 font-semibold">
                  {tt({
                    id: "values-results-page-investment-themes-title",
                    values: {
                      names: client ? [client.firstName] : completedMembers?.map(({ client }) => client.firstName).join(" and ")
                    }
                  })}
                </h3>
                <button aria-label="Open the theme information modal" onClick={() => setThemesInfoModalOpen(true)}>
                  <img src={infoIcon} aria-hidden alt="" className="w-4 h-4" />
                </button>
              </div>
              <div className="flex w-full gap-x-9">
                {topCauses?.map(({ client, topCause }) => (
                  <div key={client._id} className="flex flex-col gap-y-5 w-full">
                    {household && <p className="font-semibold text-sec text-main-500">{client.firstName}</p>}
                    <ul className="list-disc leading-6 mb-6">
                      {topCause?.items?.map((item, i) => (
                        <li key={`item-${i}`} className="relative flex items-start mb-1">
                          <img className="inline mt-1 mr-2 absolute" src={checkmark} alt="checkmark" />
                          <span className="pl-6">{item.title}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              {(topCauses?.filter(({ topCause }) => topCause?.learn?.devGoals?.items.length).length ?? 0) > 0 && (
                <div className="flex flex-col gap-y-6">
                  <p>
                    {client
                      ? "This value aligns with the following United Nations Sustainable Development Goals (UN SDG):"
                      : "These values align with the following United Nations Sustainable Development Goals (UN SDG):"}{" "}
                  </p>
                  <div className="flex w-full gap-x-9">
                    {topCauses?.map(({ client, topCause }) => (
                      <div key={client._id} className="flex flex-col gap-y-5 w-full">
                        {topCause?.learn?.devGoals?.items.length && (
                          <>
                            {household && <p className="font-semibold text-sec text-main-500">{client.firstName}</p>}
                            <ul className={clsx("leading-6", hasSdgItemsIcon ? "list-none flex flex-col gap-y-2" : "list-disc")}>
                              {topCause?.learn?.devGoals?.items.map((item, i) => (
                                <li className={item.icon && "flex p-0"} key={i}>
                                  {item.icon && <img alt="" src={item.icon} className="h-5 w-5 mr-2" aria-hidden />}
                                  {item.description}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Section>
          )
        } else if (section === "comments") {
          return (
            <Section key={section} className="!py-0" showSeparator={theme.pages.profile.resultsTabs?.esg?.length !== i + 1} isEmbedded={isEmbedded}>
              <CommentsSection gameType="esg" client={client} household={household} />
            </Section>
          )
        }
      })}
      <AnimatePresence>
        {themesInfoModalOpen && (
          <Modal className="h-full modal-medium" contentClassName="h-full overflow-y-auto no-scrollbar" handleClose={() => setThemesInfoModalOpen(false)}>
            <div className="flex flex-col gap-y-6 w-full">
              <h3 className="text-h3 font-semibold">Understanding the values</h3>
              <div className="flex flex-col">
                {Object.values(theme.causes?.S1 ?? {})
                  .filter((cause) => !cause.isDefault)
                  .map((cause) => <CauseDetail cause={cause} key={cause.id} />)}
              </div>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default InvestmentValuesTab
