import { useTheme } from "../../../contexts/ThemeContext"
import useNumberFormatter from "../../../hooks/useNumberFormatter"
import { tt } from "../../../lib/translations"
import { FrequencyOption } from "../../../models/Client"

export type PFSReportRow = {
  type: "header" | "subheader" | "gain" | "loss" | "gain-total" | "loss-total" | "separator"
  content?: JSX.Element | string
  amount?: number
  frequency?: FrequencyOption
}

export const ROWS_PER_PAGE = 22

export const getPageRows = (allRows: PFSReportRow[], pageNum: number, totalPages: number) => {
  const MINIMUM_ITEMS_NEXT_PAGE = 3
  const startIndex = (pageNum - 1) * ROWS_PER_PAGE

  if (pageNum === totalPages && startIndex > 0) {
    // For the last page, check if we have too few items
    const remainingItems = allRows.length - startIndex

    if (remainingItems < MINIMUM_ITEMS_NEXT_PAGE) {
      // Take some items from the previous page to ensure minimum items
      const adjustedStartIndex = startIndex - (MINIMUM_ITEMS_NEXT_PAGE - remainingItems)
      return allRows.slice(adjustedStartIndex)
    }
  }

  if (pageNum === totalPages - 1) {
    // For the second-to-last page, adjust its end point if needed
    const remainingItems = allRows.length - (startIndex + ROWS_PER_PAGE)
    if (remainingItems > 0 && remainingItems < MINIMUM_ITEMS_NEXT_PAGE) {
      // Reduce this page's items to ensure minimum items on last page
      return allRows.slice(startIndex, allRows.length - MINIMUM_ITEMS_NEXT_PAGE)
    }
  }

  // Default case: return normal page slice
  return allRows.slice(startIndex, startIndex + ROWS_PER_PAGE)
}

const PFSRowDetail = ({ row }: { row: PFSReportRow }) => {
  const theme = useTheme()
  const numberFormatter = useNumberFormatter(theme.defaultLocale || "en-US")
  if (row.type === "header") {
    return <h3 className="font-semibold">{row.content}</h3>
  } else if (row.type === "subheader") {
    return <h4 className="font-semibold px-3">{row.content}</h4>
  } else if (row.type === "separator") {
    return <hr className="text-surface-300"/>
  } else if (row.type === "gain-total") {
    return (
      <div className="flex w-full justify-between bg-surface-100 py-4 px-3">
        <p className="font-semibold">{row.content}</p>
        <p className="text-positive-600">
          {tt({ id: "currency-symbol", message: "$" })}
          {numberFormatter.format(row.amount!)}
          {row.frequency && tt({ id: `${row.frequency}-option-postfix` })}
        </p>
      </div>
    )
  } else if (row.type === "loss-total") {
    return (
      <div className="flex w-full justify-between bg-surface-100 py-4 px-3">
        <p className="font-semibold">{row.content}</p>
        <p className="text-red-600">
          {tt({ id: "currency-symbol", message: "$" })}
          {numberFormatter.format(row.amount!)}
          {row.frequency && tt({ id: `${row.frequency}-option-postfix` })}
        </p>
      </div>
    )
  } else {
    return (
      <div className="flex w-full justify-between mx-3 pr-6">
        <p>{row.content}</p>
        <p className={row.type === "gain" ? "text-positive-600" : "text-red-600"}>
          {tt({ id: "currency-symbol", message: "$" })}
          {numberFormatter.format(row.amount!)}
          {row.frequency && tt({ id: `${row.frequency}-option-postfix` })}
        </p>
      </div>
    )
  }
}

export default PFSRowDetail
