import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { useQuery } from "react-query";
import defaultTheme from "../config/default";
import theme, { SupportedLocales, Theme, switchTheme } from "../config/theme";
import { FirmContext } from "./FirmContext";

export const ThemeContext = createContext<{ theme: Theme, isLoading?: boolean }>({
  theme: defaultTheme
})

const ThemeContextProvider: React.FC<PropsWithChildren<{ fallbackToBuiltInTheme?: boolean }>> = ({ children, fallbackToBuiltInTheme = true }) => {
  
  const { firm } = useContext(FirmContext)
  
  const { data: defaultLoadedTheme } = useQuery(['theme'], () => theme(), {
    enabled: !firm,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })
  
  const { data: selectedTheme, isLoading } = useQuery(['theme', firm?.theme], () => theme(firm!.theme), {
    enabled: !!firm,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })
  
  const effectiveLoadedTheme = selectedTheme ?? defaultLoadedTheme
  useEffect(() => {
    if (effectiveLoadedTheme) {
      const locale = firm?.locale ?? import.meta.env.VITE_APP_DEFAULT_LOCALE as SupportedLocales ?? effectiveLoadedTheme.defaultLocale ?? "en"
      switchTheme(effectiveLoadedTheme.id)
      i18n.load({
        [locale]: effectiveLoadedTheme.messages?.[locale] ?? {},
      });
      i18n.activate(locale)
    }
  }, [effectiveLoadedTheme, firm?.locale])

  return (
    (effectiveLoadedTheme || fallbackToBuiltInTheme)
      ? <ThemeContext.Provider value={{
          theme: effectiveLoadedTheme ?? defaultTheme,
          isLoading
        }}>
          <I18nProvider i18n={i18n}>
            {children}
          </I18nProvider>
        </ThemeContext.Provider>
      : <></> // we avoid to render without theme present to prevent FOUC
  )
}

export const useTheme: () => Theme = () => {
  return useContext(ThemeContext).theme
}

export default ThemeContextProvider