import { useContext, useState } from "react"
import { Helmet } from "react-helmet-async"
import { AppContext } from "../../contexts/AppContext"
import SearchBox from "../advisor/components/SearchBox"
import { t, Trans } from "@lingui/macro"
import useTrackViewEvent from "../../hooks/useTrackViewEvent"
import { AnimatePresence } from "framer-motion"
import Drawer from "../../components/Drawer/Drawer"
import CreateFirmModal from "./CreateFirmModal"

const FirmsListPage = () => {
  const trackViewEvent = useTrackViewEvent()
  const { userProfile } = useContext(AppContext)
  const [search, setSearch] = useState<string>()
  const [modal, setModal] = useState<"create" | null>()
  const numClients = 0 // TODO should be actual count of clients, for whether to show empty state

  return (
    <>
      <Helmet>
        <title>{import.meta.env.VITE_APP_TITLE ?? "Private Markets"}</title>
      </Helmet>
      <div className="firms-list-page pg-ctr w-full h-full bg-surface-100 text-main-600 p-10 flex flex-col gap-10 items-stretch overflow-y-auto no-scrollbar absolute">
        <h1 className="firms-list-page__header font-semibold text-h2">Welcome, {userProfile?.firstName}</h1>
        <div className="flex items-center gap-5 justify-between">
          <div className="w-1/2">
            <SearchBox
              className="firms-list-page__searchbox"
              label="Search"
              placeholder={t({ id: "firms-list-search-placeholder", message: "Firm name" })}
              value={search}
              onChange={(val) => {
                setSearch(val)
              }}
              onClear={() => {
                setSearch("")
              }}
            />
          </div>
          <div className="flex gap-5 items-center">
            {numClients > 0 && (
              <button
                className="btn btn-primary btn-medium py-3.5 w-44 text-center mt-4.5"
                onClick={() => {
                  trackViewEvent({ action: "click", category: "firm_creation", label: "create_new_firm_btn" })
                  setModal("create")
                }}
              >
                <Trans id="firms-list-create-new-firm">Create new firm</Trans>
              </button>
            )}
          </div>
        </div>
        <div>
          {/* TODO Add firms table */}
          <div className="flex flex-col items-center py-10 px-6 bg-[#F1EFECCC]">
            <p>You have no firms yet, start by adding one.</p>
            <button
              className="btn btn-primary btn-medium py-3.5 w-44 text-center mt-4.5 border-focuscolor shadow-focus"
              onClick={() => {
                trackViewEvent({ action: "click", category: "firm_creation", label: "create_new_firm_btn" })
                setModal("create")
              }}
            >
              <Trans id="firms-list-create-new-firm">Create new firm</Trans>
            </button>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {modal === "create" && (
          <Drawer handleClose={() => setModal(null)}>
            <CreateFirmModal handleClose={() => setModal(null)} />
          </Drawer>
        )}
      </AnimatePresence>
    </>
  )
}

export default FirmsListPage
