import hero from "../../../assets/images/maintenance.svg"

const ComingSoon = () => (
  <div className="w-full h-full flex flex-col items-center justify-center gap-6">
    <img src={hero} alt="Road works sign" />
    <div className="flex flex-col items-center gap-2">
      <h4 className="text-h1 font-semibold">This feature is coming soon</h4>
      <p>Watch this space</p>
    </div>
  </div>
)

export default ComingSoon