const TaxIcon = () => {
  return (
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.73276 11.67C4.66624 11.67 4.59913 11.6589 4.5332 11.635C4.28112 11.5428 4.12357 11.2907 4.15275 11.0241L4.60147 7.46874H0.583432C0.368118 7.46874 0.170309 7.35029 0.0687787 7.16065C-0.0327516 6.97043 -0.0210817 6.73994 0.0985374 6.56081L5.28534 0.259508C5.43413 0.0354406 5.71655 -0.0567536 5.96921 0.0354406C6.22187 0.127051 6.37941 0.37971 6.35024 0.646373L5.90152 4.2011H9.91955C10.1349 4.2011 10.3327 4.32014 10.4342 4.50978C10.5357 4.7 10.5241 4.9299 10.4045 5.10904L5.21765 11.4109C5.10737 11.5766 4.92298 11.67 4.73276 11.67Z"
        fill="white"
      />
    </svg>
  )
}

export default TaxIcon
