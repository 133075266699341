import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from "react-query"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import axiosInstance from "../api/axiosInstance"
import { update, UserProfileUpdateRequest } from "../api/userProfiles"
import Loading from "../components/ClientProfile/Loading/Loading"
import SelectNavBar from "../layout/navbars/SelectNavBar"
import StandardLayout from '../layout/StandardLayout'
import { PERM } from '../models/Permission'
import { UserProfile } from "../models/UserProfile"
import SelectPage from "../pages/select/SelectPage"
import { AuthContext, AuthStatus } from '../views/auth/AuthContext'
import FirmContextProvider, { WaitForFirm } from "./FirmContext"
import ThemeContextProvider from "./ThemeContext"

export type PermissionType = {
  [key: string]: PERM | PERM[]
}


interface AppContextType {
  firmId?: string,
  setFirmId: Dispatch<SetStateAction<string | undefined>>,

  // formerly UserProfileContext
  userProfile?: UserProfile | null,
  updateUserProfile: (up:UserProfileUpdateRequest) => Promise<void>,
  isLoading: boolean,
  isSuccess?: boolean,
  error?: { message?: string } | null,
}

export const defaults:AppContextType = {
  setFirmId: () => {},

  // formerly UserProfileContext
  isLoading: false,
  isSuccess: false,
  updateUserProfile: () => Promise.reject(),
}

export const AppContext = createContext(defaults)



const AppContextProvider:React.FunctionComponent<{ children: any }> = ({ children }) => {

  const { authStatus, sessionInfo } = useContext(AuthContext)

  const [ searchParams ] = useSearchParams()
  const [firmId, setFirmId] = useState<string|undefined>(searchParams.get("firmId") ?? sessionStorage.getItem("firmId") ?? localStorage.getItem("firmId") ?? "")
  const location = useLocation()
  const navigate = useNavigate()

  const [latencyCompensatedUserProfile, setLatencyCompensatedUserProfile] = useState<UserProfile | null>()
  const [updateQueue, setUpdateQueue] = useState<UserProfileUpdateRequest[]>([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [needsSelect, setNeedsSelect] = useState(false)

  const {
    isLoading,
    isSuccess,
    error,
    data: user,
    refetch
  } = useQuery<UserProfile|null, { message?: string }>(
    ["userProfiles", sessionInfo?.accessToken],
    () => axiosInstance.get<UserProfile>(`${import.meta.env.VITE_APP_API_BASE||""}/api/user/profile`)
        .then(res => res.data)
        .catch(err => {
          if(err?.response?.status === 404) {
            return {
              firstName: sessionInfo?.idTokenPayload?.email?.split(/@/)[0],
              lastName: "",
              email: sessionInfo?.idTokenPayload?.email,
            } as UserProfile
          } else {
            throw err
          }
        }),
    {
      enabled: authStatus === AuthStatus.SignedIn && !!sessionInfo?.accessToken,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )

  const processQueue = useCallback(async () => {
    if (isProcessing) return
    if (updateQueue.length === 0) return
  
    setIsProcessing(true)
    let aggregatedUpdate: UserProfileUpdateRequest = {}
  
    for (const update of updateQueue) {
      aggregatedUpdate = { ...aggregatedUpdate, ...update }
    }
    setUpdateQueue([]) // Clear the queue after aggregating
  
    try {
      setLatencyCompensatedUserProfile(prev => ({
        ...(prev ?? user ?? {} as UserProfile),
        ...aggregatedUpdate
      }))
      Object.keys(aggregatedUpdate).length && await update(aggregatedUpdate)
      await refetch()
    } catch (error) {
      console.error('Failed to update user profile', error)
    } finally {
      setIsProcessing(false)
    }
  }, [updateQueue, isProcessing, refetch, user])
  
  const updateUserProfile = useCallback(
    async (up: UserProfileUpdateRequest) => {
      setUpdateQueue(prevQueue => [...prevQueue, up])
      processQueue()
    },
    [processQueue]
  )
  
  useEffect(() => {
    if (updateQueue.length > 0) {
      processQueue()
    }
  }, [updateQueue, processQueue])

  useEffect(() => {
    if (sessionStorage.getItem("firmId") !== firmId) {
      if (firmId) {
        sessionStorage.setItem("firmId", firmId)
      } else {
        sessionStorage.removeItem("firmId")
      }
    }
  }, [firmId])

  
  const firmsPerms = useMemo(() => user?.permissions?.filter(p => p.firmId), [user?.permissions])
  
  useEffect(() => {
    if (user) {
      if (!firmId/* && !/^\/(client|household)s\/[A-Fa-f0-9]+\//.test(location.pathname)*/) { // TODO: the check may or may not be required, we will see after testing
        if (firmsPerms) {
          if (firmsPerms.length === 1) {
            setFirmId(firmsPerms[0].firmId)
          } else if (firmsPerms.find(p => p.firmId === user.selectedFirm)) {
            setFirmId(user.selectedFirm)
          } else {
            setNeedsSelect(true)
          }
        }
      }
    }
  }, [firmId, firmsPerms, location.pathname, navigate, user])



  return (
    <AppContext.Provider value={{
      firmId,
      setFirmId,

      userProfile: latencyCompensatedUserProfile ?? user,
      updateUserProfile,
      isLoading,
      isSuccess,
      error,
    }}>
      <FirmContextProvider>
        {
          isLoading
          ? <Loading />
          : error
            ? <div className="full-flex-content-center">An error has occurred: {error.message}</div>
            : firmId
              ? <WaitForFirm>
                  {children}
                </WaitForFirm>
              : needsSelect
                ? <ThemeContextProvider fallbackToBuiltInTheme>
                    <StandardLayout navbar={<SelectNavBar />}  footerDisclaimer={<div className="pt-2"></div>}> 
                      <SelectPage permissions={firmsPerms ?? []} />
                    </StandardLayout>
                  </ThemeContextProvider>
                : <></>
        }
      </FirmContextProvider>
    </AppContext.Provider>
  )

}

export default AppContextProvider


