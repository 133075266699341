import clsx from "clsx"
import React from "react"
import { Cause } from "../../../config/theme"

export const CauseDetail = ({ cause }: { cause: Cause }) => (
  <div className="flex items-start gap-x-2 text-sm mb-6">
    <img alt="" aria-hidden src={cause.persona?.icon} className="w-6" />
    <div className="w-full">
      <p className="font-semibold mb-0.5">{cause.title}</p>
      <p>{cause.description}</p>
      <div className="flex mt-2 gap-x-4 w-full">
        <div className="flex flex-col items-start w-full">
          <p className="mb-2">Includes companies that:</p>
          <ul className="list-disc ml-4">
            {cause.items?.map((item, i) => (
              <li key={i}>{item.title}</li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col w-full">
          <p className="pb-2">{cause.learn?.devGoals?.description}</p>
          <ul
            className={clsx("mb-2 flex flex-col gap-2", {
              "list-disc ml-4": !cause.learn?.devGoals?.items.some((item) => item.icon)
            })}
          >
            {cause.learn?.devGoals?.items.map((item, i) => (
              <li className={clsx({ "flex items-center p-0": !!item.icon })} key={i}>
                {item.icon && <img alt="" src={item.icon} className="h-5 w-5 mr-2" aria-hidden />}
                {item.description}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
)
