import React, { useContext, useMemo } from "react"
import { useQueries } from "react-query"
import { useSearchParams } from "react-router-dom"
import { computeGoalAchievability } from "../../../api/rm/goals"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import LoadingError from "../../../components/ClientProfile/LoadingError/LoadingError"
import { Reports } from "../../../config/theme"
import { FirmContext } from "../../../contexts/FirmContext"
import { useTheme } from "../../../contexts/ThemeContext"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import { latest } from "../../../lib/clients"
import { AuthContext } from "../../../views/auth/AuthContext"
import { createOptions, Options } from "../../rmjourney/goalExplorer/components/SelectPortfolio/SelectPortfolio"
import AltruismScores from "./AltruismScoresPage"
import AppendixPage1 from "./AppendixPage1"
import AppendixPage2 from "./AppendixPage2"
import AppendixPage3 from "./AppendixPage3"
import BusinessInvestment from "./BusinessInvestment"
import ComfortMatchPage from "./ComfortMatchPage"
import CoverPage from "./CoverPage"
import DecisionPatternPage from "./DecisionPattern/DecisionPatternPage"
import DisclaimerPage from "./DisclaimerPage"
import GoalPlanCoverPage from "./GoalPlanCoverPage"
import GoalSummary from "./GoalSummary"
import InvestingChampionPage from "./InvestingChampionPage"
import { InvestmentProjection } from "./InvestmentProjection"
import InvestorPersonality from "./InvestorPersonality"
import PFSIncomeExpensesPage, { incomeExpensesPageData } from "./PFSIncomeExpensesPage"
import PFSNetWorthPage, { assetsLiabilitiesPageData } from "./PFSNetWorthPage"
import { getPageRows } from "./PFSRowDetail"
import PersonalizedAdvice from "./PersonalizedAdvice"
import PortfolioAnalyserReportPage, { splitFundArrays } from "./PortfolioAnalyserReportPage"
import RiskChartsPage from "./RiskChartsPage/RiskChartsPage"
import RiskDimensionsPage from "./RiskDimensionsPage/RiskDimensionsPage"
import RiskProfilePage from "./RiskProfilePage/RiskProfilePage"
import SelectedPortfolio from "./SelectedPortfolio"
import SummaryPage from "./SummaryPage/SummaryPage"
import SustainabilityCommitmentPage from "./SustainabilityCommitmentPage/SustainabilityCommitmentPage"
import SustainabilityFocusPage from "./SustainabilityFocusPage/SustainabilityFocusPage"
import SustainabilityMetricsReportPage from "./SustainabilityMetricsReportPage"
import SustainabilityValuesPage from "./SustainabilityValuesPage/SustainabilityValuesPage"
import SustainableInvestingDisclaimerPage from "./SustainableInvestingDisclaimerPage"
import SustainableInvestingDisclaimerPage2 from "./SustainableInvestingDisclaimerPage2"
import SustainablePersonaCoverPage from "./SustainablePersonaCoverPage"
import TopThemes from "./TopThemes"
import ValuesAppendixPage1 from "./ValuesAppendixPage1/ValuesAppendixPage1"
import ValuesAppendixPage2 from "./ValuesAppendixPage2/ValuesAppendixPage2"
import "./assets/css/reports.css"
import pfsCoverImage from "./assets/pfs-cover.jpg"
import useClientFinances from "../../../hooks/useClientFinances"
import { Household } from "../../../models/Household"
import { Client, GoalDetail } from "../../../models/Client"
import { GoalPrioritiesOrder } from "../../advisor/Results/Goals/GoalsTab"
import SmartInvestmentPage from "./SmartInvestmentPage/SmartInvestmentPage"
import GoalProjectorPage from "./GoalProjectorPage"

const InvestmentMeetingReport = ({ postMeeting }: { postMeeting?: boolean }) => {
  const [par] = useSearchParams()
  const report = (par.get("report") ?? "risk") as Reports
  const clientId = par.get("clientId")
  const householdId = par.get("householdId")
  const goalExplorerCompleted = par.get("goalExplorerCompleted")
  const { client, isClientLoading, clientLoadError } = useClient(clientId!)
  const { household, isHouseholdLoading, householdLoadError } = useHousehold(householdId!)
  const clientOrHousehold = client || household
  const { sessionInfo } = useContext(AuthContext)
  const { firm } = useContext(FirmContext)
  const theme = useTheme()

  const goals = useMemo(
    () =>
      clientOrHousehold?.goals?.goalDetails && clientOrHousehold.goals.goalDetails.length > 0
        ? sortedGoalDetails(clientOrHousehold.goals.goalDetails)
        : undefined,
    [clientOrHousehold?.goals?.goalDetails]
  )

  const goalType = primaryInvestmentGoal(clientOrHousehold)

  const goalQueries = useQueries(
    goals?.map((goal) => ({
      queryKey: ["goals-outcome", client, household, goal.type, goal.id],
      queryFn: async () => {
        if (clientOrHousehold && goalExplorerCompleted && goals.length > 0) {
          const res = await computeGoalAchievability({
            goalType: goal?.type === "retirementIncome" && clientOrHousehold?.alreadyRetired ? "retirementDrawdown" : goal?.type,
            advisorId: clientOrHousehold.advisorId!,
            clientId: client?._id,
            householdId: household?._id
          })

          return createOptions({
            goalType: goal.type !== "retirementIncome" && goalType !== "retirementDrawdown" ? "wealthAccumulation" : goal.type,
            clientOrHousehold,
            portfolios: res.results,
            isRange: true,
            disPortfolios: res.disPortfolios,
            legacyPortfolios: res.legacyPortfolios,
            goal
          })
        }
      },
      enabled: !!clientOrHousehold && Boolean(sessionInfo),
      refetchOnWindowFocus: false,
      retry: false
    })) ?? []
  )

  const optionsLoadingError = goalQueries.find((q) => q.isError)?.error
  const isLoadingOptions = goalQueries.some((q) => q.isLoading)
  const goalOptions = goalQueries?.map(({ data }) => data) as Options[]
  const errorMessage = [
    clientLoadError ? (clientLoadError as any)?.response?.data ?? clientLoadError?.message ?? "Could not load client" : "",
    householdLoadError?.message,
    optionsLoadingError
  ]
    .filter((_) => _)
    .join("; ")

  const finances = useClientFinances(client ?? household)
  const hasNoCurrentPorfolioAndGoals =
    !clientOrHousehold?.currentPortfolio && (!clientOrHousehold?.goals || clientOrHousehold?.goals?.goalDetails?.length === 0)
  let pageNum = 1 // Cover page is 1 so next page will be 1++ = 2

  return errorMessage ? (
    <LoadingError message={errorMessage} />
  ) : isClientLoading || isHouseholdLoading || isLoadingOptions || !clientOrHousehold ? (
    <Loading />
  ) : (
    <main className={`investment-meeting-report bg-white ${report}-report`}>
      {theme.reports?.pages[report]?.map((page, i) => {
        if (page === "cover") {
          return (
            <CoverPage
              key={i}
              client={client!}
              household={household!}
              reportType={report}
              isPostMeeting={postMeeting}
              coverImage={theme.reports?.coverPage?.coverImage}
            />
          )
        } else if (page === "advice" && postMeeting) {
          return <PersonalizedAdvice key={i} page={++pageNum} client={client!} household={household!} />
        } else if (page === "summary") {
          return <SummaryPage key={i} page={++pageNum} client={client!} household={household!} />
        } else if (page === "riskProfile" && !hasNoCurrentPorfolioAndGoals) {
          return <RiskProfilePage key={i} page={++pageNum} client={client!} household={household!} isPostMeeting={postMeeting} />
        } else if (page === "riskDimensions") {
          return (
            <RiskDimensionsPage key={i} page={++pageNum} client={client!} household={household!} hasNoCurrentPorfolioAndGoals={hasNoCurrentPorfolioAndGoals} />
          )
        } else if (page === "decisionPattern") {
          return <DecisionPatternPage key={i} page={++pageNum} client={client!} household={household!} />
        } else if (page === "riskCharts") {
          return <RiskChartsPage key={i} page={++pageNum} client={client!} household={household!} />
        } else if (page === "comfortMatch") {
          return <ComfortMatchPage key={i} page={++pageNum} client={client!} household={household!} postMeeting={postMeeting} />
        } else if (page === "goalProjector" && postMeeting && goalQueries) {
          return <GoalProjectorPage key={i} page={++pageNum} client={client!} household={household!} goalOptions={goalOptions} />
        } else if (page === "sustainableCover") {
          return <SustainablePersonaCoverPage key={i} client={client!} />
        } else if (page === "sustainabilityFocus") {
          return <SustainabilityFocusPage key={i} page={++pageNum} client={client!} household={household!} />
        } else if (page === "sustainabilityValues") {
          return <SustainabilityValuesPage key={i} page={++pageNum} firm={firm!} client={client!} household={household!} />
        } else if (page === "sustainabilityCommitment") {
          return <SustainabilityCommitmentPage key={i} page={++pageNum} firm={firm!} client={client!} household={household!} />
        } else if (page === "goalCover") {
          return <GoalPlanCoverPage key={i} client={client!} />
        } else if (page === "goalSummary") {
          return <GoalSummary key={i} page={++pageNum} client={client!} />
        } else if (page === "investorPersonality") {
          return <InvestorPersonality key={i} page={++pageNum} client={client!} />
        } else if (page === "investmentProjection") {
          return <InvestmentProjection key={i} page={++pageNum} client={client!} />
        } else if (page === "selectedPortfolio") {
          return <SelectedPortfolio key={i} page={++pageNum} client={client!} />
        } else if (page === "valuesAppendix1") {
          return <ValuesAppendixPage1 key={i} page={++pageNum} firm={firm!} client={client!} household={household!} />
        } else if (page === "valuesAppendix2") {
          return <ValuesAppendixPage2 key={i} page={++pageNum} firm={firm!} client={client!} household={household!} />
        } else if (page === "topThemes") {
          return <TopThemes key={i} page={++pageNum} client={client!} />
        } else if (page === "investingChampion") {
          return <InvestingChampionPage key={i} page={++pageNum} client={client!} />
        } else if (page === "altruismScores" && latest(client!, "esg")?.esg.results?.S2) {
          return <AltruismScores key={i} page={++pageNum} client={client!} />
        } else if (page === "portfolioAnalyser" && client?.portfolio) {
          const page = pageNum + 1
          const splitLength = splitFundArrays((client?.portfolio?.proposed ?? client?.portfolio?.current)?.fundAllocations ?? [])
          pageNum = pageNum + splitLength.length
          return <PortfolioAnalyserReportPage key={i} page={page} client={client!} firm={firm} />
        } else if (page === "sustainabilityMetrics" && client?.portfolio) {
          return <SustainabilityMetricsReportPage key={i} page={++pageNum} client={client!} />
        } else if (page === "businessInvesment") {
          return <BusinessInvestment key={i} page={++pageNum} client={client!} />
        } else if (page === "appendix1") {
          return <AppendixPage1 key={i} page={++pageNum} />
        } else if (page === "appendix2") {
          return <AppendixPage2 key={i} page={++pageNum} />
        } else if (page === "appendix3") {
          return <AppendixPage3 key={i} page={++pageNum} firm={firm!} client={client!} household={household!} />
        } else if (page === "sustainableInvestingDisclaimer") {
          return <SustainableInvestingDisclaimerPage key={i} page={++pageNum} />
        } else if (page === "sustainableInvestingDisclaimer2") {
          return <SustainableInvestingDisclaimerPage2 key={i} page={++pageNum} />
        } else if (page === "smartInvestmentPage") {
          return <SmartInvestmentPage key={i} page={++pageNum} client={client!} household={household!} firm={firm!} />
        } else if (page === "disclaimer") {
          return (
            <>
              {theme.reports?.investmentMeeting?.disclaimer?.content?.map((disclaimer) => (
                <DisclaimerPage key={i} page={++pageNum} client={client!} household={household!} content={disclaimer} />
              ))}
            </>
          )
        } else if (page === "pfsCover") {
          return <CoverPage key={i} client={client!} household={household!} reportType={report} isPostMeeting={postMeeting} coverImage={pfsCoverImage} />
        } else if (page === "pfsNetWorth") {
          const { totalPages, data } = assetsLiabilitiesPageData(clientOrHousehold, finances.raw.assets, finances.raw.liabilities)
          return (
            <React.Fragment key={i}>
              {[...Array(totalPages)].map((_, i) => {
                const currentPageRows = getPageRows(data, i + 1, totalPages)
                return <PFSNetWorthPage key={`pfs-net-worth-${i}`} client={client} household={household} page={++pageNum} currentPageRows={currentPageRows} />
              })}
            </React.Fragment>
          )
        } else if (page === "pfsIncomeExpenses") {
          const { totalPages, data } = incomeExpensesPageData(clientOrHousehold, finances.raw.incomeSources, finances.raw.expenses)
          return (
            <>
              {[...Array(totalPages)].map((_, i) => {
                const currentPageRows = getPageRows(data, i + 1, totalPages)
                return (
                  <PFSIncomeExpensesPage
                    key={`pfs-income-expense-${i}`}
                    client={client}
                    household={household}
                    page={++pageNum}
                    currentPageRows={currentPageRows}
                  />
                )
              })}
            </>
          )
        } else {
          return null
        }
      })}
      <div id="pdf-rendered-indicator" className="hidden" />
    </main>
  )
}

export const sortedGoalDetails = (goalDetails: GoalDetail[]) => {
  return [...goalDetails].sort((a, b) => {
    const priorityA = a.priority ? GoalPrioritiesOrder.indexOf(a.priority) : Infinity
    const priorityB = b.priority ? GoalPrioritiesOrder.indexOf(b.priority) : Infinity
    return priorityA - priorityB
  })
}

export const primaryInvestmentGoal = (clientOrHousehold?: Client | Household) => {
  if (clientOrHousehold?.goals?.goalDetails && clientOrHousehold?.goals?.goalDetails.length > 0) {
    const firstGoal = sortedGoalDetails(clientOrHousehold.goals.goalDetails)[0]
    return firstGoal?.type === "retirementIncome" && clientOrHousehold?.alreadyRetired ? "retirementDrawdown" : firstGoal?.type
  } else {
    return clientOrHousehold?.primaryInvestmentGoal
  }
}

export default InvestmentMeetingReport
