import { useEffect, useState } from "react"
import logo from "./assets/cp-logo-white.svg"
import riskHero from "./assets/risk-hero.jpg"
import valuesHero from "./assets/values-hero.jpg"
import menuIcon from "./assets/menu.svg"
import closeIcon from "./assets/close.svg"
import { AnimatePresence, motion } from "framer-motion"

const LeadGenDemoPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () => window.innerWidth > 640 && setIsMenuOpen(false)
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <div className="w-full min-h-screen bg-data-teal-50">
      <div className="relative max-w-screen-xl mx-auto">
        <nav className="flex justify-between items-center gap-2 h-12 sm:h-17 px-4 sm:px-10 py-2 sm:py-6">
          <img className="h-3.5 sm:h-5" src={logo} alt="Capital Preferences" />
          <div className="hidden sm:flex flex-col sm:flex-row items-end sm:gap-5 text-sec font-bold text-white">
            <a href="#">Our solutions</a>
            <a href="#">About us</a>
            <a href="#">Login</a>
          </div>
          <button className="block sm:hidden h-fit p-1.5" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <img src={closeIcon} alt="Close menu" /> : <img src={menuIcon} alt="Open menu" />}
          </button>
        </nav>
        <div className="flex flex-col gap-10 sm:gap-13 pt-10 sm:pt-23 max-w-[850px] mx-auto px-4">
          <h1 className="text-h1 w-full text-contrast-100 text-center font-serif">We'd like to get to know you</h1>
          <div className="flex flex-col sm:flex-row gap-5 sm:gap-10 justify-center mb-14">
            <div className="w-full sm:w-[328px] bg-white">
              <img className="w-full max-h-42 object-cover object-bottom" src={riskHero} alt="" aria-hidden />
              <div className="px-6 sm:px-10 pt-6 pb-8 text-center text-black/100">
                <div className="h-full flex flex-col justify-between sm:h-[202px]">
                  <div className="flex flex-col gap-4 pb-4">
                    <h2 className="text-h3 leading-6 text-black/100 font-serif">What's your risk profile?</h2>
                    <p className="font-semibold text-sec leading-5">We can help you align your investments to your risk profile.</p>
                    <p className="text-sm text-main-400">This activity will take less than 5 mins.</p>
                  </div>
                  <a
                    href={import.meta.env.VITE_APP_DEMO_LEAD_GEN_BASE_URL_RISK || ("https://" + window.location.hostname.replaceAll(/^[^.]+\./g, "risk."))}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary btn-medium w-full bg-[#75E9AA] border-[#75E9AA] text-[#321E46] hover:bg-white active:bg-white hover:border-white"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-[328px] bg-white">
              <img className="w-full max-h-42 object-cover object-bottom" src={valuesHero} alt="" aria-hidden />
              <div className="px-6 sm:px-10 pt-6 pb-8 text-center text-black/100">
                <div className="h-full flex flex-col justify-between">
                  <div className="flex flex-col gap-4 pb-4">
                    <h2 className="text-h3 leading-6 text-black/100 font-serif">Are your investments aligned to your values?</h2>
                    <p className="font-semibold text-sec leading-5">We can help you align your investments to your unique values.</p>
                    <p className="text-sm text-main-400">This activity will take less than 5 mins.</p>
                  </div>
                  <a
                    href={import.meta.env.VITE_APP_DEMO_LEAD_GEN_BASE_URL_INVESTMENTPREFERENCES || ("https://" + window.location.hostname.replaceAll(/^[^.]+\./g, "investmentpreferences."))}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary btn-medium w-full bg-[#75E9AA] border-[#75E9AA] text-[#321E46] hover:bg-white active:bg-white hover:border-white"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="fixed top-12 sm:top-17 right-0 w-full h-full z-10 bg-data-teal-50"
            >
              <div className="font-bold text-sec leading-5 text-white flex flex-col px-4 pt-5">
                <a href="#" className="w-full py-5 border-b border-[#4D677D]">
                  Our solutions
                </a>
                <a href="#" className="w-full py-5 border-b border-[#4D677D]">
                  About us
                </a>
                <a href="#" className="w-full py-5 border-b border-[#4D677D]">
                  Login
                </a>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default LeadGenDemoPage
