import { AxiosError } from "axios"
import { format, intervalToDuration } from "date-fns"
import { useCallback, useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import { ClientUpdateRequest } from "../../../../api/clients"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import DatePicker from "../../../../components/DatePicker/DatePicker"
import ErrorMessage from "../../../../components/Error/ErrorMessage"
import Modal from "../../../../components/Modal/Modal"
import RetirementAgeInput from "../../../../components/RetirementAgeInput/RetirementAgeInput"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { tt } from "../../../../lib/translations"
import { Client, GoalDetail } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { validateTargetDate } from "../../goalExplorer/wealthAccumulation/WealthAccumulationUtils"

const EditInvestmentTimeframeModal = ({
  goal,
  retirementClient,
  client,
  household,
  onClose,
  onUpdateDone
}: {
  goal: GoalDetail
  client?: Client
  household?: Household
  retirementClient?: Client
  onClose: () => void
  onUpdateDone: (goalDetail?: GoalDetail) => void
}) => {
  const clientOrHousehold = client ?? household
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [retirementAge, setRetirementAge] = useState<{ years?: number | undefined; months?: number | undefined }>()
  const [retirementDate, setRetirementDate] = useState<Date | null>()
  const [alreadyRetired, setAlreadyRetired] = useState<boolean | undefined>(false)
  const [shouldValidate, setShouldValidate] = useState(false)
  const [inputType, setInputType] = useState<"datePicker" | "ageInput">("datePicker")
  const [errors, setErrors] = useState<{ targetDate?: string; retirementAgeYears?: string; retirementAgeMonths?: string }>()
  const [targetDate, setTargetDate] = useState<Date | null>()
  const [targetDateRaw, setTargetDateRaw] = useState<string | null>()

  useEffect(() => {
    setAlreadyRetired(retirementClient?.alreadyRetired)
  }, [retirementClient?.alreadyRetired])

  useEffect(() => {
    const duration =
      retirementClient?.dob && retirementClient.retirementDate
        ? intervalToDuration({ start: new Date(retirementClient.dob), end: new Date(retirementClient.retirementDate) })
        : undefined
    if (duration) {
      setRetirementAge({ years: duration ? duration.years : retirementClient?.retirementAge, months: duration ? duration.months : 0 })
      setRetirementDate(retirementClient?.retirementDate ? new Date(retirementClient?.retirementDate) : undefined)
    }
  }, [retirementClient?.dob, retirementClient?.retirementAge, retirementClient?.retirementDate])

  const { mutate: update, status } = useMutation<Client | Household | null, AxiosError<{ message?: string }>>({
    mutationFn: () => {
      const updateRequest: ClientUpdateRequest = {}
      if (goal.type === "retirementIncome" && alreadyRetired) {
        updateRequest.alreadyRetired = true
        updateRequest.retirementDate = null
        return updateClient(retirementClient!._id, updateRequest)
      } else if (goal.type === "retirementIncome" && !alreadyRetired && retirementDate) {
        updateRequest.alreadyRetired = false
        updateRequest.retirementDate = format(retirementDate, "yyyy-MM-dd")
        return updateClient(retirementClient!._id, updateRequest)
      } else {
        const updatedGoals = clientOrHousehold?.goals?.goalDetails?.map((goalDetail) => {
          if (goalDetail.type === goal?.type && goalDetail.id === goal.id) {
            return {
              ...goalDetail,
              targetDate: {
                value: format(targetDate!, "yyyy-MM-dd")
              }
            }
          } else {
            return goalDetail
          }
        })
        updateRequest.goals = { goalDetails: updatedGoals }
        return household ? updateHousehold(household._id, [], updateRequest) : updateClient(client!._id, updateRequest)
      }
    },
    onSuccess: (updated: Client | Household | null) => {
      onUpdateDone(updated?.goals?.goalDetails?.find((g) => g.type === goal.type && g.id === goal.id))
      onClose()
    }
  })

  const validate = useCallback(() => {
    const errors: { targetDate?: string; retirementAgeYears?: string; retirementAgeMonths?: string } = {}
    if (goal.type !== "retirementIncome") {
      errors.targetDate = validateTargetDate(targetDate)
    } else if (goal.type === "retirementIncome" && !alreadyRetired && retirementClient?.dob) {
      const retirementDuration = retirementDate ? intervalToDuration({ start: new Date(retirementClient.dob), end: retirementDate! }) : null
      const retirementAgeYears = inputType === "ageInput" ? retirementAge?.years : retirementDuration?.years
      const retirementAgeMonths = inputType === "ageInput" ? retirementAge?.months : retirementDuration?.months
      if (!retirementAgeYears || retirementAgeYears < 30 || retirementAgeYears > 90) {
        if (inputType === "ageInput") {
          errors.retirementAgeYears = "Age must be between 30-90 yrs"
        } else {
          errors.targetDate = "Retirement date must be between 30-90 yrs"
        }
      }
      if (inputType === "ageInput" && (retirementAgeMonths === undefined || retirementAgeMonths > 11)) {
        errors.retirementAgeMonths = "Please add a month between 0-11"
      }
    }
    setErrors(errors)
    return { isValid: Object.values(errors).filter((v) => !!v).length === 0 }
  }, [alreadyRetired, goal.type, inputType, retirementAge?.months, retirementAge?.years, retirementClient?.dob, retirementDate, targetDate])

  const onUpdate = () => {
    setShouldValidate(true)
    const { isValid } = validate()
    if (isValid) {
      update()
    }
  }

  useEffect(() => {
    if (shouldValidate) {
      validate()
    }
  }, [shouldValidate, validate])

  useEffect(() => {
    setTargetDate(goal.targetDate?.value ? new Date(goal.targetDate.value) : null)
  }, [goal.targetDate?.value])

  console.log({ retirementAge, retirementDate, errors, shouldValidate })

  return (
    <Modal handleClose={() => onClose()} className="max-w-xl w-full">
      <div className="w-full">
        <h2 className="text-h2 font-semibold pb-6">Edit investment timeframe</h2>
        {goal.type === "retirementIncome" ? (
          <RetirementAgeInput
            client={retirementClient!}
            alreadyRetired={alreadyRetired}
            retirementAge={retirementAge}
            retirementDate={retirementDate}
            inputType={inputType}
            onChange={(data) => {
              setRetirementAge(data.retirementAge)
              setRetirementDate(data.retirementDate)
            }}
            onToggleChange={setInputType}
            errors={errors}
          />
        ) : (
          <DatePicker
            id="target-date"
            label={
              <span className="font-semibold">{goal ? tt({ id: `goal-projector-form-${goal?.type}-target-date-label` }) : "Expected withdrawal year"}</span>
            }
            value={targetDate || null}
            onChange={setTargetDate}
            onChangeRaw={setTargetDateRaw}
            yearsInAdvance={75}
            error={shouldValidate && errors?.targetDate ? errors.targetDate : undefined}
          />
        )}
        <div role="alert">{status === "error" && <ErrorMessage id="edit-investment-timeframe" message="Error updating the value" />}</div>
        <div className="flex justify-center gap-5 mt-6">
          <button type="button" onClick={() => onClose()} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button className="btn btn-primary btn-medium w-44" disabled={status === "loading"} onClick={() => onUpdate()}>
            {status === "loading" ? <Loading type="dots" /> : "Update"}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default EditInvestmentTimeframeModal
