import addYears from "date-fns/addYears"
import { Client, GoalDetail, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { validateAnnualContribution, validateInvestmentAmount } from "../utils/validation"
import { ClientUpdateRequest } from "../../../../api/clients"
import { HouseholdUpdateRequest } from "../../../../api/households"
import { format } from "date-fns"
import { DATE_FORMAT } from "../../../../lib/date"

export interface GoalFormErrors {
  investmentContribution?: string
  investmentAmount?: string
  targetAmount?: string
  targetDate?: string
}

export const validateWealthAccumulationForm = ({ values }: { values: GoalFormValues }): { errors: GoalFormErrors; isValid: boolean } => {
  let isValid = true
  const { investmentContribution, investmentAmount, targetAmount, targetDate } = values
  const errors: GoalFormErrors = {
    investmentContribution: undefined,
    investmentAmount: undefined,
    targetAmount: undefined,
    targetDate: undefined
  }

  errors.investmentAmount = validateInvestmentAmount(investmentAmount)
  errors.investmentContribution = validateAnnualContribution(investmentContribution)

  if (targetAmount !== null && targetAmount !== undefined) {
    if (targetAmount < 0 || targetAmount > 1000000000000) {
      errors.targetAmount = "Value must be less than $1T"
    }
  } else {
    errors.targetAmount = "Target amount required"
  }

  errors.targetDate = validateTargetDate(targetDate)

  isValid = Object.values(errors).every((x) => x === null || x === undefined || x === "")

  return {
    errors,
    isValid
  }
}

export const validateTargetDate = (targetDate?: Date | null) => {
  if (targetDate) {
    const minDate = addYears(new Date(), 1)
    const maxDate = addYears(new Date(), 75)
    if (targetDate < minDate || targetDate > maxDate) {
      return `Please select a date between ${format(minDate, DATE_FORMAT)} and ${format(maxDate, DATE_FORMAT)}`
    }
  } else {
    return "Target date is required"
  }
}

export const createFormValues = ({ client, household, goal }: { client?: Client; household?: Household; goal?: GoalDetail }): GoalFormValues => {
  const clientOrHousehold = client ?? household
  return {
    investmentContribution: goal ? goal.contributions?.value : clientOrHousehold?.annualInvestmentContribution,
    investmentContributionFrequency: goal ? goal?.contributions?.frequency : "monthly",
    investmentAmount: goal ? goal?.investmentAmount : clientOrHousehold?.investmentAmount,
    targetAmount: goal ? goal?.targetAmount?.value : clientOrHousehold?.wealthAccumulationGoal,
    targetDate: goal?.targetDate?.value
      ? new Date(goal?.targetDate?.value)
      : clientOrHousehold?.wealthAccumulationYear
      ? new Date(clientOrHousehold.wealthAccumulationYear, 0, 1)
      : undefined
  }
}

export interface GoalFormValues {
  investmentContribution?: number
  investmentContributionFrequency?: "weekly" | "fortnightly" | "monthly" | "quarterly" | "annually"
  investmentAmount?: number
  targetAmount?: number
  targetDate?: Date | null
  targetDateRaw?: string | null
}

export const createWealthAccumulationRequest = ({
  client,
  values,
  goalType,
  household,
  goal
}: {
  client?: Client
  values: GoalFormValues
  goalType: InvestmentGoal
  household?: Household
  goal?: GoalDetail
}) => {
  const { investmentContribution, investmentContributionFrequency, investmentAmount, targetAmount, targetDate } = values

  const updateRequest: ClientUpdateRequest | HouseholdUpdateRequest = {}
  const clientOrHousehold = client ?? household

  if (goal) {
    const goals = {
      ...clientOrHousehold?.goals,
      goalDetails: clientOrHousehold?.goals?.goalDetails?.map((goalDetail) => {
        if (goalDetail.type === goal.type && goalDetail.id === goal.id) {
          return {
            ...goalDetail,
            targetAmount: {
              value: targetAmount
            },
            investmentAmount,
            contributions: {
              value: investmentContribution,
              frequency: investmentContributionFrequency ?? "monthly"
            },
            targetDate: {
              value: targetDate ? format(targetDate, "yyyy-MM-dd") : null
            }
          }
        } else {
          return goalDetail
        }
      })
    }
    updateRequest.goals = goals
  } else if (clientOrHousehold) {
    if (investmentContribution !== clientOrHousehold.annualInvestmentContribution) {
      updateRequest.annualInvestmentContribution = investmentContribution
    }

    if (investmentAmount !== clientOrHousehold.investmentAmount) {
      updateRequest.investmentAmount = investmentAmount
    }

    if (targetAmount !== clientOrHousehold.wealthAccumulationGoal) {
      updateRequest.wealthAccumulationGoal = targetAmount
    }

    const nextWealthAccumulationYear = targetDate ? targetDate.getFullYear() : undefined

    if (nextWealthAccumulationYear !== clientOrHousehold.wealthAccumulationYear) {
      updateRequest.wealthAccumulationYear = nextWealthAccumulationYear
    }

    if (goalType !== clientOrHousehold.primaryInvestmentGoal) {
      updateRequest.primaryInvestmentGoal = goalType
    }
  }

  return updateRequest
}
