import { useMemo } from "react"
import { latest } from "../../../../lib/clients"
import { customDateFormat } from "../../../../lib/date"
import { Client, Game } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import useRetirementRiskMindset from "../../../../hooks/useRetirementRiskMindset"

import retirementGraphTmp from "./assets/retirement-graph.svg"
import certaintyPiechart from "./assets/recommendation-certainty.svg"
import growthPiechart from "./assets/recommendation-growth.svg"

import RetirementIcon from "./assets/RetirementIcon"

import ScoreChart from "../components/ScoreChart/ScoreChart"
import clsx from "clsx"
import { tt } from "../../../../lib/translations"

const RetirementIncomeTab = ({ client, household, isEmbedded }: { client?: Client; household?: Household; isEmbedded?: boolean }) => {
  const clientOrHousehold = (client ?? household)!
  const game = latest(clientOrHousehold, "retirement")
  const latestHouseholdGames = useMemo(() => {
    return household?.members.map((member) => latest(member.client!, "retirement")) ?? []
  }, [household?.members])

  const lastUpdatedGame = household
    ? latestHouseholdGames.reduce(
        (latest, game) => (new Date(game?.played ?? 0) > new Date(latest?.played ?? 0) ? game : latest),
        null as (typeof latestHouseholdGames)[number] | null
      )
    : game
  const lastUpdatedDate = lastUpdatedGame?.played
  const completedMembers = household && household.members.filter((_, index) => latestHouseholdGames[index]?.played)

  const retirementGames = household && completedMembers
    ? completedMembers?.length === 1
      ? latest(completedMembers[0].client, "retirement")
      : completedMembers?.length === household.members.length
      ? latestHouseholdGames.filter((game): game is Game => !!game) // Filter out undefined values
      : undefined
    : game // Not a household, use client game

  const retirementRiskMindset = useRetirementRiskMindset(retirementGames)

  const title = useMemo(() => {
    const name = completedMembers && completedMembers?.length === household.members.length ? "Household" : completedMembers?.length === 1 ? completedMembers[0].client.firstName : client?.firstName
    return `${completedMembers && completedMembers?.length === household.members.length ? name : name + "'s"} profile completed on ${customDateFormat(lastUpdatedDate!)}`
  }, [completedMembers, household?.members.length, client?.firstName, lastUpdatedDate])

  const names = household ? completedMembers?.map((m) => m.client.firstName).join(" and ") : client?.firstName
  const isPlural = completedMembers && completedMembers?.length === household.members.length

  return (
    <div className={clsx("retirement-tab w-full flex flex-col items-start gap-10 py-10", { "px-16": !isEmbedded })}>
      <h1 className="sr-only">Retirement income</h1>
      <section className="flex justify-between items-center gap-2 w-full text-left">
        <p>{title}</p>
      </section>
      <section className="w-full flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <h2 className="text-emph uppercase">Retirement persona</h2>
          <p className="text-h3 font-semibold">
            {tt({ id: `retirement-persona-title-${retirementRiskMindset?.rating}-${isPlural ? "plural" : "individual"}`, values: { names } })}
          </p>
        </div>
        <p>
          {tt({ id: `retirement-persona-description-${retirementRiskMindset?.rating}-${isPlural ? "plural" : "individual"}`, values: { names } })}
        </p>
      </section>
      <hr className="w-full border-surface-300" />
      <section className="w-full flex flex-col gap-6">
        <div className="w-full flex items-center justify-between gap-6">
          <div className="flex flex-col gap-1">
            <h2 className="text-emph uppercase">Certainty score</h2>
            <p className="text-h3 font-semibold">
              {tt({ id: `retirement-certainty-title-${retirementRiskMindset?.rating}-${isPlural ? "plural" : "individual"}`, values: { names } })}
            </p>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-h2 font-semibold text-main-400">{retirementRiskMindset?.score}</span>
            <span className="text-sm">Certainty score</span>
          </div>
        </div>
        {Number.isFinite(retirementRiskMindset?.score) && (
          <div className="risk-attitude-line-chart w-full self-center !h-80">
            <ScoreChart
              client={household ? completedMembers?.[0].client : client}
              score={retirementRiskMindset!.score}
              title={`${names}'s overall preference for retirement income certainty`}
              color="#8B85C6"
              icon={<RetirementIcon />}
            />
            <div className="flex justify-between items-center text-sec font-semi text-main-400">
              <span>More opportunity</span>
              <span>More certainty</span>
            </div>
          </div>
        )}
      </section>
      <hr className="w-full border-surface-300" />
      <section className="flex flex-col gap-6 items-start">
        <h2 className="text-h3 font-semibold">Guaranteed income recommendation</h2>
        <p>
          We recommend allocating ${retirementRiskMindset?.rating === "low" ? "300,000" : "150,000"} of {names}'s portfolio to our guaranteed income product
          that will create a lifetime income stream of ${retirementRiskMindset?.rating === "low" ? "24,000" : "12,000"}/yr. This will leave {names} with $
          {retirementRiskMindset?.rating === "low" ? "560,000" : "710,000"} in their portfolio to focus on growth and income.
        </p>
        <img className="h-14" src={retirementRiskMindset?.rating === "low" ? certaintyPiechart : growthPiechart} alt="" aria-hidden />
      </section>
      <hr className="w-full border-surface-300" />
      <section className="w-full flex flex-col gap-6 items-center">
        <h2 className="w-full text-h3 font-semibold text-left">Projected income in retirement</h2>
        <img src={retirementGraphTmp} alt="" aria-hidden />
      </section>
    </div>
  )
}

export default RetirementIncomeTab
