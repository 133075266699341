import { Route, Routes } from "react-router-dom"
import AppWithAuth from "../AppWithAuth"
import StandardLayout from "../layout/StandardLayout"
import PortalNavBar from "../layout/navbars/PortalNavBar"
import ThemeContextProvider from "../contexts/ThemeContext"
import AppContextProvider from "../contexts/AppContext"
import FirmsListPage from "../pages/privateMarkets/FirmsListPage"
import FirmAdminPage from "../pages/firm/FirmAdminPage"
import AssetClassesPage from "../pages/firm/AssetClassesPage"
import ModelPortfoliosPage from "../pages/firm/ModelPortfoliosPage"
import AdminRoutes from "../pages/admin/AdminRoutes"

const PrivateMarketsApp = () => (
  <AppWithAuth>
    <AppContextProvider>
      <ThemeContextProvider fallbackToBuiltInTheme={false}>
        {/* theme needs firm loaded */}
        <StandardLayout
          navbar={
            <Routes>
              <Route path="/*" element={<PortalNavBar />} />
            </Routes>
          }
        >
          <Routes>
            <Route path="/firms" element={<FirmsListPage />} />
            <Route path="admin/*" element={<AdminRoutes />} />
            <Route path="*" element={<FirmsListPage />} />
          </Routes>
        </StandardLayout>
      </ThemeContextProvider>
    </AppContextProvider>
  </AppWithAuth>
)

export default PrivateMarketsApp
