import { useMemo } from "react"
import { Client } from "../../../models/Client"
import { i18n } from "@lingui/core"
import { Plural, t, Trans } from "@lingui/macro"
import GoalProjectionChart from "./components/GoalProjection/GoalProjectionChart"
import { periodToAnnual, shortRound } from "../../../lib/numbers"
import Page from "./Page"
import { tt } from "../../../lib/translations"
import { findRecommendedPortfolio } from "./SelectedPortfolio"
import { latest } from "../../../lib/clients"

export const InvestmentProjection = ({ page, client }: { page: number; client: Client }) => {
  const game = latest(client, "risk")
  const goalProjection = useMemo(
    () => client.aux?.goalProjection ?? game?.risk?.goalProjection,
    [game?.risk?.goalProjection, client.aux?.goalProjection]
  )

  const maxRange = shortRound(goalProjection?.currentDetails.highPortfolioProjection ?? 0)
  const minRange = shortRound(goalProjection?.currentDetails.lowPortfolioProjection ?? 0)

  const portfolioMappings = game?.portfolioMappings
  const goalTargetYear = client.goalTargetYear ?? new Date().getFullYear() + 10

  const recommendedPortfolio = findRecommendedPortfolio(client)

  const contributionAmount = shortRound(Number(client?.aux?.investmentContributionAmount))

  const projectedTotal = useMemo(() => {
    const investmentAmount = client.investmentAmount
    const otherSavings = goalProjection?.currentDetails.otherSavings ?? 0
    const portfolioProjection = goalProjection?.currentDetails.portfolioProjection ?? 0
    const savingsSchemeBalanceProjected = Number(client?.aux?.savingsSchemeBalanceProjected ?? 0)
    const totalSaving =
      client.primaryInvestmentGoal === "retirementIncome"
        ? goalProjection?.currentDetails?.currentExpectedValue ?? 0
        : portfolioProjection + otherSavings + savingsSchemeBalanceProjected
    return {
      investmentAmount: shortRound(investmentAmount),
      otherSavings: shortRound(otherSavings!),
      portfolioProjection: shortRound(portfolioProjection!),
      savingsSchemeBalanceProjected: shortRound(savingsSchemeBalanceProjected),
      total: shortRound(totalSaving)
    }
  }, [
    client.investmentAmount,
    client?.aux?.savingsSchemeBalanceProjected,
    client.primaryInvestmentGoal,
    goalProjection?.currentDetails.otherSavings,
    goalProjection?.currentDetails.portfolioProjection,
    goalProjection?.currentDetails?.currentExpectedValue
  ])

  const otherIncome = useMemo(
    () =>
      client?.aux?.otherIncome && client?.aux?.otherIncomeFrequency ? client?.aux?.otherIncome * periodToAnnual[client?.aux?.otherIncomeFrequency] : undefined,
    [client?.aux?.otherIncome, client?.aux?.otherIncomeFrequency]
  )

  const currentPortfolio = useMemo(
    () => game?.portfolioMappings?.find(({ portfolio }) => portfolio.id === client.currentPortfolio),
    [game?.portfolioMappings, client.currentPortfolio]
  )
  const selectedPortfolio = currentPortfolio ?? recommendedPortfolio
  const selectedPortfolioRiskLevel = useMemo(
    () =>
      [...(portfolioMappings ?? [])]
        .sort((a, b) => a.portfolio.sd! - b.portfolio.sd!)
        .findIndex(({ portfolio }) => portfolio.id === selectedPortfolio?.portfolio.id),
    [portfolioMappings, selectedPortfolio?.portfolio.id]
  )

  const shouldHideWealthGoalDetails = client.primaryInvestmentGoal === "wealthAccumulation" && !client.goalAmount

  return (
    <Page className="report-investment-projection" page={page} title={tt({ id: "report-header-title" })}>
      <div className="mx-9">
        <h2 className="investment-projection-title font-semibold text-h2 mt-15 text-main-600">
          <Trans id="report-investment-projection-title">Your investment projection</Trans>
        </h2>
        <div className="w-5/6">
          {goalProjection && (
            <GoalProjectionChart
              forReport={true}
              goalTargetYear={goalTargetYear}
              graphData={goalProjection.graph}
              goalAmount={goalProjection?.graph?.goal}
              portfolioProjection={goalProjection?.currentDetails?.portfolioProjection}
              shouldHideWealthGoalDetails={shouldHideWealthGoalDetails}
            />
          )}
          <div className="report-goal-projection-chart-legends flex justify-center gap-2 mt-4">
            <div className="flex items-center gap-1">
              <div className="w-[5px] h-[5px] bg-interactive-500 rounded-full" />
              <p className="text-sec leading-4 font-medium">
                <Trans id={`report-goal-projection-portfolio-title-${selectedPortfolioRiskLevel + 1}`}>portfolio</Trans>
              </p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-[5px] h-[5px] bg-interactive-200 rounded-full" />
              <p className="text-sec leading-4 font-medium">
                <Trans id="report-goal-projection-chart-legend-projected-range">
                  Projected range ${minRange.value + minRange.unit} - ${maxRange.value + maxRange.unit}
                </Trans>
              </p>
            </div>
          </div>
        </div>

        <p className="text-sm text-main-500 font-normal my-6 note">
          <Trans id="report-goal-projection-chart-note-text">
            <span className="font-semibold">Note:</span> Projections assume you stay invested for {goalTargetYear - new Date().getFullYear()} full years. Chart does
            not show withdrawals. View <a className="text-grey-300 visited:text-grey-300 underline" href={t({ id: "disclaimers-link" })} target="_blank" rel="noreferrer">Goal plan assumptions and disclaimers.</a>
          </Trans>
        </p>
        <div className="w-4/5 flex flex-col gap-6 pt-6">
          <h2 className="investment-projection-plan-details-title text-lg text-main-600 font-semibold">
            <Trans id="report-plan-summary-heading">Your plan details</Trans>
          </h2>
          <div className="text-sm font-400">
            <h3 className="font-normal text-main-600">
              <Trans id="report-plan-summary-goal-projection-title">Goal projection</Trans>
            </h3>
            <p className="flex justify-between">
              <span className="text-main-500">
                <Trans id={`report-goal-projection-portfolio-title-${selectedPortfolioRiskLevel + 1}`}>Projection in a portfolio</Trans>
              </span>
              <span className="text-main-600 font-semibold text-right lowercase">
                ${projectedTotal.portfolioProjection.value + (projectedTotal.portfolioProjection.unit ?? "")}
              </span>
            </p>
            {projectedTotal.savingsSchemeBalanceProjected.value !== 0 && (
              <p className="flex justify-between">
                <span className="text-main-500">
                  {
                    /* lingui-extract-ignore */ i18n._({
                      id: `report-goal-projection-${client?.primaryInvestmentGoal}-savings-scheme-title`,
                      values: { 0: Math.max(client?.retirementAge ?? 65, 65) }
                    })
                  }
                </span>
                <span className="text-main-600 font-semibold text-right lowercase">
                  ${projectedTotal.savingsSchemeBalanceProjected.value + (projectedTotal.savingsSchemeBalanceProjected.unit ?? "")}
                </span>
              </p>
            )}
            {!shouldHideWealthGoalDetails && (
              <>
                {!!projectedTotal?.otherSavings.value && (
                  <p className="flex justify-between">
                    <span className="text-main-500">
                      <Trans id="report-plan-summary-other-savings">Other savings</Trans>
                    </span>
                    <span className="text-main-600 font-semibold text-right lowercase">
                      ${projectedTotal?.otherSavings.value + (projectedTotal?.otherSavings.unit ?? "")}
                    </span>
                  </p>
                )}
                {otherIncome && (
                  <p className="flex justify-between text-main-500">
                    <Trans id="report-plan-summary-other-income">Other income</Trans>
                    <span className="text-main-600 font-semibold text-right lowercase">
                      {
                        /* lingui-extract-ignore */ i18n._({
                          id: "report-plan-summary-other-income-total",
                          values: { 0: shortRound(otherIncome).value + shortRound(otherIncome).unit }
                        })
                      }
                    </span>
                  </p>
                )}
                <hr className="border-t border-surface-200 my-3" />
                <p className="flex justify-between text-main-600 font-semibold">
                  <span>
                    {
                      /* lingui-extract-ignore */ i18n._({
                        id: `report-plan-summary-${client.primaryInvestmentGoal?.toLowerCase()}-total-projection`,
                        values: { retirementAge: client.retirementAge, goalTargetYear }
                      })
                    }
                  </span>
                  <span className="text-main-600 font-semibold text-right lowercase">
                    {
                      /* lingui-extract-ignore */ i18n._({
                        id: `report-plan-summary-${client.primaryInvestmentGoal?.toLowerCase()}-total-projection-value`,
                        values: { 0: projectedTotal.total.value + projectedTotal.total.unit }
                      })
                    }
                  </span>
                </p>
                {client.primaryInvestmentGoal === "retirementIncome" && !!client.retirementAge && (
                  <p className="text-main-500 text-xs font-medium">
                    {tt({
                      id: `report-plan-summary-retirementIncome-projected-total-relationship-${client.aux?.hasRelationship ? "yes" : "no"}-${
                        client?.retirementAge < 65 ? "min-" : ""
                      }subtext`
                    })}
                  </p>
                )}
              </>
            )}
          </div>
          <div className="text-sm font-400">
            <h3 className="font-normal">
              <Trans id="report-plan-summary-investment-details-title">Investment details</Trans>
            </h3>
            <p className="flex justify-between">
              <span className="text-main-500">
                <Trans id="report-plan-summary-initial-investment">Initial investment</Trans>
              </span>
              <span className="text-main-600 font-semibold text-right lowercase">
                ${projectedTotal.investmentAmount.value + (projectedTotal?.investmentAmount.unit ?? "")}
              </span>
            </p>
            <p className="flex justify-between">
              <span className="text-main-500">
                <Trans id={`report-plan-summary-${client?.aux?.investmentContributionFrequency}-contribution`}>Monthly contribution</Trans>
              </span>
              <span className="text-main-600 font-semibold text-right pl-1 lowercase">${contributionAmount.value + (contributionAmount.unit ?? "")}</span>
            </p>
            {!shouldHideWealthGoalDetails && (
              <p className="flex justify-between">
                <span className="text-main-500">
                  <Trans id="report-plan-summary-investment-term">Investment term</Trans>
                </span>
                <span className="text-main-600 font-semibold text-right pl-1 lowercase">
                  {goalTargetYear - new Date().getFullYear() > 0 ? goalTargetYear - new Date().getFullYear() : "< 1"}{" "}
                  <Trans id="report-plan-summary-goal-year-postfix">yrs</Trans>
                </span>
              </p>
            )}
          </div>
          <div className="text-sm font-400">
            <h3>
              <Trans id="report-plan-summary-portfolio-details-title">Portfolio details</Trans>
            </h3>
            <p className="flex justify-between">
              <span className="text-main-500">
                <Trans id="report-plan-summary-projected-return">Projected long term return</Trans>
              </span>
              <span className="text-main-600 font-semibold text-right pl-1">{selectedPortfolio?.portfolio.r}%</span>
            </p>
            <p className="flex justify-between">
              <span className="text-main-500">
                <Trans id="report-plan-summary-frequency-negative-year">Likely frequency of a negative year</Trans>
              </span>
              <span className="text-main-600 font-semibold text-right pl-1">1 in every {selectedPortfolio?.portfolio.aux?.likelyFrequencyOfNegativeYear} yrs</span>
            </p>
            <p className="flex justify-between">
              <span className="text-main-500">
                <Trans id="report-plan-summary-returns-range">Returns range in any one year</Trans>
              </span>
              <span className="text-main-600 font-semibold text-right pl-1">
                {selectedPortfolio?.portfolio.aux?.minReturnInOneYear}% to +{selectedPortfolio?.portfolio.aux?.maxReturnInOneYear}%
              </span>
            </p>
            <p className="flex justify-between text-main-500">
              <Trans id="report-plan-summary-minimum-timeframe">
                Minimum recommended investment timeframe
                <span className="font-semibold text-main-600 text-right pl-1">
                  {selectedPortfolio?.portfolio.aux?.minRecommendedTimeframe}{" "}
                  <Plural
                    id="plan-summary-portfolio-details-minimum-timeframe-unit"
                    value={selectedPortfolio?.portfolio.aux?.minRecommendedTimeframe}
                    one="yr"
                    other="yrs"
                  />
                </span>
              </Trans>
            </p>
          </div>
          <p className="text-sm text-main-500 font-400 text-left mb-6">
            <Trans id="report-plan-summary-disclaimer-text">
              <span className="font-semibold">Disclaimer:</span> Investing carries inherent risks, including potential loss of capital; you should carefully
              consider these risks before making any investment decisions.
            </Trans>
          </p>
        </div>
      </div>
    </Page>
  )
}
