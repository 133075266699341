import { motion } from "framer-motion"
import { useEffect, useState } from "react"

interface Props {
  enableAnimations?: boolean
  radius: number
  textSize: number
  text: string | number
  icon?: JSX.Element
  textColor: string
  offSetX: number
  offSetY: number
  x: number
  y: number
  color: string
  height: number
  showScoreAsDataPoint?: boolean
}

const DataPoint = ({ enableAnimations = true, height, radius, x, y, color, textSize, text, icon, textColor, showScoreAsDataPoint }: Props) => {
  return (
    <g>
      {x !== undefined && (
        <motion.line
          initial={{ x1: x, y1: "100%", x2: x, y2: "100%" }}
          animate={{ x1: x, y1: "100%", x2: x, y2: `${(y / height) * 100}%` }}
          transition={{ duration: enableAnimations ? 1 : 0, delay: enableAnimations ? 0.5 : 0, ease: "easeIn" }}
          fill={color}
          strokeWidth={1}
          stroke={color}
          className="line-chart-data-line"
        />
      )}
      {x !== undefined && (
        <motion.circle
          animate={{ cx: x }}
          cx={x}
          cy="100%"
          r={showScoreAsDataPoint ? 0 : 3}
          fill={color}
          stroke={color}
          strokeWidth="1"
          transition={{ duration: enableAnimations ? 1 : 0, delay: enableAnimations ? 0.5 : 0, ease: "easeIn" }}
          className="line-chart-base-data-point"
        />
      )}
      {x !== undefined && (
        <motion.g
          initial={{ x: x, y: "100%" }}
          animate={{ x: x, y: `${(y / height) * 100}%` }}
          transition={{ duration: enableAnimations ? 1 : 0, delay: enableAnimations ? 0.5 : 0, ease: "easeIn" }}
        >
          <circle r={showScoreAsDataPoint ? 3 : radius} fill={color} stroke={color} strokeWidth="1" className="line-chart-data-point" />
          {icon ? (
            <g transform={`translate(-5.75, -5.75)`}>
              {icon}
            </g>
          ) : (
            <text
              fill={textColor}
              alignmentBaseline="central"
              textAnchor="middle"
              dominantBaseline="central"
              fontSize={textSize!}
              className="uppercase line-chart-datapoint-text"
              y={showScoreAsDataPoint ? -12 : 0}
            >
              {text}
            </text>
          )}
        </motion.g>
      )}
    </g>
  )
}

export default DataPoint
