import { UIEventHandler, useEffect, useState } from "react"
import TextInput from "../../components/TextInput/TextInput"
import { validateEmail } from "../../lib/email"

export interface Props {
  handleClose?: UIEventHandler
}

type Errors = {
  firmName?: string
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
}

type FirmUpdateRequest = {
  firmName?: string
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
}

const CreateFirmModal = ({ handleClose }: Props) => {
  const [errors, setErrors] = useState<Errors>({})
  const [data, setData] = useState<FirmUpdateRequest>({})
  const [shouldValidate, setShouldValidate] = useState<boolean>()

  const validate = (data: FirmUpdateRequest): Errors => {
    return {
      firmName: !data.firmName || data.firmName.trim() === "" ? "This field is required" : undefined,
      firstName: !data.firstName || data.firstName.trim() === "" ? "This field is required" : undefined,
      lastName: !data.lastName || data.lastName.trim() === "" ? "This field is required" : undefined,
      email: data.email && !validateEmail(data.email.trim()) ? "Please enter a valid email address" : undefined,
      phone: data.phone && !/^\+?(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/.test(data.phone) ? "Please enter a valid phone number" : undefined
    }
  }

  useEffect(() => {
    if (shouldValidate) {
      setErrors(validate(data))
    }
  }, [data, shouldValidate])

  const onCreate = () => {
    setShouldValidate(true)
    setErrors(validate(data))
    // Make update request
  }

  return (
    <div className="w-full h-full flex flex-col gap-10">
      <h1 className="text-h2 font-medium">Create new firm</h1>
      <div>
        <TextInput
          name="firm-name"
          label="Firm name"
          value={data.firmName}
          onChange={(val) => setData((prev) => ({ ...prev, firmName: val }))}
          error={errors.firmName}
        />
      </div>
      <hr className="text-surface-200" />
      <div className="flex flex-col gap-6">
        <h2 className="text-h3">Key contact</h2>
        <TextInput
          name="first-name"
          label="First name"
          value={data.firstName}
          onChange={(val) => setData((prev) => ({ ...prev, firstName: val }))}
          error={errors.firstName}
        />
        <TextInput
          name="last-name"
          label="Last name"
          value={data.lastName}
          onChange={(val) => setData((prev) => ({ ...prev, lastName: val }))}
          error={errors.lastName}
        />
        <TextInput
          name="phone"
          label="Phone"
          optional
          value={data.phone}
          onChange={(val) => setData((prev) => ({ ...prev, phone: val }))}
          error={errors.phone}
        />
        <TextInput
          name="email"
          label="Email"
          optional
          value={data.email}
          onChange={(val) => setData((prev) => ({ ...prev, email: val }))}
          error={errors.email}
        />
      </div>
      <div className="w-full flex gap-4 justify-center py-10">
        <button className="btn btn-secondary btn-medium w-44" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-primary btn-medium w-44" onClick={onCreate}>
          Create
        </button>
      </div>
    </div>
  )
}

export default CreateFirmModal
