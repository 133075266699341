import { Client, GoalDetail } from "../../../models/Client"
import Container from "./components/Container"
import { Household } from "../../../models/Household"
import Footer from "./components/Footer"
import { useContext, useMemo } from "react"
import { FirmContext } from "../../../contexts/FirmContext"
import { Options } from "../../rmjourney/goalExplorer/components/SelectPortfolio/SelectPortfolio"
import PortfolioTable from "../../rmjourney/goalExplorer/components/PortfolioTable/PortfolioTable"
import { getGoalTitle } from "../../advisor/Results/Goals/components/CardTitle"
import { tt } from "../../../lib/translations"

interface Props {
  page: number
  client: Client
  household: Household
  options: Options
  goal?: GoalDetail
}

const ProjectorTablePage = ({ page, client, household, options, goal }: Props) => {
  const { firm } = useContext(FirmContext)
  const clientOrHousehold = client || household

  const goalType = goal?.type === "retirementIncome" && clientOrHousehold?.alreadyRetired ? "retirementDrawdown" : goal?.type

  const title = useMemo(() => {
    if (goal && clientOrHousehold) {
      const title = getGoalTitle({ client, household, goalDetails: clientOrHousehold.goals!.goalDetails!, goal })
      return title ? title : tt({ id: `profile-page-status-card-goals-goal-${goal.type}-title` })
    }
  }, [client, clientOrHousehold, goal, household])

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="flex-auto mx-10 h-1/2">
        <h2 className="text-sm uppercase">
          {goalType === "retirementIncome" ? "Retirement goal" : goalType === "retirementDrawdown" ? "Retirement drawdown goal" : `${title} goal`}
        </h2>
        <h3 className="mb-2 text-main-600 text-primary pb-5 tracking-wide">
          {goalType === "retirementIncome"
            ? "Retirement income projections"
            : goalType === "retirementDrawdown"
            ? "Retirement drawdown projections"
            : `${title} projections`}
        </h3>
        {options && (
          <div className="flex flex-col justify-between">
            <div className="mb-2">
              {options && (
                <PortfolioTable
                  options={options}
                  client={client}
                  household={household}
                  clientOrHousehold={clientOrHousehold}
                  goalType={goalType}
                  forReport={true}
                  showComfortMatch={true}
                  showGoalMatch={true}
                  goal={goal}
                />
              )}
              {goalType === "retirementIncome" && (
                <div className="text-xs text-main-400 leading-1">
                  <p>
                    <i>Goal achievability</i> is the calculated probability of exceeding your retirement income goal.
                  </p>
                  <p>
                    <i>Projected income</i> reflects the withdrawal amount at the 30th percentile of the distribution of outcomes, with the range reflecting the
                    15th-85th percentiles of the distribution of outcomes.
                  </p>
                </div>
              )}
              {goalType !== "retirementIncome" && goalType !== "retirementDrawdown" && (
                <div className="text-xs text-main-400 leading-1">
                  <p>
                    <i>Goal achievability</i> is the calculated probability of exceeding your {title?.toLowerCase()} goal.
                  </p>
                  <p>
                    <i>Projected wealth</i> reflects the 30th percentile of the distribution of outcomes, with the range reflecting the 15th-85th percentiles of
                    the distribution of outcomes.
                  </p>
                </div>
              )}
              {goalType === "retirementDrawdown" && (
                <div className="text-xs text-main-400 leading-1">
                  {(clientOrHousehold?.estateGoal || goal?.estateGoal) && (
                    <p>
                      <i>When legacy money may be required</i> is the year when there’s at least a 30% likelihood that you’ll need to begin using your 'legacy
                      money' to fund your retirement income withdrawals.
                    </p>
                  )}
                  <p className="mt-1">
                    <i>{clientOrHousehold?.estateGoal || goal?.estateGoal ? "When legacy money may run out" : "When expected wealth may run out"}</i> is the
                    year when there’s at least a 30% likelihood you’ll have nothing left in your portfolio, with the range reflecting the 15%-85% likelihood
                    range.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="relative mx-10">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default ProjectorTablePage
