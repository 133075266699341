import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { VictoryArea, VictoryAxis, VictoryChart, VictoryContainer, VictoryLabel, VictoryLine, VictoryScatter } from "victory"
import { shortRound } from "../../../../../lib/numbers"
import { AnimatePresence, motion } from "framer-motion"
import { Trans } from "@lingui/macro"
import { tt } from "../../../../../lib/translations"
import useNumberFormatter from "../../../../../hooks/useNumberFormatter"
import { useTheme } from "../../../../../contexts/ThemeContext"
import AxisLabels from "./AxisLabels"
import DataPoint from "./DataPoint"

interface GraphData {
  goal: number
  portfolioExpectedValues: number[]
  portfolioHighValues: number[]
  portfolioLowValues: number[]
  portfolioYearValues: number[]
}

const roundingRules = {
  20: [0, 10000],
  50: [10000, 25000],
  100: [25000, 100000],
  500: [100000, 250000],
  1000: [250000, 1000000],
  5000: [1000000, 2500000],
  10000: [2500000, 10000000],
  50000: [10000000, 100000000],
  1000000: [100000000, 1000000000],
  100000000: [1000000000, 25000000000],
  500000000: [25000000000, 1000000000000],
  100000000000: [1000000000000, Infinity]
}

const NUMBER_OF_TICKS = 5

const GoalProjectionChart = ({
  forReport = false,
  goalAmount,
  goalTargetYear,
  graphData,
  portfolioProjection,
  shouldHideWealthGoalDetails,
  customDatapoints,
  customYAxisTicks,
  showSmartProjection = false,
  showGoalTargetyear = true,
  showCurrencyUnit = true,
  tickCounts,
  isFormatCurrency,
  chartPadding,
  formatDatapoint
}: {
  forReport?: boolean
  goalTargetYear?: number
  graphData: GraphData
  goalAmount?: number
  portfolioProjection?: number
  shouldHideWealthGoalDetails?: boolean
  customDatapoints?: { x: number; y: number }[]
  customYAxisTicks?: number[]
  showSmartProjection?: boolean
  showGoalTargetyear?: boolean
  tickCounts?: {
    xAxisTickCount?: number
    yAxisTickCount?: number
  }
  showCurrencyUnit?: boolean
  isFormatCurrency?: boolean
  chartPadding?: { top: number; bottom: number; left: number; right: number }
  formatDatapoint?: "short" | "long"
}) => {
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const [chartSize, setChartSize] = useState<{ width: number; height: number }>({ width: 500, height: 200 })
  const [targetDateline, setTargetDateLine] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const [targetLineValues, setTargetLineValues] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const [roundedTo, setRoundedTo] = useState(0)
  const [topYtickVal, setTopYtickVal] = useState(0)
  const currentYear = new Date().getFullYear()
  const theme = useTheme()
  const formatCurrency = useNumberFormatter(theme.defaultLocale ?? "")
  const currency = tt({ id: "currency-symbol" })
  const padding = chartPadding ? chartPadding : forReport ? { left: 60, right: 25, top: 50, bottom: 60 } : { left: 55, right: 27, top: 50, bottom: 60 }

  const data = useMemo(
    () =>
      graphData
        ? graphData.portfolioExpectedValues.map((val, i) => ({
            expectedValues: { x: graphData.portfolioYearValues[i], y: val },
            portfolioHighValues: { x: graphData.portfolioYearValues[i], y: graphData.portfolioHighValues[i] },
            portfolioLowValues: { x: graphData.portfolioYearValues[i], y: graphData.portfolioLowValues[i], y0: graphData.portfolioHighValues[i] }
          }))
        : [],
    [graphData]
  )
  const calculateLine = useCallback(
    (arr: { x: number; y: number }[], scaleX: number, scaleY: number, length?: number) => {
      const pathString = arr
        .map(({ y }, index) => {
          const scaledX = padding.left + (length ? index + length : index) * scaleX
          const scaledY = chartSize.height - padding.bottom - y * scaleY

          return index === 0 ? `M${scaledX},${scaledY}` : `L${scaledX},${scaledY}`
        })
        .join("")

      const startCoordinate = `M${targetDateline.x},${targetDateline.y}`
      const endCoordinate = `L${targetDateline.x},${targetDateline.y}`

      let updatePath

      if (length) {
        updatePath = `${startCoordinate}${pathString.replace(/^M/, "L")}`
      } else if (arr.length !== data.length) {
        updatePath = `${pathString}${endCoordinate}`
      } else {
        updatePath = pathString
      }

      return updatePath
    },

    [chartSize.height, data.length, padding.bottom, padding.left, targetDateline.x, targetDateline.y]
  )

  const chartData = useMemo(() => {
    const expectedValues = data.map(({ expectedValues }) => expectedValues)
    const high = data?.map(({ portfolioHighValues }) => portfolioHighValues)
    const low = data?.map(({ portfolioLowValues }) => portfolioLowValues)

    const maxYAxis = Math.max(...high.map((dataPoint) => dataPoint.y))

    const maxXAxis = Math.max(...expectedValues.map((dataPoint) => dataPoint.x))

    const chartMaxYAxisVal = goalAmount && goalAmount > maxYAxis ? goalAmount : maxYAxis

    const targetDate = customDatapoints ?? [{ x: goalTargetYear, y: portfolioProjection }]
    const expectedOutcome = [...expectedValues].filter(({ y }) => y <= portfolioProjection!)
    const projectedOutcome = [...expectedValues].splice(expectedOutcome.length)

    const scaleX = (chartSize.width - (padding.left + padding.right)) / (expectedValues.length - 1)
    const scaleY = (chartSize.height - (padding.top + padding.bottom)) / chartMaxYAxisVal

    const dataLinePoints = calculateLine(expectedOutcome, scaleX, scaleY)
    const extendedPoints = calculateLine(projectedOutcome, scaleX, scaleY, expectedOutcome.length)

    const isTargetDate = expectedValues.some(({ x }) => x === goalTargetYear)

    const goalLine = Array(expectedValues.length)
      .fill(0)
      .map((_, i) => ({ x: expectedValues[i].x, y: goalAmount ?? 0 }))

    const goalLineData = calculateLine(goalLine, scaleX, scaleY)
    const goalLabelPos = chartSize.height - padding.bottom - (goalAmount ?? 0) * scaleY

    const investmentGoalAmount = shortRound(goalAmount ?? 0)

    return {
      maxYAxis,
      maxXAxis,
      high,
      low,
      dataLinePoints,
      extendedPoints,
      expectedValues,
      investmentGoalAmount,
      targetDate,
      isTargetDate,
      goalLine,
      goalAmount,
      goalLineData,
      goalLabelPos,
      chartMaxYAxisVal,
      expectedOutcome,
      projectedOutcome
    }
  }, [
    data,
    goalAmount,
    customDatapoints,
    goalTargetYear,
    portfolioProjection,
    chartSize.width,
    chartSize.height,
    padding.left,
    padding.right,
    padding.top,
    padding.bottom,
    calculateLine
  ])

  useEffect(() => {
    for (const [rule, [low, high]] of Object.entries(roundingRules)) {
      if (chartData.chartMaxYAxisVal > low && chartData.chartMaxYAxisVal <= high) {
        setRoundedTo(parseInt(rule))
      }
    }
  }, [chartData.chartMaxYAxisVal])

  const computedTopYTickVal = useMemo(() => {
    if (customYAxisTicks) {
      return customYAxisTicks[customYAxisTicks.length - 1]
    }
    return Math.ceil(chartData.chartMaxYAxisVal / (roundedTo * (NUMBER_OF_TICKS - 1))) * roundedTo * (NUMBER_OF_TICKS - 1)
  }, [chartData.chartMaxYAxisVal, customYAxisTicks, roundedTo])

  const yTickVals = useMemo(() => {
    if (customYAxisTicks) {
      return customYAxisTicks
    }
    return Array(NUMBER_OF_TICKS)
      .fill(0)
      .map((_, i) => (i === 0 ? 0 : (i * computedTopYTickVal) / (NUMBER_OF_TICKS - 1)))
  }, [customYAxisTicks, computedTopYTickVal])

  useEffect(() => {
    setTopYtickVal(computedTopYTickVal)
  }, [computedTopYTickVal])

  useEffect(() => {
    const localRef = chartContainerRef.current
    const divResizeObserver = new ResizeObserver(() => {
      setChartSize({
        width: chartContainerRef.current?.offsetWidth ?? 100,
        height: chartContainerRef.current?.offsetHeight ?? 100
      })
    })

    divResizeObserver.observe(localRef!)
    return () => {
      divResizeObserver.unobserve(localRef!)
    }
  }, [])

  const TargetLine = () => {
    const offset = forReport ? 20 : 30
    const offsetY = !showGoalTargetyear ? 20 : !chartData.isTargetDate ? -7 : 8
    const targetDateOffsetY = 30
    const targetDateOffsetX = 8
    return (
      <AnimatePresence initial={!forReport}>
        <motion.g>
          <motion.path
            initial={{ pathLength: 0, pathOffset: 0 }}
            animate={{ pathLength: 1, pathOffset: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
            d={`M${targetDateline.x} ${targetDateline.y} L${targetDateline.x} ${targetLineValues.y - offsetY}`}
            stroke="#00222E"
            strokeWidth="2"
          />
          {showGoalTargetyear && (
            <g>
              <motion.rect className="fill-main-400" x={targetDateline.x - offset} y={targetLineValues.y - offsetY} width={40} height={offset} rx={10} />
              <motion.text className="fill-white text-xs" x={targetDateline.x - offset / 2 - 2} y={targetLineValues.y - offsetY + 14}>
                {goalTargetYear}
              </motion.text>
            </g>
          )}
          {!shouldHideWealthGoalDetails && (
            <motion.text
              className="fill-main-600 font-semibold text-xs"
              x={targetDateline.x - offset - targetDateOffsetX}
              y={targetLineValues.y - offsetY + targetDateOffsetY}
            >
              <Trans id="goal-projection-chart-target-date-text">Target date</Trans>
            </motion.text>
          )}
        </motion.g>
      </AnimatePresence>
    )
  }

  const GoalLineLabel = () => {
    return (
      <AnimatePresence>
        <motion.path d={chartData.goalLineData} className="stroke-main-400" strokeWidth="2" fill="none" />
        <motion.text className="fill-main-600 text-sec font-semibold" x={padding.left} y={chartData.goalLabelPos - 5} style={{ fontFamily: "inherit" }}>
          <Trans id="goal-projection-chart-goal-line-text">
            goal ${chartData.investmentGoalAmount.value}
            {chartData.investmentGoalAmount.unit}
          </Trans>
        </motion.text>
      </AnimatePresence>
    )
  }

  return (
    <div className="w-full h-full overflow-hidden relative" ref={chartContainerRef}>
      <VictoryChart
        padding={{ top: padding.top, bottom: padding.bottom, left: padding.left, right: padding.right }}
        containerComponent={<VictoryContainer responsive={false} />}
        height={forReport ? 300 : chartSize?.height}
        width={chartSize.width}
        domain={{
          x: [currentYear, chartData.maxXAxis],
          y: [0, topYtickVal]
        }}
      >
        <VictoryAxis
          axisLabelComponent={<VictoryLabel dy={forReport ? 65 : 25} />}
          crossAxis={false}
          offsetX={0}
          style={{
            axis: { stroke: "#C1CBCB", strokeWidth: 5 },
            ticks: {
              size: 10,
              stroke: "#C1CBCB",
              strokeWidth: 5
            },
            axisLabel: { fontSize: "14px", fontFamily: "inherit", fontWeight: 600 },
            tickLabels: {
              fill: "#7E8B8D",
              fontSize: 12,
              fontFamily: "inherit",
              fontWeight: 400
            }
          }}
          fixLabelOverlap
          tickCount={tickCounts?.xAxisTickCount ?? chartData.expectedValues.length - 1}
          tickValues={chartData.expectedValues.map(({ x }) => x)}
          tickFormat={(xAxislabel: number) => {
            return `'${new Date(xAxislabel, 0, 1).getFullYear().toString().slice(-2)}`
          }}
          tickLabelComponent={<AxisLabels setTargetLineValues={setTargetLineValues} />}
        />
        <VictoryAxis
          axisLabelComponent={<VictoryLabel dy={forReport ? -60 : -35} />}
          crossAxis={false}
          dependentAxis
          style={{
            axis: { stroke: "transparent" },
            axisLabel: { fontSize: "14px", fontFamily: "inherit", fontWeight: 600 },
            grid: {
              stroke: (p: any) => (p.index === 0 ? "transparent" : "#E4E2DE"),
              strokeDasharray: "2 2",
              strokeWidth: 1
            },
            tickLabels: {
              fill: "#7E8B8D",
              fontSize: 12,
              fontFamily: "inherit",
              fontWeight: 400
            }
          }}
          tickValues={yTickVals}
          tickFormat={(amount: number) => {
            const { value, unit } = shortRound(amount)
            return tt({
              id: "currency-amount",
              values: { currency, amount: `${isFormatCurrency ? formatCurrency.format(amount ?? 0) : value}${showCurrencyUnit ? unit : ""}` }
            })
          }}
        />

        <VictoryArea
          interpolation="basis"
          data={chartData.low}
          style={{ data: { stroke: "none", strokeWidth: 2, strokeDasharray: 5, fill: "#FCE1F6", fillOpacity: "60%" } }}
        />
        <VictoryLine
          interpolation="basis"
          data={chartData.high}
          style={{ data: { stroke: showSmartProjection ? "#F4832E" : "none", strokeWidth: 2, strokeDasharray: 0 } }}
        />

        <VictoryLine
          interpolation="basis"
          data={showSmartProjection ? chartData.expectedValues : [...chartData.expectedOutcome].sort((a, b) => a.x - b.x)}
          style={{ data: { stroke: "#F4832E", strokeWidth: 2 } }}
        />
        {!showSmartProjection && (
          <VictoryLine
            interpolation="basis"
            data={[...chartData.projectedOutcome, ...chartData.targetDate].sort((a, b) => a.x! - b.x!)}
            style={{ data: { stroke: "#F4832E", strokeWidth: 2, strokeDasharray: 5 } }}
          />
        )}

        {!shouldHideWealthGoalDetails && <GoalLineLabel />}

        {(goalTargetYear || showSmartProjection) && <TargetLine />}

        <VictoryScatter
          data={chartData.targetDate}
          dataComponent={
            <DataPoint
              currency={currency}
              chartWidth={chartSize.width}
              paddingRight={padding.right}
              setTargetDateLine={setTargetDateLine}
              showCurrencyUnit={formatDatapoint === "short" || showCurrencyUnit}
              isFormatCurrency={formatDatapoint !== "short" && isFormatCurrency}
              shouldLongRound={formatDatapoint === "long"}
            />
          }
        />
      </VictoryChart>
    </div>
  )
}

export default React.memo(GoalProjectionChart)
