import clsx from "clsx"
import { useContext } from "react"
import { NavLink, Route, Routes, useLocation, useParams } from "react-router-dom"
import Loading from "../../components/ClientProfile/Loading/Loading"
import { FirmContext } from "../../contexts/FirmContext"
import { useTheme } from "../../contexts/ThemeContext"
import useClient from "../../hooks/useClient"
import useHousehold from "../../hooks/useHousehold"
import { latest } from "../../lib/clients"
import { tt } from "../../lib/translations"
import InvestmentValuesTab from "../advisor/Results/InvestmentValuesTab"
import Profile from "../clients/components/Profile/Profile"
import RiskTab from "../advisor/Results/Risk/RiskTab"
import PFSTab from "../advisor/Results/PFS/PFSTab"
import GoalsTab from "../advisor/Results/Goals/GoalsTab"
import ComingSoon from "../advisor/Results/ComingSoon"
import LiquidityTab from "../advisor/Results/Liquidity/LiquidityTab"
import TaxTab from "../advisor/Results/Tax/TaxTab"
import RetirementIncomeTab from "../advisor/Results/RetirementIncome/RetirementIncomeTab"

const CRMRoutes = () => {
  const { firm } = useContext(FirmContext)
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const clientOrHousehold = client || household
  const theme = useTheme()
  const location = useLocation()

  if (!clientOrHousehold) {
    return <Loading />
  } else {
    return (
      <div className="crm-overview-page relative bg-white h-full pb-8 text-main-500">
        <nav className="w-full font-semibold">
          <ul className="flex overflow-x-auto no-scrollbar">
            <li className="py-4 text-sec flex-shrink-0 flex-nowrap">
              <NavLink
                end
                className={({ isActive }: { isActive: boolean }) => clsx("px-3 py-3.75", isActive ? "border-b-2 border-b-black font-bold" : "font-semibold")}
                to={{ pathname: "overview", search: location.search }}
                replace={true}
              >
                Overview
              </NavLink>
            </li>
            {theme.games?.map((game) => {
              if (game.type === "pfs" || game.type === "goals" || firm?.config?.supportedGames?.some((sg) => sg?.type === game?.type)) {
                // PFS and Goals tabs should always be present
                const clientGamePlayed = client && latest(client, game.type)?.played
                const householdGamePlayed = household?.members?.some((member) => {
                  return latest(member.client, game.type)?.played
                })
                // Disable the tab only if the game is neither "pfs" nor "goals" and hasn't been played by anyone
                const isTabDisabled = game.type !== "pfs" && game.type !== "goals" && (household ? !householdGamePlayed : !clientGamePlayed)
                const linkStyle = ({ isActive }: { isActive: boolean }) =>
                  clsx(
                    "px-3 py-3.75",
                    isActive ? "border-b-2 border-b-black font-bold" : "font-semibold",
                    isTabDisabled ? "text-main-200 cursor-default" : "text-interactive-500"
                  )
                return (
                  <li key={game.type} className="py-4 text-sec flex-shrink-0 flex-nowrap">
                    <NavLink
                      end
                      className={linkStyle}
                      to={{ pathname: game.type, search: location.search }}
                      onClick={(e) => isTabDisabled && e.preventDefault()}
                      aria-disabled={isTabDisabled}
                      replace={!!isTabDisabled}
                    >
                      {tt({ id: `game-${game.type}-title` })}
                    </NavLink>
                  </li>
                )
              }
            })}
          </ul>
        </nav>
        <div className="w-full">
          <div className="border-b border-b-surface-300" />
        </div>
        <div className="h-full overflow-y-auto no-scrollbar">
          <Routes>
            <Route
              path="overview"
              element={
                <div className="py-9">
                  <Profile client={client!} />
                </div>
              }
            />
            <Route path="pfs" element={<PFSTab client={client} household={household} isEmbedded={true} />} />
            <Route path="goals" element={<GoalsTab client={client} household={household} isEmbedded={true} />} />
            <Route path="risk" element={<RiskTab client={client} household={household} />} />
            <Route path="liquidity" element={<LiquidityTab client={client} household={household} isEmbedded={true} />} />
            <Route path="esg" element={theme.crmRoutes?.investmentValues?.enabled ? <InvestmentValuesTab client={client} household={household} /> : <ComingSoon />} />
            <Route path="tax" element={<TaxTab client={client} household={household} isEmbedded={true} />} />
            <Route path="retirement" element={<RetirementIncomeTab client={client} household={household} isEmbedded={true} />} />
            <Route path="*" element={<div className="full-flex-content-center text-error">We have encountered a configuration error.</div>} />
          </Routes>
        </div>
      </div>
    )
  }
}

export default CRMRoutes
