import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import { Fragment, ReactNode, useEffect, useMemo, useRef, useState } from "react"
import DataPoint from "./DataPoint"
import PatternsModal from "../../../clients/reports/RiskChartsPage/components/PatternsModal"
import Modal from "../../../../components/Modal/Modal"
import { GameType } from "../../../../models/Client"

export interface Label {
  id: string
  text: string
}

export interface XAxisConfig {
  minLabels?: number
  labels: Label[]
  title?: string
}

export interface YAxisConfig {
  labels: Label[]
  title?: string
}

export interface Value {
  id: string
  value: number
}

export interface Series {
  id: string
  color: string
  title?: string
  values: Value[]
}

export interface XLabel extends Label {
  x: number
}

export interface YLabel extends Label {
  y: number
}

export interface NodeData {
  color: string
  id: string
  value: number
  x: number
  y: number
}
export interface SeriesData {
  color: string
  id: string
  nodes: NodeData[]
}

export interface CreateSeries {
  seriesData: Series
  height: number
  width: number
  maxYvalue: number
}
export interface Datapoint {
  x: number | undefined
  y: number | undefined
}

export interface LegendData {
  color: string
  id: string
  text?: string
}

export interface Props {
  className?: string
  enableAnimations?: boolean
  series: Series[]
  legend?: LegendData[]
  showNodes?: boolean
  title?: string
  type?: "risk" | "loss"
  xAxis: XAxisConfig
  yAxis: YAxisConfig
  xAxisLabel?: {
    show: boolean
    text: ReactNode
  }
  gameType: GameType
  icon?: string
  highlightDataPoints?: {
    id: string
    height?: number
    color: string
    radius: number
    textSize: number
    text: string | undefined
    icon?: JSX.Element
    textColor: string
    offSetX: number
    offSetY: number
    x: number | string
    y: number | string
  }[]
  highlightAreas?: {
    endXPercent: number
    id: string
    label: string
    startXPercent: number
  }[]
  showScoreAsDataPoint?: boolean
  forReport?: boolean
}

const toFixed = (value: number): number => {
  return Number(value.toFixed(2))
}

const createSeries = ({ seriesData, height, width, maxYvalue }: CreateSeries): SeriesData => {
  return {
    color: seriesData.color,
    id: seriesData.id,
    nodes: seriesData.values.map(({ id, value }: { id: string; value: number }, i: number) => {
      return {
        color: seriesData.color,
        id,
        value,
        x: (i / (seriesData.values.length - 1)) * width,
        y: height - (value / maxYvalue) * height
      }
    })
  }
}

const LineChart = ({
  className,
  enableAnimations = true,
  type,
  series,
  legend,
  title,
  xAxis,
  yAxis,
  icon,
  highlightDataPoints,
  highlightAreas,
  xAxisLabel,
  gameType,
  showScoreAsDataPoint = false,
  forReport = false
}: Props) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [patternsModal, setPatternsModal] = useState<boolean>(false)

  useEffect(() => {
    const localRef = chartRef.current
    const divResizeObserver = new ResizeObserver((e) => {
      setWidth(e[0].contentRect.width)
      setHeight(e[0].contentRect.height)
    })
    divResizeObserver.observe(localRef!)
    return () => {
      divResizeObserver.unobserve(localRef!)
    }
  }, [])

  /* 
  We should extract the maximum Y value from the Y-axis labels instead of deriving it from the chart data to be plotted. 
  This approach ensures that the curve height is set to match the maximum value present in the data, 
  providing an accurate depiction of the chart's scaling and revealing the starting point of curvature. 
  example: if the maximum Y axis label is 25 and the maximum value in the chart data is 20 then 
  the calculation will use the 25 as the maxYvalue. 
  */
  const maxYvalue = useMemo(() => {
    const yAxisValue = [...yAxis.labels].map(({ text }) => {
      const matches = text.match(/\d+(\.\d+)?/)
      return parseFloat(matches![0])
    })
    return Math.max(...yAxisValue)
  }, [yAxis.labels])

  const data = useMemo(() => {
    return {
      chartSeries: series.map((seriesData) => {
        return createSeries({ seriesData, height, width, maxYvalue })
      }),
      xLabels: [...xAxis.labels].map(({ id, text }, i) => {
        return {
          id,
          text,
          x: toFixed(i * (100 / (xAxis.labels.length - 1)))
        }
      }),
      yLabels: [...yAxis.labels].reverse().map(({ id, text }, i) => {
        return {
          id,
          text,
          y: toFixed(i * (100 / (yAxis.labels.length - 1)))
        }
      })
    }
  }, [series, xAxis.labels, yAxis.labels, height, width, maxYvalue])

  const calculatedDataPointCoordinates: Datapoint[] | undefined = useMemo(() => {
    const filterData = highlightDataPoints?.map((dp) => series[0].values.find((_, i) => Number(dp.x) === i)!) || []
    if (width !== 0) {
      return filterData.map((data) => {
        const x = (Number(data?.id) / (series[0].values.length - 1)) * width
        const y = height - (data?.value / maxYvalue) * height
        return { x, y }
      })
    }
  }, [highlightDataPoints, width, series, height, maxYvalue])

  return (
    <>
      <div className={clsx("w-full h-full flex flex-col line-chart", className)}>
        {title && <h2 className="text-main-600 font-semibold justify-center w-full h-10 flex">{title}</h2>}
        <div className="w-full flex flex-grow relative">
          {yAxis.title && (
            <div className="y-axis-title w-8 flex flex-col items-center">
              <div className="flex flex-grow items-center">
                <p className="text-sec font-semibold leading-4 text-main-400 block -rotate-90 w-max y-axis-title">{yAxis.title}</p>
              </div>
              <div className="flex items-end pb-10">{icon && <img src={icon} alt="chart icon" className="chart-icon" />}</div>
            </div>
          )}
          <div className="flex flex-col flex-grow">
            <div className="flex flex-grow mb-2">
              {/* Y Axis labels */}
              <div className="y-axis-labels relative w-10 flex flex-col items-end mr-3">
                {data.yLabels.map(({ id, text, y }) => {
                  return (
                    <div className="absolute text-sm text-main-400 text-right leading-1 pr-2 -translate-y-1/2" key={id} style={{ top: `${y}%` }}>
                      {text}
                    </div>
                  )
                })}
              </div>
              <div className="relative flex-grow" ref={chartRef}>
                {width > 0 && highlightAreas && (
                  <svg height="100%" width="100%" className="absolute bottom-0 block overflow-visible" viewBox={`0 0 ${width} ${height}`}>
                    {highlightAreas.map(({ endXPercent, id, label, startXPercent }, i) => {
                      const labelX = startXPercent + (endXPercent - startXPercent) / 2

                      return (
                        <g key={id}>
                          {i > 0 && (
                            <line
                              className="line-chart-highlight-area-line"
                              strokeWidth={0.5}
                              x1={`${startXPercent}%`}
                              y1="-30"
                              x2={`${startXPercent}%`}
                              y2="100%"
                              stroke="#E4E2DE"
                            />
                          )}
                          <text
                            x={`${labelX}%`}
                            y={-15}
                            textAnchor="middle"
                            className={clsx("highlight-labels fill-main-300", forReport ? "text-sm" : "text-sec")}
                          >
                            {label}
                          </text>
                        </g>
                      )
                    })}
                  </svg>
                )}
                {width > 0 && (
                  <svg height="100%" width="100%" className="absolute bottom-0 block overflow-visible" viewBox={`0 0 ${width} ${height}`}>
                    {data.chartSeries.map(({ color, id, nodes }, i) => {
                      const points = nodes.reduce((prev: number[], current: NodeData): number[] => {
                        return [...prev, current.x, current.y]
                      }, [])

                      return (
                        <Fragment key={id}>
                          {calculatedDataPointCoordinates && calculatedDataPointCoordinates[i]?.x !== undefined && (
                            <svg className="y-axis-lines" height="100%" width="100%" viewBox={`0 0 ${width} ${height}`}>
                              <motion.g key={id}>
                                <motion.g>
                                  {data.yLabels.map(({ id, y }) => {
                                    return (
                                      <motion.line
                                        className="y-line"
                                        key={id}
                                        fill={color}
                                        strokeWidth={1}
                                        strokeDasharray={2}
                                        x1={`${0}%`}
                                        y1={`${y}%`}
                                        x2={width}
                                        y2={`${y}%`}
                                        stroke="#E4E2DE"
                                      />
                                    )
                                  })}
                                </motion.g>
                                <motion.polyline
                                  initial={{ y: "100%", points: ["0", "0"].join(" ") }}
                                  animate={{ y: 0, points: points.join(" ") }}
                                  fill="none"
                                  stroke={color}
                                  strokeWidth="2"
                                  transition={{ duration: enableAnimations ? 1 : 0, delay: enableAnimations ? 0.5 : 0, ease: "easeIn" }}
                                  vectorEffect="non-scaling-stroke"
                                  className="line-chart-line"
                                />
                                <motion.polyline
                                  initial={{ y: "100%", points: ["0", "0"].join(" ") }}
                                  animate={{ y: 0, points: ["0", height, ...points, width, height].join(" ") }}
                                  fill={`url(#gradient-${i})`}
                                  opacity={1}
                                  stroke="none"
                                  strokeWidth="1"
                                  transition={{ duration: enableAnimations ? 1 : 0, delay: enableAnimations ? 0.5 : 0, ease: "easeIn" }}
                                  vectorEffect="non-scaling-stroke"
                                  className="line-chart-gradient"
                                />
                                <defs>
                                  <linearGradient id={`gradient-${i}`} x1="0" x2="0" y1="0" y2="1">
                                    <stop offset="0%" stopColor={color} stopOpacity="0.3" />
                                    <stop offset="100%" stopColor={color} stopOpacity="0.05" />
                                  </linearGradient>
                                </defs>
                              </motion.g>
                            </svg>
                          )}
                          <svg height="100%" width="100%" className="overflow-visible" viewBox={`0 0 ${width} ${height}`}>
                            {highlightDataPoints &&
                              calculatedDataPointCoordinates &&
                              highlightDataPoints.map((highLightDataPoint, j) => {
                                const x = calculatedDataPointCoordinates[j]?.x ?? 0
                                const y = calculatedDataPointCoordinates[j]?.y ?? 0

                                const prevPoint = highlightDataPoints[j - 1]
                                const prevY = calculatedDataPointCoordinates[j - 1]?.y
                                const isOverlapped = prevPoint !== undefined && prevY !== undefined ? highLightDataPoint.x === prevPoint.x : false

                                return (
                                  <DataPoint
                                    enableAnimations={enableAnimations}
                                    height={height}
                                    key={highLightDataPoint.id}
                                    radius={highLightDataPoint.radius}
                                    color={highLightDataPoint.color}
                                    text={highLightDataPoint.text!}
                                    icon={highLightDataPoint.icon}
                                    textSize={highLightDataPoint.textSize}
                                    textColor={highLightDataPoint.textColor}
                                    offSetX={highLightDataPoint?.offSetX}
                                    offSetY={highLightDataPoint?.offSetY}
                                    x={x}
                                    y={isOverlapped ? (prevY ?? 0) + highLightDataPoint.radius * 2 : y}
                                    showScoreAsDataPoint={showScoreAsDataPoint}
                                  />
                                )
                              })}
                          </svg>
                        </Fragment>
                      )
                    })}
                  </svg>
                )}
              </div>
            </div>
            {/* X Axis labels */}
            <div className={clsx("x-axis-labels h-10 flex flex-col pl-12", xAxisLabel?.show && "mb-3")}>
              <div className="relative flex-grow">
                {data.xLabels.map(({ id, text, x }, i) => {
                  return (
                    <div
                      className={clsx("absolute text-sm", {
                        "-translate-x-1/3": i > 0 && i < data.xLabels.length - 1,
                        "-translate-x-1/2": i === data.xLabels.length - 1,
                        "text-main-400": gameType === "risk",
                        "text-neutral-500": gameType === "esg"
                      })}
                      key={id}
                      style={{
                        left: `${x}%`
                      }}
                    >
                      {text}
                    </div>
                  )
                })}
              </div>
              {xAxisLabel?.show && xAxisLabel.text}
            </div>
          </div>
        </div>
        {xAxis.title && (
          <div className="x-axis-title flex justify-center">
            <div className="x-axis-title-gap w-24" />
            <div className="flex flex-grow justify-center">
              <p
                className={clsx(
                  "leading-5 x-axis-title",
                  xAxisLabel?.show ? "text-black font-normal" : "text-main-400 font-semibold",
                  forReport ? "text-sm" : "text-sec "
                )}
              >
                {xAxis.title}
              </p>
            </div>
          </div>
        )}
        {/* Legend */}
        {legend && (
          <div className="legend mt-4 flex">
            <div className="legend-gap w-24" />
            <div className="flex flex-grow justify-center gap-5">
              {legend.map(({ color, id, text }) => {
                return (
                  <p className="flex items-center" key={id}>
                    <span className="w-2 h-2 block rounded-full mr-2" style={{ backgroundColor: color }}></span>
                    <span className="text-sm text-main-400">{text}</span>
                  </p>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <AnimatePresence>
        {patternsModal && (
          <Modal handleClose={() => setPatternsModal(false)}>
            <PatternsModal type={type!} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default LineChart
