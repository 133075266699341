import { format, intervalToDuration, parseISO } from "date-fns"
import { AnimatePresence } from "framer-motion"
import { useCallback, useMemo, useState } from "react"
import edit from "../../../../assets/icons/edit.svg"
import { DATE_FORMAT } from "../../../../lib/date"
import { tt } from "../../../../lib/translations"
import { Client, GoalDetail } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import EditInvestmentTimeframeModal from "../EditInvestmentTimeframeModal/EditInvestmentTimeframeModal"

const InvestmentTimeframe = ({
  client,
  household,
  goal,
  onUpdateDone
}: {
  client?: Client
  household?: Household
  goal: GoalDetail
  onUpdateDone: (goalDetail?: GoalDetail) => void
}) => {
  const [isInvestmentTimeframeModalOpen, setIsInvestmentTimeframeModalOpen] = useState(false)
  const [retirementClient, setRetirementClient] = useState<Client>()
  const clientOrHousehold = client ?? household

  const title =
    goal.type === "retirementIncome" && clientOrHousehold?.alreadyRetired
      ? "Retirement"
      : goal.type === "retirementIncome"
      ? "Retirement age"
      : tt({ id: `goal-projector-form-${goal.type}-target-date-label` })

  const targetDateLabel = useMemo(() => {
    const diffFromNow = goal.targetDate?.value ? intervalToDuration({ start: new Date(), end: new Date(goal.targetDate?.value) }) : undefined
    return diffFromNow ? `${`${format(new Date(goal.targetDate!.value!), DATE_FORMAT)}`}${` (in ${diffFromNow.years}yrs ${diffFromNow.months}mos)`}` : ""
  }, [goal.targetDate])

  const getRetirementDateLabel = useCallback((client: Client) => {
    if (client.alreadyRetired) {
      return "Already retired"
    }
    const retirementAge = client.retirementDate
      ? intervalToDuration({ start: parseISO(client.dob), end: new Date(client.retirementDate) })
      : { years: client.retirementAge, months: 0 }
    const diffFromNow = client.retirementDate ? intervalToDuration({ start: new Date(), end: new Date(client.retirementDate) }) : undefined
    const suffix = diffFromNow ? ` (in ${diffFromNow.years}yrs ${diffFromNow.months}mos)` : ""
    return `${`${retirementAge.years}yrs ${retirementAge.months}mos`}${suffix}`
  }, [])

  const ClientInvestmentTimeframeRenderer = ({ client }: { client?: Client }) => {
    return (
      <div className="flex gap-x-2 items-center w-full">
        <span>{title}</span>
        <div className="flex items-center gap-x-1">
          <span className="font-bold">{goal.type === "retirementIncome" && client ? getRetirementDateLabel(client) : targetDateLabel}</span>
          <button
            className="btn btn-text text-sec font-bold w-fit flex items-center justify-center align-middle p-1 text-main-500"
            aria-label="Edit investment timeframe"
            onClick={() => {
              setRetirementClient(goal.type === "retirementIncome" ? client : undefined)
              setIsInvestmentTimeframeModalOpen(true)
            }}
          >
            <img src={edit} alt="" aria-hidden />
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex">
      <div className="flex flex-col items-center gap-y-1 text-sec text-main-500">
        {goal.type === "retirementIncome" ? (
          client ? (
            <ClientInvestmentTimeframeRenderer client={client} />
          ) : (
            household?.members.map(({ client }) => client && <ClientInvestmentTimeframeRenderer client={client} key={client._id} />)
          )
        ) : (
          <ClientInvestmentTimeframeRenderer />
        )}
      </div>
      <AnimatePresence>
        {isInvestmentTimeframeModalOpen && (
          <EditInvestmentTimeframeModal
            onClose={() => setIsInvestmentTimeframeModalOpen(false)}
            client={client}
            household={household}
            retirementClient={retirementClient}
            goal={goal}
            onUpdateDone={onUpdateDone}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default InvestmentTimeframe
