import { clsx } from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import { useMemo, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import arrowIcon from "../../../assets/icons/arrowhead-right.svg"

const AdminSideBar = () => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [isAnimating, setIsAnimating] = useState(true)
  const { pathname } = useLocation()
  const isLicensee = useMemo(() => /^\/admin\/licensees?/.test(pathname), [pathname])
  const isUpdateLicensee = useMemo(() => /^\/admin\/licensee\/.+/.test(pathname), [pathname])

  const isFirm = useMemo(() => /^\/admin\/firms?/.test(pathname), [pathname])
  const isUpdateFirm = useMemo(() => /^\/admin\/firm\/.+/.test(pathname), [pathname])

  const isBilling = useMemo(() => /^\/admin\/billing?/.test(pathname), [pathname])

  const linkStyle = ({ isActive }: { isActive: boolean }) => clsx("overflow-x-hidden whitespace-nowrap w-full pl-10 pr-5 py-2 transition-all text-white border-b", isActive ? "bg-interactive-500 border-interactive-700 " : "border-interactive-500 hover:bg-interactive-500")
  const linkStyle2 = ({ isActive }: { isActive: boolean }) => clsx("overflow-x-hidden whitespace-nowrap w-full pl-12 pr-5 py-2 transition-all text-white border-b", isActive ? "bg-interactive-500 border-interactive-700 " : "border-interactive-500 hover:bg-interactive-500")
  
  return (
    <AnimatePresence> 
      <motion.div
        initial={false} //disable initial animation on page load
        animate={{ width: isExpanded ? "320px" : "76px", transition: { duration: 0.25, ease: "easeInOut" } }}
        onAnimationStart={() => setIsAnimating(isAnimating)}
        onAnimationComplete={() => setIsAnimating(!isAnimating)}
        className={clsx("client-sidebar-wrapper text-white relative h-full bg-interactive-600", isExpanded ? "w-80" : "w-20")}
      >
          <button
            className="absolute w-7 h-7 -right-2 bottom-13.5 z-10 border border-neutral-200 bg-white rounded-2 flex justify-center items-center"
            onClick={() => setIsExpanded(!isExpanded)}
            aria-label={`${isExpanded ? "Collapse" : "Expand"} admin sidebar`}
          >
            <img className={clsx("w-3 h-3", { "rotate-180": isExpanded })} src={arrowIcon} alt="" aria-hidden />
          </button>
          {<nav className="h-full flex flex-col items-start">
            <NavLink to="/admin/search" className={linkStyle}>Search</NavLink>
            <NavLink to="/admin/licensees" className={linkStyle}>Licensees</NavLink>
            {isLicensee && <NavLink to="/admin/licensees/create" className={linkStyle2}>Create</NavLink>}
            {isUpdateLicensee && <NavLink to={pathname} className={() => linkStyle2({ isActive: isUpdateLicensee })}>Manage</NavLink>}
            {/* { licenseeId && <div className="pl-12">{licenseeId}</div>} */}
            <NavLink to="/admin/firms" className={linkStyle}>Firms</NavLink>
            {isFirm && <NavLink to="/admin/firms/create" className={linkStyle2}>Create</NavLink>}
            {isUpdateFirm && <NavLink to={pathname} className={linkStyle2({ isActive: true })}>Manage firm</NavLink>}
            <NavLink to="/admin/users" className={linkStyle}>Users</NavLink>
            <NavLink to="/admin/activities" className={linkStyle}>Activities</NavLink>
            <NavLink to="/admin/analytics" className={linkStyle}>Analytics</NavLink>
            <NavLink to="/admin/billing/codes" className={linkStyle}>Billing</NavLink>
            {isBilling && <NavLink to="/admin/billing/codes" className={linkStyle2}>Codes</NavLink>}
            <NavLink to="/admin/kafka" className={linkStyle}>Kafka</NavLink>
          </nav>}
          
      </motion.div>
    </AnimatePresence>
  )
}

export default AdminSideBar
