import { useMemo, useRef, useState } from "react"
import AlertIcon from "../../../../assets/icons/AlertIcon"
import RMJTextTooltip from "../../../rmjourney/goalExplorer/components/RMJTextTooltip/RMJTextTooltip"

const InconsistentDecisionsWarning = ({ type, names }: { type: "client" | "household"; names: string[] }) => {
  const warningTooltipRef = useRef<HTMLDivElement>(null)
  const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false)
  const warningTooltipPos = warningTooltipRef.current?.getBoundingClientRect()
  const offSetY = -20

  const label = useMemo(() => (type === "client" ? "Decision check" : `Decision check for ${names.join(" and ")}`), [names, type])

  return (
    <div ref={warningTooltipRef}>
      <div
        className="flex items-center justify-center cursor-pointer bg-red-200 px-2 py-1 w-fit decision-check-warning"
        onMouseOver={() => setShowWarningAlert(true)}
        onMouseOut={() => setShowWarningAlert(false)}
      >
        <AlertIcon sizeProp="sm" />
        <span className="text-xs text-red-600 font-bold tracking-extrawide pl-1 uppercase">{label}</span>
      </div>
      {showWarningAlert && warningTooltipPos && (
        <div
          className="fixed w-[375px] cursor-default z-50 decision-check-warning-message"
          style={{
            top: `${warningTooltipPos.top - offSetY}px`,
            left: `${warningTooltipPos.left}px`
          }}
        >
          <RMJTextTooltip>
            <p className="text-sec leading-3">
              {`${names.join(
                " and "
              )}'s decisions were inconsistent. Please consider re-profiling them before you present their results to ensure that you get a clear read of their risk preferences.`}
            </p>
          </RMJTextTooltip>
        </div>
      )}
    </div>
  )
}

export default InconsistentDecisionsWarning
