import { Route, Routes, useNavigate } from "react-router-dom"

const RMJourneyNavbar = () => {
  const navigate = useNavigate()

  return (
    <nav className="w-full">
      <div className="w-full flex justify-start pt-4">
        <button
          className="ml-5 xl:ml-12 w-[30px] h-[30px] rounded-full bg-white hover:bg-interactive-100 focus:shadow-focus"
          aria-label="Back"
          onClick={() => {
            navigate(-1)
          }}
        >
          &larr;
        </button>
      </div>
      <div className="mx-6">
        <div className="w-full flex justify-center">
          <div className="relative w-full flex flex-col mt-4 -mb-4 max-w-screen-lg-fluid-max px-6 justify-start">
            <div className="w-full grid grid-cols-7">
              <div className="col-span-7 col-start-1 bg-surface-300 h-1" />
            </div>
            <div className="w-full grid grid-cols-14 -mt-1">
              <Routes>
                <Route path="advice-overview" element={<div className="col-start-1 col-span-3 bg-highlight-600 h-1" />} />
                <Route path="your-decisions" element={<div className="col-start-1 col-span-5 bg-highlight-600 h-1" />} />
                <Route path="risk-attitude" element={<div className="col-start-1 col-span-7 bg-highlight-600 h-1" />} />
                <Route path="loss-sensitivity" element={<div className="col-start-1 col-span-9 bg-highlight-600 h-1" />} />
                <Route path="portfolio-comfort" element={<div className="col-start-1 col-span-11 bg-highlight-600 h-1" />} />
                <Route path="goal-explorer" element={<div className="col-start-1 col-span-13 bg-highlight-600 h-1" />} />
              </Routes>
            </div>
            <div className="w-full grid grid-cols-7 text-sm text-main-600">
              <div className="col-span-1 text-center py-2">Overview</div>
              <div className="col-span-1 text-center py-2">Risk dimensions</div>
              <div className="col-span-1 text-center py-2">Your decisions</div>
              <div className="col-span-1 text-center py-2">Attitude to Risk</div>
              <div className="col-span-1 text-center py-2">Sensitivity to Loss</div>
              <div className="col-span-1 text-center py-2">Risk Comfort</div>
              <div className="col-span-1 text-center py-2">Goal Projector</div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default RMJourneyNavbar
