import { useContext, useMemo } from "react"
import { FirmContext } from "../../../../contexts/FirmContext"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import riskDimensions from "../../../rmjourney/adviceOverview/assets/risk-dimensions-beige.png"
import Container from "../components/Container"
import Footer from "../components/Footer"
import { latest } from "../../../../lib/clients"
import checkMark from "../assets/checkmark.svg"
import { ActionItemLabels } from "../../../../models/ActionItem"
import { Trans } from "@lingui/macro"
import { useSearchParams } from "react-router-dom"

const RiskDimensionsPage = ({
  page,
  client,
  household,
  hasNoCurrentPorfolioAndGoals
}: {
  page: number
  client: Client
  household: Household
  hasNoCurrentPorfolioAndGoals?: boolean
}) => {
  const { firm } = useContext(FirmContext)
  const clientOrHousehold = client ?? household
  const game = latest(clientOrHousehold, "risk")
  const [par] = useSearchParams()
  const nextMeetingMonths = par.get("nextMeetingMonths")
  const actionItems = useMemo(
    () =>
      (game?.actionItems ?? [])?.filter(
        (item) =>
          item.value &&
          item.key !== "comfortable-with-investment-risk" &&
          item.key !== "increase-goal-achieving-likelihood" &&
          item.key !== "increase-goal-achieving-likelihood-of-investment-drawdown"
      ),
    [game?.actionItems]
  )

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="flex-auto mx-10 text-sm">
        <h2 className="mb-2 text-main-600 tracking-wide uppercase">Planning dimensions</h2>
        <h3 className="text-main-600 font-semibold text-p">There are three dimensions of risk that will shape your financial strategy</h3>
        <p className="mt-8 leading-tight text-main-600">
          Our planning process balances these three factors to come to a recommendation that offers the best path toward achieving your lifestyle goals.
        </p>
        <div className="flex flex-col h-auto gap-6 mt-4">
          <div className="flex flex-col gap-y-6">
            <div className="text-main-600">
              <h4 className="font-bold">Goals</h4>
              <p className="mt-2 leading-tight">Your goals are central to your investment strategy and will guide the risk required to meet your goals.</p>
            </div>
            <div className="text-main-600">
              <h4 className="font-bold">Preferences</h4>
              <p className="mt-2 leading-tight">Your risk preferences are important as they determine how comfortable you’ll be with our investment options.</p>
            </div>
            <div className="text-main-600">
              <h4 className="font-bold">Constraints</h4>
              <p className="mt-2 leading-tight">
                Your constraints may include your income, timeframe, debt levels, and other factors that may influence your capacity to take risk.
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center grow w-1/2 m-auto">
            <img src={riskDimensions} alt="Risk dimensions" aria-hidden />
          </div>
        </div>
      </div>

      <div className="relative bg-surface-100">
        {hasNoCurrentPorfolioAndGoals && (
          <div className="min-h-[330px] items-end">
            <div className="flex flex-col w-full px-10 mt-10">
              <h2 className="mb-1 text-main-600 text-sm tracking-wide uppercase">Next Steps</h2>
              <h3 className="font-semibold text-main-600 text-p">
                <Trans id="reports-risk-profile-page-actions-header">Your adviser will progress the following actions to support your experience</Trans>
              </h3>
            </div>
            <ul className="mb-2 mx-10 mt-8 max-h-36 flex flex-col flex-wrap gap-2.5 items-start text-sm">
              {nextMeetingMonths && (
                <li className="flex items-center justify-between text-main-500 hover:bg-interactive-100 p-1" key="next-meeting">
                  <div className="flex">
                    <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                    Meet again in {nextMeetingMonths} months
                  </div>
                </li>
              )}
              {actionItems.map((item, i) => (
                <li className="flex items-center justify-between text-main-500 font-normal hover:bg-interactive-100 p-1" key={`${item.key}-${i}`}>
                  <div className="flex items-center">
                    <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                    {item.key === "freetext" ? item.value : ActionItemLabels[item.key]}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        <Footer className="px-10" pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default RiskDimensionsPage
