import { AxiosError } from "axios"
import clsx from "clsx"
import { AnimatePresence } from "framer-motion"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { useLocation, useNavigate, useParams } from "react-router"
import infoAlert from "../../../assets/icons/info-alert-dark.svg"
import RadioButton from "../../../components/RadioButton/RadioButton"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../contexts/FirmContext"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import { Client, GoalDetail } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { GoalPrioritiesOrder } from "../../advisor/Results/Goals/GoalsTab"
import arrowLeft from "../assets/images/arrow-left.svg"
import RMJFinishModal from "../components/RMJFinishModal/RMJFinishModal"
import SidebarHeader from "../components/SidebarHeader/SidebarHeader"
import RMJInvestmentGoalModal from "./RMJInvestmentGoalModal/RMJInvestmentGoalModal"
import menuIcon from "../goalExplorer/assets/menu.svg"
import RMJPortfolioComfortMoreInfoModal from "./RMJPortfolioComfortMoreInfoModal/RMJPortfolioComfortMoreInfoModal"
import { latest } from "../../../lib/clients"
import { portfolioComfortMatch } from "./comfortMatch"
import { FEATURE_TIMEFRAME_MAPPING_IN_IM } from "../../../config/features"

const RMJPortfolioComfortSideBar = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const clientOrHousehold = useMemo(() => client ?? household, [client, household])
  const { firm } = useContext(FirmContext)
  const navigate = useNavigate()
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false)
  const [isInvestmentGoalModalOpen, setIsInvestmentGoalModalOpen] = useState(false)
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false)
  const [portfolio, setPortfolio] = useState<string | null>()
  const [portfolioError, setPortfolioError] = useState<boolean>()

  const location = useLocation()
  const currentState = location.state || {}
  const { goal: goalType, id: goalId, shouldValidate } = currentState || {}

  const game = latest(clientOrHousehold!, "risk")
  const comfortMatch = useMemo(() => portfolioComfortMatch(clientOrHousehold), [clientOrHousehold])

  const sortedGoalDetails = useMemo(
    () =>
      [...(clientOrHousehold?.goals?.goalDetails ?? [])].sort((a, b) => {
        const priorityA = a.priority ? GoalPrioritiesOrder.indexOf(a.priority) : Infinity
        const priorityB = b.priority ? GoalPrioritiesOrder.indexOf(b.priority) : Infinity
        return priorityA - priorityB
      }),
    [clientOrHousehold?.goals?.goalDetails]
  )

  const goal = useMemo(
    () =>
      FEATURE_TIMEFRAME_MAPPING_IN_IM
        ? goalType
          ? clientOrHousehold?.goals?.goalDetails?.find((goal) => goal.type === goalType && goal.id === (goalId ?? ""))
          : sortedGoalDetails?.[0]
        : undefined,
    [clientOrHousehold?.goals?.goalDetails, goalId, goalType, sortedGoalDetails]
  )

  useEffect(() => setPortfolio(goal ? goal.selectedPortfolio : clientOrHousehold?.currentPortfolio), [clientOrHousehold?.currentPortfolio, goal])

  const { mutate: update, status } = useMutation<
    Client | Household | null,
    AxiosError<{ message?: string }>,
    { goalDetails?: GoalDetail[]; currentPortfolio?: string | null }
  >({
    mutationFn: ({ goalDetails, currentPortfolio }: { goalDetails?: GoalDetail[]; currentPortfolio?: string | null }) => {
      const req = {
        ...(currentPortfolio !== undefined ? { currentPortfolio: currentPortfolio } : {}),
        ...(goalDetails ? { goals: { goalDetails } } : {})
      }
      return household ? updateHousehold(household!._id!, [], req) : updateClient(client!._id, req)
    }
  })

  const onChange = (selectedPortfolio: string | null) => {
    setPortfolio(selectedPortfolio)
    if (goal) {
      const updatedGoals = clientOrHousehold?.goals?.goalDetails?.map((goalDetail) => {
        if (goalDetail.type === goal?.type && goalDetail.id === goal.id) {
          return {
            ...goalDetail,
            selectedPortfolio
          }
        } else {
          return goalDetail
        }
      })
      update({ goalDetails: updatedGoals! })
    }
  }

  const allPortfoliosSelected = useMemo(() => {
    return (
      (FEATURE_TIMEFRAME_MAPPING_IN_IM
        ? clientOrHousehold?.goals?.goalDetails?.every((goalDetail) => goalDetail.selectedPortfolio !== undefined) ?? true
        : true) && portfolio !== undefined
    )
  }, [clientOrHousehold?.goals?.goalDetails, portfolio])

  const validate = useCallback(() => {
    setPortfolioError(portfolio === undefined)
  }, [portfolio])

  useEffect(() => {
    if (shouldValidate) {
      validate()
    }
  }, [shouldValidate, validate])

  return (
    <div className="w-80 h-full bg-interactive-600 flex flex-1 flex-col justify-between">
      {(client || household) && (
        <>
          <div className="gap-x-2.5 overflow-y-auto no-scrollbar">
            <div className="flex flex-col">
              {client && <SidebarHeader client={client} />}
              {household && <SidebarHeader household={household} />}
              <div className="sidebar-section">
                <h3 className="sidebar-section-title">Risk Comfort</h3>
                <p className="sidebar-section-text text-sec">
                  Your Risk Comfort expresses how comfortable you’d be in each of our investment options, given the risk and return expectations and your
                  investment timeframe.
                </p>
              </div>
              <div className="sidebar-section text-white">
                <fieldset>
                  <legend className="sidebar-section-title mb-2">
                    Select investment risk level and either, finish meeting or launch Goal Projector
                  </legend>
                  <RadioButton
                    checked={portfolio === null}
                    id="no-investment"
                    invertColors={true}
                    label={
                      <div className="flex flex-row items-center text-sec">
                        <div className={`mr-2 ${shouldValidate && portfolio === undefined ? "text-negative-600" : "text-white"}`}>No selected investment</div>
                      </div>
                    }
                    isDark={true}
                    error={shouldValidate && portfolio === undefined}
                    name="no-investment"
                    onChange={() => onChange(null)}
                    className="mb-2 text-white items-center"
                  />
                  {firm?.modelPortfolios?.map((modelPortfolio) => (
                    <RadioButton
                      key={modelPortfolio.id}
                      checked={portfolio === modelPortfolio.id}
                      id={modelPortfolio.id}
                      invertColors={true}
                      label={
                        <div className="flex flex-row items-center text-sec">
                          <div className={clsx("mr-2", { "text-negative-600": portfolioError, "text-white": !portfolioError })}>{modelPortfolio.name}</div>
                          <div className="flex flex-col">
                            {clientOrHousehold?.currentPortfolio === modelPortfolio.id && (
                              <div className="flex flex-row items-center">
                                <div className="rounded-full h-1 w-1 mr-2 bg-alt-link-600"></div>
                                <div className="text-xs text-alt-link-600 uppercase">Current investment</div>
                              </div>
                            )}
                          </div>
                        </div>
                      }
                      isDark={true}
                      error={portfolioError}
                      name="portfolio"
                      value={modelPortfolio.name}
                      onChange={() => onChange(modelPortfolio.id)}
                      className="mb-2 text-white items-center"
                    />
                  ))}
                </fieldset>

                {portfolioError && (
                  <div className="flex items-center mt-1">
                    <img alt="Info alert" className="mr-1" src={infoAlert} />
                    <p className="text-sm text-negative-600">Please select an investment risk</p>
                  </div>
                )}
                <button
                  className="btn btn-text btn-small btn-secondary btn-alt-text text-white text-xs px-3 flex gap-x-1 items-center"
                  onClick={() => setIsMoreInfoModalOpen(true)}
                >
                  <img src={menuIcon} aria-hidden alt="" />
                  View strategic asset allocation
                </button>
              </div>
            </div>
          </div>
          <div className="px-4 pb-4 w-full flex">
            <button
              onClick={() => navigate(`/${client ? "clients" : "households"}/${clientOrHousehold?._id}/rm/loss-sensitivity`)}
              className="cursor-pointer w-10"
            >
              <img className="mt-8" src={arrowLeft} alt="" aria-label="Back" />
            </button>
            <div className="flex flex-col items-center grow">
              <button
                className="btn btn-medium btn-primary-alt mt-4 w-full h-14"
                type="button"
                disabled={status === "loading"}
                onClick={() => {
                  navigate(`/${client ? "clients" : "households"}/${clientOrHousehold?._id}/rm/goal-explorer`)
                }}
              >
                Launch Goal Projector
              </button>
              <button
                className="mt-3 w-full py-1 border border-alt-interactive-300"
                type="button"
                disabled={status === "loading"}
                onClick={() => {
                  navigate("", { state: { ...currentState, shouldValidate: true } })
                  if (allPortfoliosSelected) {
                    setIsFinishModalOpen(true)
                  }
                }}
              >
                <span className="text-sec text-alt-interactive-300 font-bold">Finish meeting</span>
              </button>
            </div>
          </div>
        </>
      )}
      <AnimatePresence>
        {isFinishModalOpen && <RMJFinishModal portfolio={portfolio} onClose={() => setIsFinishModalOpen(false)} />}
        {isInvestmentGoalModalOpen && <RMJInvestmentGoalModal client={client} household={household} onClose={() => setIsInvestmentGoalModalOpen(false)} />}
        {isMoreInfoModalOpen && (
          <RMJPortfolioComfortMoreInfoModal
            assetClasses={game?.assetClasses}
            comfortMatch={comfortMatch}
            onClose={() => setIsMoreInfoModalOpen(false)}
            portfolioMappings={game?.portfolioMappings}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default RMJPortfolioComfortSideBar
