import { Trans } from "@lingui/macro"
import { useContext, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import PercentageBar from "../../../../components/PercentageBar/PercentageBar"
import { FirmContext } from "../../../../contexts/FirmContext"
import { latest } from "../../../../lib/clients"
import { tt } from "../../../../lib/translations"
import { ActionItemLabels } from "../../../../models/ActionItem"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import PortfolioDetail from "../../components/PortfolioDetail"
import insightIcon from "../assets/insight-icon.svg"
import { allAssetClasses, assetTypeWeights } from "../ComfortMatchPage"
import Container from "../components/Container"
import Footer from "../components/Footer"
import checkMark from "./assets/checkmark.svg"
import footerImage from "./assets/footer.svg"
import { portfolioModelSecondaryText } from "./assets/profileTypes"
import { sortedGoalDetails } from "../InvestmentMeetingReport"
import { getGoalTitle } from "../../../advisor/Results/Goals/components/CardTitle"
import useAdvisorSearch from "../../../../hooks/useAdvisorSearch"
import { format } from "date-fns"
import clsx from "clsx"
import { useTheme } from "../../../../contexts/ThemeContext"

const RiskProfilePage = ({
  page,
  client,
  household,
  isPostMeeting = false
}: {
  page: number
  client: Client
  household: Household
  isPostMeeting?: boolean
}) => {
  const [par] = useSearchParams()
  const nextMeetingMonths = par.get("nextMeetingMonths")
  const clientOrHousehold = client ?? household
  const game = latest(clientOrHousehold, "risk")
  const portfolioMappings = game?.portfolioMappings
  const riskProfileMatch = [...(portfolioMappings || [])].sort((a, b) => b.riskComfort! - a.riskComfort!)
  const { firm } = useContext(FirmContext)
  const { data } = useAdvisorSearch()
  const theme = useTheme()

  const assetClasses = allAssetClasses(game?.assetClasses ?? []) || {}

  const assetWeights = assetTypeWeights(riskProfileMatch[0].portfolio.components, assetClasses).filter(({ assetClass }) => assetClass === "growth")

  const comfortMatchText = portfolioModelSecondaryText(riskProfileMatch[0].portfolio.name, assetWeights?.[0]?.total ?? 0)

  const currentPortfolio = useMemo(() => {
    if (clientOrHousehold.goals?.goalDetails && clientOrHousehold.goals?.goalDetails.length > 0) {
      const goal = sortedGoalDetails(clientOrHousehold.goals.goalDetails)[0]
      return firm?.modelPortfolios?.find((modelPortfolio) => modelPortfolio.id === goal.selectedPortfolio)
    } else {
      return firm?.modelPortfolios?.find((modelPortfolio) => modelPortfolio.id === clientOrHousehold.currentPortfolio)
    }
  }, [clientOrHousehold.currentPortfolio, clientOrHousehold.goals?.goalDetails, firm?.modelPortfolios])
  const currentPortfolioScore = game?.portfolioMappings?.find((val) => val.portfolio.id === currentPortfolio?.id)

  const actionItems = useMemo(
    () =>
      [...(game?.actionItems ?? [])]?.filter(
        (item) =>
          item.value &&
          item.key !== "comfortable-with-investment-risk" &&
          item.key !== "increase-goal-achieving-likelihood" &&
          item.key !== "increase-goal-achieving-likelihood-of-investment-drawdown"
      ),
    [game?.actionItems]
  )

  const advisor = data?.find((d) => d._id === clientOrHousehold.advisorId)
  const sortedGoal = sortedGoalDetails(clientOrHousehold?.goals?.goalDetails ?? [])
  const selectedGoalsPortfolios = useMemo(
    () =>
      sortedGoal
        .map((goal) => firm?.modelPortfolios?.find((modelPortfolio) => modelPortfolio.id === goal.selectedPortfolio))
        .filter((portfolio) => !!portfolio),
    [firm?.modelPortfolios, sortedGoal]
  )

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="flex-auto mx-10">
        <h2 className="mb-1 text-main-600 text-sm tracking-wide uppercase">Session summary</h2>
        <h3 className="font-semibold text-main-600 text-p">
          {isPostMeeting &&
            (!clientOrHousehold?.goals?.goalDetails || clientOrHousehold?.goals?.goalDetails?.length === 0
              ? `We’ll consider a ${currentPortfolio?.name} risk level in our planning process`
              : clientOrHousehold?.goals?.goalDetails?.length === 1
              ? clientOrHousehold?.goals?.goalDetails?.map((g) => {
                  const title = getGoalTitle({
                    goalDetails: clientOrHousehold?.goals?.goalDetails ?? [],
                    goal: g
                  })
                  return `We’ll consider a ${currentPortfolio?.name} risk level for your ${
                    title || tt({ id: `report-profile-page-goal-${g.type}-title` })
                  } goal`
                })
              : "We’ll consider the following risk levels in our planning process")}
        </h3>
        {!isPostMeeting && (household || client) && riskProfileMatch && (
          <div className="risk-profile-summary bg-surface-100 w-full mt-6 mb-8 p-8 gap-x-4 flex justify-between items-center">
            <img src={insightIcon} alt="" className="w-16 h-16" aria-hidden />
            <div className="text-sec text-main-500 leading-tight">
              {comfortMatchText} When we meet we will review your preferences and determine together what risk level is right for you.
            </div>
          </div>
        )}
        {clientOrHousehold.goals?.goalDetails && clientOrHousehold.goals?.goalDetails?.length > 1 && (
          <div role="table">
            <div className="grid grid-cols-10 mt-8 mb-4.5 text-sm font-semibold gap-8" role="row">
              <p role="columnheader" className="font-semibold col-span-3">
                Goals
              </p>
              <p role="columnheader" className="font-semibold col-span-2">
                Target date
              </p>
              <p role="columnheader" className="font-semibold col-span-3">
                Investment risk level
              </p>
              <p role="columnheader" className="font-semibold col-span-2 text-center">
                Risk comfort
              </p>
            </div>
            <div className="mb-5 text-sm" role="rowgroup">
              {(clientOrHousehold?.goals?.goalDetails?.length ?? 0) > 1 &&
                sortedGoal?.map((g, i) => {
                  const currentPortfolioScore = game?.portfolioMappings?.find((val) => val.portfolio.id === selectedGoalsPortfolios?.[i]?.id)
                  const title = getGoalTitle({
                    goalDetails: clientOrHousehold?.goals?.goalDetails ?? [],
                    goal: g
                  })
                  return (
                    <div key={i} className="grid grid-cols-10 border-b border-b-surface-300 gap-10" role="row">
                      <p role="cell" className="py-4 col-span-3 w-max flex gap-x-2 items-center">
                        <span>
                          <img src={theme.goals.find((goal) => g.type === goal.type)?.reportIcon} />
                        </span>
                        {title ?? tt({ id: `report-profile-page-goal-${g.type}-title` })}
                      </p>
                      <p role="cell" className="py-4 col-span-2 my-auto">
                        {format(new Date(g.targetDate?.value ?? new Date()), "dd/MM/yyyy")}
                      </p>
                      <p role="cell" className="py-4 col-span-3 w-max my-auto">
                        {selectedGoalsPortfolios?.[i]?.name ?? "No selected investment"}
                      </p>
                      <p role="cell" className="py-4 col-span-2 text-center my-auto">
                        {currentPortfolioScore?.riskComfort ?? "-"}
                      </p>
                    </div>
                  )
                })}
            </div>
          </div>
        )}

        {isPostMeeting && (clientOrHousehold?.goals?.goalDetails?.length ?? 0) <= 1 && (
          <div className="bg-surface-100 w-full mt-6 mb-8 p-6 gap-x-4 flex flex-row">
            <div className="w-auto mr-6">{currentPortfolio && <PortfolioDetail portfolio={currentPortfolio} forReport={true} />}</div>
            <div className="w-56 pb-3 pl-6 border-l border-surface-300">
              <h4 className="font-semibold text-main-600 text-sm">Alignment score</h4>
              <PercentageBar
                forReport={true}
                percentage={currentPortfolioScore?.riskComfort ?? 0}
                label={`Risk Comfort ${currentPortfolioScore?.riskComfort ?? 0}%`}
              />
            </div>
          </div>
        )}
        <p className="text-sm">
          {isPostMeeting
            ? "Thank you for your time today. We appreciate the opportunity to discuss your goals and investment risk comfort. We look forward to continuing our work together."
            : "When we meet, we’ll review your preferences and determine together what risk level is right for you."}
        </p>
        <p aria-hidden className="advisor-signature text-h2 my-6 font-[aguafina]">
          {advisor?.firstName} {advisor?.lastName}
        </p>
        <p className="flex flex-col text-sm">
          {advisor?.firstName} {advisor?.lastName}
          <span>{firm?.name}</span>
        </p>
      </div>
      {isPostMeeting && (clientOrHousehold.goals?.goalDetails?.length === 1 || clientOrHousehold.currentPortfolio) && (
        <div className="w-full min-h-60 text-main-600 flex flex-col">
          <div className="flex flex-col w-full px-10 mt-10">
            <h2 className="mb-1 text-main-600 text-sm tracking-wide uppercase">Next Steps</h2>
            <h3 className="font-semibold text-main-600 text-p">
              <Trans id="reports-risk-profile-page-actions-header">Your adviser will progress the following actions to support your experience</Trans>
            </h3>
          </div>
          <div className="flex px-10 mt-8 max-h-36">
            <div className="flex flex-row justify-between w-full text-sec gap-x-4 text-main-500">
              <ul className="mb-2 max-h-36 flex flex-col flex-wrap gap-x-6 items-start text-sm">
                {nextMeetingMonths && (
                  <li className="flex items-center justify-between text-main-500 hover:bg-interactive-100 p-1" key="next-meeting">
                    <div className="flex">
                      <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                      Meet again in {nextMeetingMonths} months
                    </div>
                  </li>
                )}
                {actionItems.map((item, i) => (
                  <li className="flex items-center justify-between text-main-500 font-normal hover:bg-interactive-100 p-1" key={`${item.key}-${i}`}>
                    <div className="flex items-center">
                      <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                      {item.key === "freetext" ? item.value : ActionItemLabels[item.key]}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}

      <div
        className={clsx("w-full h-[330px]", (clientOrHousehold.goals?.goalDetails?.length ?? 0) > 1 && "bg-surface-100")}
        style={{ backgroundImage: `url(${(clientOrHousehold.goals?.goalDetails?.length ?? 0) > 1 ? "" : footerImage})` }}
      >
        {clientOrHousehold.goals?.goalDetails && clientOrHousehold.goals?.goalDetails?.length > 1 ? (
          <div className="flex flex-col justify-between h-full">
            <div className="w-full min-h-60 text-main-600 flex flex-col">
              <div className="flex flex-col w-full px-10 mt-10">
                <h2 className="mb-1 text-main-600 text-sm tracking-wide uppercase">Next Steps</h2>
                <h3 className="font-semibold text-main-600 text-p">
                  <Trans id="reports-risk-profile-page-actions-header">Your adviser will progress the following actions to support your experience</Trans>
                </h3>
              </div>
              <div className="flex px-10 mt-8 max-h-36">
                <div className="flex flex-row justify-between w-full text-sec gap-x-4 text-main-500">
                  <ul className="mb-2 max-h-36 flex flex-col flex-wrap gap-x-6 items-start text-sm">
                    {nextMeetingMonths && (
                      <li className="flex items-center justify-between text-main-500 hover:bg-interactive-100 p-1" key="next-meeting">
                        <div className="flex">
                          <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                          Meet again in {nextMeetingMonths} months
                        </div>
                      </li>
                    )}
                    {actionItems.map((item, i) => (
                      <li className="flex items-center justify-between text-main-500 font-normal hover:bg-interactive-100 p-1" key={`${item.key}-${i}`}>
                        <div className="flex items-center">
                          <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                          {item.key === "freetext" ? item.value : ActionItemLabels[item.key]}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mx-9 flex items-end">
              <Footer
                pageNum={page}
                client={client}
                household={household}
                firm={firm}
                className="relative"
                textClassName="text-surface-500"
                borderClassName="border-t-surface-500"
              />
            </div>
          </div>
        ) : (
          <div className="mx-9 h-full flex items-end">
            <Footer
              pageNum={page}
              client={client}
              household={household}
              firm={firm}
              className="relative"
              textClassName="text-surface-100"
              borderClassName="border-t-surface-100"
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export default RiskProfilePage
