import { ReactNode, useContext, useMemo } from "react"
import { FirmContext } from "../../../contexts/FirmContext"
import { AssetClass, SubAssetClass } from "../../../models/InvestmentUniverse"
import { ModelPortfolio } from "../../../models/PortfolioModel"
import { useTheme } from "../../../contexts/ThemeContext"
import { DoughnutChart } from "../../rmjourney/components/DoughnutChart/DoughnutChart"
import { ASSET_TYPES } from "../../../config/assetClasses"
import heart from "../../advisor/assets/images/heart.svg"
import chevron from "../../../assets/icons/chevron-right.svg"

const PortfolioDetail = ({
  handleClick,
  portfolio,
  riskComfort,
  forReport = false,
  showBreakdown = true,
  showPortfolioDescription = false,
  title
}: {
  handleClick?: () => void
  portfolio?: ModelPortfolio
  riskComfort?: number
  forReport?: boolean
  showBreakdown?: boolean
  showPortfolioDescription?: boolean
  title?: ReactNode | string
}) => {
  const { firm } = useContext(FirmContext)
  const theme = useTheme()

  const assetTypeWeights =
    useMemo(() => {
      if (!portfolio) return []
      const allAssetClasses = firm?.assetClasses
        .flatMap((ac) => [ac, ...(ac.subAssetClasses || []).map((sac) => ({ ...sac, type: sac.type ?? ac.type }))] as (AssetClass | SubAssetClass)[])
        .reduce(
          (acc, cur) => ({
            ...acc,
            [cur.id!]: cur
          }),
          {} as { [key: string]: AssetClass | SubAssetClass }
        )
      return ASSET_TYPES.map((ac, jj) => ({
        assetClass: ac,
        colorIndex: jj,
        total: Math.round(portfolio.components.filter((c) => allAssetClasses![c.id].type === ac).reduce((sum, x) => sum + x.weight, 0) * 100) / 100
      })).filter(({ total }) => total > 0)
    }, [firm?.assetClasses, portfolio]) || {}

  return (
    <div className="flex items-center gap-10">
      <div className="flex gap-3 items-center">
        <div className="w-14">
          <DoughnutChart
            forReport={forReport}
            data={assetTypeWeights.map(({ total }) => total)}
            colors={assetTypeWeights.map(({ assetClass }) => theme.colors.assetClassConfigurations[assetClass].color)}
            strokeWidth={7}
          />
        </div>
        <div className="flex flex-col gap-1">
          <h4 className="text-main-600 font-semibold text-sm">{title ?? `${portfolio?.name} investment`}</h4>
          {showPortfolioDescription && (
            <div className="flex items-center gap-1">
              <p className="text-sec">{portfolio?.name ?? "No selected investment"}</p>
              <button aria-label="Show asset allocation" className="btn btn-text p-1" onClick={handleClick}>
                <img src={chevron} alt="" aria-hidden />
              </button>
            </div>
          )}
          {showBreakdown && (
            <div className="w-full flex flex-col gap-1">
              {assetTypeWeights.map(({ assetClass, total }, kk) => (
                <div className="flex flex-row justify-start gap-1" key={kk}>
                  <div className="rounded-full h-1.5 w-1.5 my-auto" style={{ backgroundColor: theme.colors.assetClassConfigurations[assetClass].color }}></div>
                  <label className="text-sm">
                    {total}% {theme.colors.assetClassConfigurations[assetClass].label}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {riskComfort !== undefined && (
        <div className="flex flex-col gap-1">
          <p className="text-sec leading-4 font-semibold">Risk comfort</p>
          <div className="flex gap-2 items-center">
            <div className="w-52 h-1.5 bg-surface-activity">
              <div className="h-full bg-riskComfort-400" style={{ width: `${riskComfort}%` }} />
            </div>
            <div className="flex gap-0.5">
              <img src={heart} alt="" aria-hidden />
              <p className="text-sec font-semibold">{riskComfort}%</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PortfolioDetail
