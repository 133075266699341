import { add, intervalToDuration, parseISO } from "date-fns"
import { FEATURE_ALREADY_RETIRED } from "../../config/features"
import { Client } from "../../models/Client"
import Checkbox from "../Checkbox/Checkbox"
import DatePicker from "../DatePicker/DatePicker"
import ErrorMessage from "../Error/ErrorMessage"
import NumberInput from "../NumberInput/NumberInput"
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch"
import birthdayIconDisabled from "./birthday-icon-disabled.svg"
import birthdayIcon from "./birthday-icon.svg"
import calendarDisabled from "./calendar-icon-disabled.svg"
import calendar from "./calendar-icon.svg"

const options = [
  {
    label: "datePicker",
    value: "datePicker",
    altName: "Date picker",
    icons: {
      active: calendar,
      inactive: calendarDisabled
    }
  },
  {
    label: "ageInput",
    value: "ageInput",
    altName: "Age input",
    icons: {
      active: birthdayIcon,
      inactive: birthdayIconDisabled
    }
  }
]

const RetirementAgeInput = ({
  client,
  alreadyRetired,
  retirementAge,
  retirementDate,
  onChange,
  inputType,
  onToggleChange,
  errors
}: {
  client: Client
  alreadyRetired?: boolean
  retirementAge?: { years?: number; months?: number }
  retirementDate?: Date | null
  onChange: (data: { retirementAge?: { years?: number; months?: number }; retirementDate?: Date | null }) => void
  inputType: "datePicker" | "ageInput"
  onToggleChange: (inputType: "datePicker" | "ageInput") => void
  errors?: { targetDate?: string; retirementAgeYears?: string; retirementAgeMonths?: string }
}) => {
  return (
    <div className="goals-container-content">
      <div className="target-date-container mt-6 relative">
        <div className="absolute right-0 -top-10 bg-surface-200 h-9 w-16 flex justify-center items-center">
          <ToggleSwitch
            className="w-full h-full p-1"
            onToggle={() => {
              onToggleChange(inputType === "ageInput" ? "datePicker" : "ageInput")
            }}
            selectedValue={inputType}
            options={options}
          />
        </div>
        {inputType === "datePicker" ? (
          <DatePicker
            id="retirement-date"
            yearsInPast={0}
            yearsInAdvance={125}
            onChange={(value) => {
              const duration = client.dob && value ? intervalToDuration({ start: parseISO(client.dob), end: value }) : undefined
              onChange({
                retirementAge: { years: duration?.years, months: duration?.months },
                retirementDate: value
              })
            }}
            onChangeRaw={() => {}}
            value={retirementDate ?? null}
            error={errors?.targetDate}
          />
        ) : (
          <div className="flex flex-row gap-x-4 mb-6">
            <div>
              <NumberInput
                className="!flex-shrink"
                disableAutoComplete
                isDisabled={alreadyRetired}
                name="retirementAge"
                value={retirementAge?.years}
                suffix={<span className="text-sec text-main-400">years</span>}
                aria-label="Retirement age in years"
                onChange={(value) => {
                  onChange({
                    retirementAge: {
                      ...retirementAge,
                      years: value
                    },
                    retirementDate: value ? (client?.dob ? add(new Date(client.dob), { years: value ?? 0, months: retirementAge?.months ?? 0 }) : null) : null
                  })
                }}
              />
              <div role="alert">{errors?.retirementAgeYears && <ErrorMessage id="retirement-age-years" message={errors.retirementAgeYears} />}</div>
            </div>
            <div>
              <NumberInput
                className="!flex-shrink"
                disableAutoComplete
                isDisabled={alreadyRetired}
                name="retirementAgeMonths"
                value={retirementAge?.months}
                suffix={<span className="text-sec text-main-400">months</span>}
                aria-label="Retirement age in months"
                onChange={(value) => {
                  onChange({
                    retirementAge: {
                      ...retirementAge,
                      months: value
                    },
                    retirementDate: client?.dob ? add(new Date(client.dob), { years: retirementAge?.years ?? 0, months: value ?? 0 }) : null
                  })
                }}
              />
              <div role="alert">{errors?.retirementAgeMonths && <ErrorMessage id="retirement-age-months" message={errors.retirementAgeMonths} />}</div>
            </div>
          </div>
        )}
        {FEATURE_ALREADY_RETIRED && (
          <Checkbox className="mt-6" name="target-date-input-not-applicable" onChange={() => {}} value="" checked={false} label="Already retired" />
        )}
      </div>
    </div>
  )
}

export default RetirementAgeInput
