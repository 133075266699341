/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useMemo, useState } from "react"
import { shortRound } from "../../../../../lib/numbers"
import { Client, GoalDetail, InvestmentGoal } from "../../../../../models/Client"
import { Household } from "../../../../../models/Household"
import RMJTextTooltip from "../RMJTextTooltip/RMJTextTooltip"
import clsx from "clsx"
import { FEATURE_WEALTH_AND_INCOME_RANGE } from "../../../../../config/features"
import GoalMatchIcon from "../../../assets/images/goal-match.svg"
import riskComfortIcon from "../../../assets/images/riskComfort.svg"
import CurrentPortfolioIcon from "../../../assets/images/current-portfolio.svg"
import { Options } from "../SelectPortfolio/SelectPortfolio"
import Tooltip from "./Tooltip"
import { latest } from "../../../../../lib/clients"
import { parseISO } from "date-fns"

const PortfolioTable = ({
  options,
  clientOrHousehold,
  goalType,
  forReport,
  client,
  household,
  showComfortMatch,
  showGoalMatch,
  goal
}: {
  options: Options
  clientOrHousehold?: Client | Household
  client?: Client
  household?: Household
  goalType?: InvestmentGoal
  forReport?: boolean
  showComfortMatch?: boolean
  showGoalMatch?: boolean
  goal?: GoalDetail
}) => {
  const [showCurrentInvestmentTooltip, setShowCurrentInvestmentTooltip] = useState(false)
  const otherSourcesIncome = goal?.otherIncome?.value
    ? goal.otherIncome.value
    : (clientOrHousehold?.otherSourcesRetirementIncome ?? 0) + (clientOrHousehold?.pensionInRetirement ?? 0)
  const [showGoalMatchTooltip, setShowGoalMatchTooltip] = useState(false)
  const [tooltip, setTooltip] = useState<{ show: boolean; index?: number }>({ show: false })
  const currentYear = new Date().getFullYear()
  const yearOfBirth = parseISO(client?.dob!).getFullYear()
  const clientAge = currentYear - yearOfBirth
  const householdAge = household?.members.map(({ client }) => currentYear - new Date(client?.dob!).getFullYear()).sort()
  const age = client ? clientAge : householdAge![0]

  const yearWhenMoneyRunsOut = useMemo(() => {
    if (options.disPortfolios && options.legacyPortfolios) {
      return {
        min: options.disPortfolios[0].map((val) => currentYear + (val - age)),
        runoutYear: options.disPortfolios[1].map((val) => currentYear + (val - age)),
        max: options.disPortfolios[2].map((val) => currentYear + (val - age)),
        legacyMoneyRunoutYear: options.legacyPortfolios[1].map((val) => currentYear + (val - age))
      }
    }
  }, [age, currentYear, options.disPortfolios, options.legacyPortfolios])

  const game = latest(clientOrHousehold!, "risk")

  return (
    <table className="w-full text-main-600 text-sec px-4 mt-4 mb-4">
      <thead>
        <tr>
          <th>
            <span className="sr-only">Portfolio name</span>
          </th>
          <th>
            <span className="sr-only">Portfolio match</span>
          </th>
          {game?.risk?.results && <th className="font-semibold">Risk comfort</th>}

          {goalType === "retirementDrawdown" && !!goal?.estateGoal && (
            <th
              className={clsx("font-semibold", {
                "w-40": forReport
              })}
            >
              When legacy money may be required
            </th>
          )}
          {goalType !== "retirementDrawdown" && <th className="font-semibold">Goal achievability</th>}

          <th
            className={clsx("font-semibold text-left", {
              "text-center": goalType === "retirementDrawdown",
              "w-40 text-right": forReport
            })}
          >
            {goalType === "retirementIncome"
              ? "Projected income"
              : goalType === "retirementDrawdown" && !clientOrHousehold?.estateGoal
              ? "When expected wealth may run out"
              : goalType === "retirementDrawdown"
              ? "When legacy money may run out"
              : "Projected wealth"}
          </th>
        </tr>
      </thead>
      <tbody>
        {options.tableOptions.portfolios?.map((portfolio, i) => {
          const roundedIncome =
            goalType === "retirementIncome" ? shortRound(otherSourcesIncome + portfolio.percentiles[30]) : shortRound(portfolio.percentiles[30])
          const roundedIncomeMin =
            goalType === "retirementIncome" ? shortRound(otherSourcesIncome + portfolio.percentiles[15]) : shortRound(portfolio.percentiles[15])
          const roundedIncomeMax =
            goalType === "retirementIncome" ? shortRound(otherSourcesIncome + portfolio.percentiles[85]) : shortRound(portfolio.percentiles[85])
          const postfix = {
            runoutYear: yearWhenMoneyRunsOut?.runoutYear[i]! - currentYear + age >= 120 ? "+" : "",
            min: yearWhenMoneyRunsOut?.min[i]! - currentYear + age >= 120 ? "+" : "",
            max: yearWhenMoneyRunsOut?.max[i]! - currentYear + age >= 120 ? "+" : "",
            legacyMoneyRequired: yearWhenMoneyRunsOut?.legacyMoneyRunoutYear[i]! - currentYear + age >= 120 ? "+" : ""
          }
          return (
            <tr className="border-b border-b-surface-300" key={portfolio.id}>
              <td className="font-semibold py-3">{portfolio.name}</td>
              <td>
                <div className="flex justify-end relative gap-x-2">
                  {showGoalMatch && !options.currentPortfolio?.id && portfolio.id === options.goalMatch?.id && (
                    <div
                      className={clsx({
                        "cursor-default": !FEATURE_WEALTH_AND_INCOME_RANGE,
                        "cursor-pointer": FEATURE_WEALTH_AND_INCOME_RANGE
                      })}
                      onMouseOver={FEATURE_WEALTH_AND_INCOME_RANGE ? () => setShowGoalMatchTooltip(true) : () => {}}
                      onMouseOut={() => setShowGoalMatchTooltip(false)}
                    >
                      <img src={GoalMatchIcon} alt="Goal match" />
                      {showGoalMatchTooltip && (
                        <div className="absolute w-75 ml-2 cursor-default -top-6 left-9/10">
                          <RMJTextTooltip>
                            <p className="text-sm leading-3">
                              Your Goal Match investment option has just enough expected return to achieve your financial goal, without taking too much risk
                            </p>
                          </RMJTextTooltip>
                        </div>
                      )}
                    </div>
                  )}
                  {portfolio.id === options.currentPortfolio?.id && (
                    <div
                      className={clsx({
                        "cursor-default": !FEATURE_WEALTH_AND_INCOME_RANGE,
                        "cursor-pointer": FEATURE_WEALTH_AND_INCOME_RANGE
                      })}
                      onMouseOver={FEATURE_WEALTH_AND_INCOME_RANGE ? () => setShowCurrentInvestmentTooltip(true) : () => {}}
                      onMouseOut={() => setShowCurrentInvestmentTooltip(false)}
                    >
                      <img src={CurrentPortfolioIcon} alt="Current investment" />
                      {showCurrentInvestmentTooltip && (
                        <div className="absolute w-75 ml-2 cursor-default -top-6 left-9/10">
                          <RMJTextTooltip>
                            <p className="text-sm leading-3">
                              Your current investment was selected with the aim of achieving your financial goals, without taking too much risk.
                            </p>
                          </RMJTextTooltip>
                        </div>
                      )}
                    </div>
                  )}
                  {!forReport && showComfortMatch && portfolio.id === options.comfortMatch?.id && <img src={riskComfortIcon} alt="Risk comfort" />}
                </div>
              </td>
              {game?.risk?.results && <td className="text-center py-3">{portfolio.riskComfort}%</td>}
              {goalType === "retirementDrawdown" && !!goal?.estateGoal && (
                <td
                  onMouseLeave={() => setTooltip({ show: false })}
                  onMouseEnter={() => setTooltip({ show: true, index: i })}
                  className="text-center py-3 relative cursor-pointer"
                >
                  {yearWhenMoneyRunsOut?.legacyMoneyRunoutYear[i] || 0}
                  {postfix.legacyMoneyRequired}
                  {tooltip.show && tooltip.index === i && (
                    <Tooltip key={i} moneyRunoutYear={yearWhenMoneyRunsOut?.legacyMoneyRunoutYear[i]!} client={client} household={household} />
                  )}
                </td>
              )}
              {goalType !== "retirementDrawdown" && <td className="text-center py-3">{portfolio.goalAchievability}%</td>}

              <td
                className={clsx("text-sm py-3", {
                  "w-48 text-right": forReport,
                  "text-center": !forReport && goalType === "retirementDrawdown",
                  "text-left": !forReport && goalType !== "retirementDrawdown"
                })}
              >
                {goalType === "retirementDrawdown" && yearWhenMoneyRunsOut ? (
                  <>
                    {yearWhenMoneyRunsOut?.runoutYear[i]}
                    {postfix.runoutYear} (range {yearWhenMoneyRunsOut?.min[i]}
                    {postfix.min} - {yearWhenMoneyRunsOut?.max[i]}
                    {postfix.max})
                  </>
                ) : (
                  <>
                    ${roundedIncome.value}
                    {roundedIncome.unit} (range ${roundedIncomeMin.value}
                    {roundedIncomeMin.unit} - ${roundedIncomeMax.value}
                    {roundedIncomeMax.unit})
                  </>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default PortfolioTable
