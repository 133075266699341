import { clsx } from "clsx"
import { AnimatePresence } from "framer-motion"
import { useCallback, useState } from "react"
import AmountFrequencyInput from "../../../../../components/AmountFrequencyInput/AmountFrequencyInput"
import Modal from "../../../../../components/Modal/Modal"
import TextInput from "../../../../../components/TextInput/TextInput"
import useClientFinances from "../../../../../hooks/useClientFinances"
import { latest } from "../../../../../lib/clients"
import {
  Client,
  Expense,
  ExpenseType,
  Goal,
  GoalsDetails,
  MAX_AMOUNT,
  PersonalFinancialDetails
} from "../../../../../models/Client"
import { Household } from "../../../../../models/Household"
import plus from "../../../../advisor/assets/images/plus-circle.svg"
import ExpenseRow from "./ExpenseRow"
import GoalRow from "./GoalRow"
import PFSGoalLabel from "../PFSGoalLabel"

const ExpensesList = ({
  client,
  household,
  goalsData,
  pfsData,
  isEditing,
  onChange,
  onGoalsChange
}: {
  client?: Client
  household?: Household
  goalsData?: GoalsDetails
  pfsData?: PersonalFinancialDetails
  isEditing: boolean
  onChange: (updated: PersonalFinancialDetails) => void
  onGoalsChange: (updated: GoalsDetails) => void
}) => {
  const clientOrHousehold = (client ?? household)!
  const game = latest(clientOrHousehold, "pfs")
  const lastUpdatedDate = (clientOrHousehold.pfs || game?.pfs) && (clientOrHousehold.pfs?.updatedAt ?? game?.played)
  const hasBeenUpdated = lastUpdatedDate || (clientOrHousehold.goals?.goalDetails?.length ?? 0) > 0
  const finances = useClientFinances(clientOrHousehold)

  const [shouldValidate, setShouldValidate] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [customItemAddition, setCustomItemAddition] = useState<Expense>({ type: "custom" })

  const standardExpenses: ExpenseType[] = [
    "housing",
    "utilities",
    "vehiclesTransport",
    "groceries",
    "creditCardPayments",
    "entertainment",
    "education",
    "childSupport",
    "insurance",
    "healthPersonalCare",
    "vacations",
    "giftsDonations"
  ]
  const customExpenses = pfsData?.expenses?.filter((data) => data.type === "custom") || []
  const goalTypes: Goal[] = ["retirementIncome", "buyAHome", "education", "travel", "wealthAccumulation", "createOwnGoal"]

  const handleAddOpen = useCallback(() => {
    setCustomItemAddition({
      type: "custom",
      name: "",
      amount: undefined
    })
    setIsAddModalOpen(true)
    setShouldValidate(false)
  }, [])

  const handleRemove = (id?: string) => {
    onChange({
      ...pfsData,
      expenses: pfsData?.expenses?.filter((a) => a.id !== id)
    })
  }

  return (
    <>
      <div className="w-1/2">
        <h3 className="text-sec font-semibold">Expenses</h3>
        <div>
          <h4 className="text-sec font-semibold pt-5 pb-3 px-3">Spending</h4>
          {standardExpenses
            .filter((type) => !hasBeenUpdated || isEditing || (pfsData?.expenses?.find((expense) => expense.type === type)?.amount ?? 0) > 0)
            .map((type) => (
              <ExpenseRow
                key={`standard-${type}`}
                expense={{ type: type }}
                isEditing={isEditing}
                lastUpdatedDate={!!hasBeenUpdated}
                pfsData={pfsData}
                onChange={onChange}
              />
            ))}
          {customExpenses.map((data) => (
            <ExpenseRow
              key={`custom-${data.id}`}
              expense={data}
              isEditing={isEditing}
              lastUpdatedDate={!!hasBeenUpdated}
              pfsData={pfsData}
              onChange={onChange}
              onRemove={handleRemove}
            />
          ))}
          {isEditing && (
            <button className="btn btn-text flex items-center gap-x-1 my-2 text-sec" onClick={handleAddOpen}>
              <img src={plus} alt="" aria-hidden />
              Add another expense
            </button>
          )}
          <h4 className="text-sec font-semibold pt-5 pb-3 px-3">Savings and investing</h4>
          <div className="flex flex-col gap-2">
            {goalTypes.map((type) => {
              const isMultipleType = type === "education" || type === "createOwnGoal"
              const goalsOfType = isMultipleType ? goalsData?.goalDetails?.filter((g) => g.type === type) ?? [] : []
              const renderRow = (type: Goal, id?: string) => {
                const goalDetail = goalsData?.goalDetails?.find((g) => g.id === id && g.type === type)
                return (
                  <div key={`${type}${id ?? ""}`}>
                    <GoalRow
                      goalType={type}
                      id={id}
                      label={type === "education" || type === "createOwnGoal" ? <PFSGoalLabel type={type} goalDetail={goalDetail} clientOrHousehold={household} /> : null}
                      isEditing={isEditing}
                      lastUpdatedDate={!!hasBeenUpdated}
                      goalsData={goalsData}
                      onChange={onGoalsChange}
                    />
                  </div>
                )
              }
              return isMultipleType && goalsOfType.length > 0
                ? goalsOfType.map((goalDetail) => renderRow(goalDetail.type as Goal, goalDetail.id))
                : (type as Goal) !== "createOwnGoal" // We don't show this type by default, only in the line above where the user already has a createOwnGoal goal
                ? renderRow(type)
                : []
            })}
          </div>
        </div>
        <div className="flex justify-between items-center bg-surface-100 py-4 px-3 text-sec font-semibold">
          <h4>Total expenses</h4>
          <span className={clsx(!hasBeenUpdated ? "text-main-300" : "text-red-600")}>{!hasBeenUpdated ? "$" : finances.formatted.expenses}</span>
        </div>
      </div>

      <AnimatePresence>
        {isAddModalOpen && (
          <Modal className="w-modal max-w-md" contentClassName="!px-10 w-full" handleClose={() => setIsAddModalOpen(false)}>
            <div className="w-full flex flex-col gap-6 text-left items-center">
              <div className="flex flex-col gap-y-6 w-full">
                <h2 className="w-full text-h3 font-semibold text-grey-600">Add another expense</h2>
                <TextInput
                  label="Expense name"
                  name={`add-${isAddModalOpen}`}
                  note="Maximum 30 chars"
                  onChange={(value) => setCustomItemAddition((prev) => ({ ...prev, name: value }))}
                  placeholder="Expense name"
                  value={customItemAddition?.name}
                  focusOnLoad
                  maxLength={30}
                  customNote="Maximum 30 characters"
                  error={shouldValidate && !customItemAddition.name ? "Please enter a name" : undefined}
                />
                <AmountFrequencyInput
                  id="new-custom-expense-amount"
                  min={0}
                  max={MAX_AMOUNT}
                  placeholder="0"
                  selectContainerClassName="w-40"
                  isError={shouldValidate && (!customItemAddition.amount || customItemAddition.amount > MAX_AMOUNT) ? true : false}
                  frequencyValue={customItemAddition?.frequency ?? "monthly"}
                  onFrequencyChange={(frequency) => setCustomItemAddition((prev) => ({ ...prev, frequency }))}
                  amountValue={customItemAddition?.amount}
                  onAmountChange={(value) => setCustomItemAddition((prev) => ({ ...prev, amount: value }))}
                />
              </div>
              <div className="flex flex-col gap-y-3 w-full">
                <button
                  className="btn btn-primary btn-medium"
                  onClick={() => {
                    setShouldValidate(true)
                    if (customItemAddition.name && customItemAddition.amount !== undefined) {
                      onChange({
                        ...pfsData,
                        expenses: [
                          ...(pfsData?.expenses ?? []),
                          {
                            type: "custom",
                            name: customItemAddition.name,
                            amount: customItemAddition.amount,
                            frequency: customItemAddition.frequency ?? "monthly",
                            id: `custom_` + ((pfsData?.expenses?.filter((a) => a.type === "custom").length ?? 0) + 1)
                          }
                        ]
                      })
                      setIsAddModalOpen(false)
                    }
                  }}
                >
                  Add
                </button>
                <button className="btn btn-secondary btn-medium" onClick={() => setIsAddModalOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default ExpensesList
