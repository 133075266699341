import { useContext } from "react"
import { FirmContext } from "../../../../contexts/FirmContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Client, PortfolioMapping } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import DecisionsTable from "../../../rmjourney/yourDecisions/components/DecisionsTable/DecisionsTable"
import Container from "../components/Container"
import Footer from "../components/Footer"
import leaves from "./assets/leaves.svg"
import { Trans } from "@lingui/macro"
import { tt } from "../../../../lib/translations"
import { comfortMatch, latest } from "../../../../lib/clients"
import { riskAttitudeDetails } from "../../../rmjourney/riskAttitude/riskAttitudeAttributes"
import { lossSensitivityDetails } from "../../../rmjourney/lossSensitivity/lossSensitivityAttributes"
import riskIcon from "./assets/speed.svg"
import sensitivityIcon from "./assets/returns.svg"
import { rtScores, sensitvityToLossText } from "../RiskProfilePage/assets/profileTypes"
import decisionsIcon from "./assets/check-circle.svg"
import { clsx } from "clsx"
import { AVATAR_ICON_BG_CLASSES } from "../../../rmjourney/portfolioComfort/RMJPortfolioComfort"

const DecisionPatternPage = ({ page, client, household }: { page: number; client: Client; household: Household }) => {
  const theme = useTheme()
  const { firm } = useContext(FirmContext)
  const clientOrHousehold = client || household
  const game = latest(clientOrHousehold, "risk")
  const currentPortfolio = game ? comfortMatch(game) : ({} as PortfolioMapping)
  const portfolioName = currentPortfolio.portfolio.name
  const inconsistent = (game?.risk.results?.R.cceiScore ?? 0) < 75
  const riskAttitudeMessages = riskAttitudeDetails(client, household)
  const lossSensitivityMessages = lossSensitivityDetails(client, household)

  const firmMessageLink = (
    <a href={tt({ id: "reports-firm-message-link" })} className="link" target="_blank" rel="noreferrer">
      <Trans id="reports-firm-message-link-text">book online</Trans>
    </a>
  )

  return (
    <Container className="decision-pattern-page flex flex-col justify-between relative">
      <div className="decision-pattern-wrapper flex-auto px-10 pt-10">
        {theme.reports?.decisionPatternPage?.preferencesSection?.show && (
          <div>
            <h2 className="report-decision-pattern-preferences-title mb-2 text-main-600 text-sec tracking-wide uppercase">
              <Trans id="report-decision-pattern-preferences-title">Your preferences</Trans>
            </h2>
            <h3 className="font-semibold text-main-600 text-h4">
              {tt({ id: `report-decision-pattern-preferences-${portfolioName.replaceAll(/\s+/g, "_").toLowerCase()}-subtitle` })}
            </h3>
            <div className="decision-pattern-description flex flex-col gap-6 mt-6 mb-22">
              <div className="flex gap-x-5">
                <img src={theme.reports.decisionPatternPage.preferencesSection.icon} alt="" aria-hidden />
                <p className="report-preferences-section-description text-main-600">
                  {tt({ id: `report-preferences-section-${portfolioName.toLowerCase()}-description` })}
                </p>
              </div>
              {theme.reports.decisionPatternPage.preferencesSection.summary?.show && (
                <>
                  <hr className="decision-pattern-preferences-hr text-surface-400" />
                  <div className="decision-pattern-preferences-summary">
                    <div className="flex gap-4">
                      <div className={clsx("flex flex-col gap-1", inconsistent ? "w-1/2" : "w-1/3")}>
                        <div className="flex gap-2">
                          <img src={riskIcon} alt="" aria-hidden />
                          <h4 className="font-semibold leading-5">Attitude to Risk</h4>
                        </div>
                        <p>{riskAttitudeMessages?.title}.</p>
                      </div>
                      <div className={clsx("flex flex-col gap-1", inconsistent ? "w-1/2" : "w-1/3")}>
                        <div className="flex gap-2">
                          <img src={sensitivityIcon} alt="" aria-hidden />
                          <h4 className="font-semibold leading-5">Market volatility</h4>
                        </div>
                        <p>{lossSensitivityMessages?.title}.</p>
                      </div>
                      {!inconsistent && (
                        <div className="flex flex-col gap-1 w-1/3">
                          <div className="flex gap-2">
                            <img src={decisionsIcon} alt="" aria-hidden />
                            <h4 className="font-semibold leading-5">Decision check</h4>
                          </div>
                          <p>You have a good understanding of risk and reward.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {theme.reports?.decisionPatternPage?.riskProfileSection?.show && (
          <div className="font-semibold">
            <h2 className="mb-1 text-main-600 text-sm tracking-wide uppercase">Your Risk Profile</h2>
            <h3 className="font-semibold text-main-600">{client ? "What your results tell us about you" : "What your results tell us about each of you"}</h3>
            {household ? (
              <>
                <div className="flex gap-x-4 mt-8">
                  {household.members.map(({ client }, i) => {
                    const game = latest(client, "risk")
                    return (
                      <div key={client._id} className="h-full text-sm w-86">
                        <div className="flex items-center gap-2 h-10">
                          <div className={clsx("w-6 h-6 flex justify-center items-center text-white rounded-full", AVATAR_ICON_BG_CLASSES[i])}>
                            {client.firstName.charAt(0)}
                          </div>
                          <div className="font-normal text-main-500">{client.firstName}</div>
                        </div>
                        {game?.risk.results?.R && (
                          <>
                            <div className="text-main-600">
                              <h4 className="font-bold">{rtScores(game.risk.results.R.riskTolerance).title}</h4>
                              <p className="mt-2 leading-tight">{rtScores(game.risk.results.R.riskTolerance).body}</p>
                            </div>
                            <div className="mt-4 text-main-600">
                              <h4 className="font-bold">{sensitvityToLossText(game.risk.results.R.lossAversion).title}</h4>
                              <p className="mt-2 leading-tight">{sensitvityToLossText(game.risk.results.R.lossAversion).body}</p>
                            </div>
                          </>
                        )}
                      </div>
                    )
                  })}
                </div>
                <div className="text-sm mt-4 text-main-600">
                  <h4 className="font-bold mb-2">Considerations</h4>
                  <p className="mb-6">You may need to adjust your risk level as we consider your goals and constraints in the planning process.</p>
                </div>
              </>
            ) : (
              game?.risk.results?.R && (
                <div className="text-sm mt-8 text-main-600">
                  <h4 className="font-bold mb-2">{rtScores(game.risk.results.R.riskTolerance).title}</h4>
                  <p className="mb-6">{rtScores(game.risk.results.R.riskTolerance).body}</p>

                  <h4 className="font-bold mb-2">{sensitvityToLossText(game.risk.results.R.lossAversion).title}</h4>
                  <p className="mb-6">{sensitvityToLossText(game.risk.results.R.lossAversion).body}</p>

                  <h4 className="font-bold mb-2">Considerations</h4>
                  <p className="mb-6">You may need to adjust your risk level as we consider your goals and constraints in the planning process.</p>
                </div>
              )
            )}
          </div>
        )}

        {theme.reports?.decisionPatternPage?.decisionsTable?.show && (
          <>
            <h2 className="report-decision-pattern-title mt-21 mb-2 text-main-600 text-sm tracking-wide uppercase">
              <Trans id="report-decision-pattern-title">Your Decision Pattern</Trans>
            </h2>
            <div className="flex flex-col gap-6">
              <h3 className="font-semibold text-main-600 text-p">
                {theme.reports?.decisionPatternPage?.showDescriptionByConsistency ? (
                  inconsistent ? (
                    <Trans id="report-decision-pattern-low-decision-consistency-title">Here are the decisions you made in the activity</Trans>
                  ) : (
                    <Trans id="report-decision-pattern-high-decision-consistency-title">You have a strong intuition when managing risk and reward</Trans>
                  )
                ) : (
                  <Trans id="report-decision-pattern-decision-consistency-title">You have a strong intuition when managing risk and reward</Trans>
                )}
              </h3>
              {theme.reports?.decisionPatternPage?.description?.show && (
                <div className="flex flex-col gap-2">
                  <p>
                    Your decision pattern reflects the percentage of your investible amount you put at risk in each round of the activity given the hypothetical
                    returns available. Decisions are sorted from highest potential return available to lowest.
                  </p>
                  <p>The green numbers are the potential gain and the red numbers are the potential loss you selected in each round.</p>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full text-main-600 mt-7">
              {household && (
                <DecisionsTable
                  enableAnimations={false}
                  forReport={true}
                  clients={household.members.map(({ client }) => client)}
                  showNamesInFooter={theme.reports?.decisionPatternPage?.decisionsTable?.showNamesInFooter}
                />
              )}
              {client && (
                <DecisionsTable
                  enableAnimations={false}
                  forReport={true}
                  clients={[client]}
                  showNamesInFooter={theme.reports?.decisionPatternPage?.decisionsTable?.showNamesInFooter}
                  showCurrency={theme.reports?.decisionPatternPage?.decisionsTable?.showCurrency}
                />
              )}
            </div>
          </>
        )}
      </div>

      {theme.reports?.decisionPatternPage?.firmMessage?.show && (
        <div className="flex flex-col gap-8 pt-14 px-10">
          <div className="flex flex-col gap-3">
            <p>Thank you for completing the investment style activity. We're excited to share with you a deeper understanding of your investment style.</p>
            <p>
              These insights will help you make informed investment choices that align with your goals and risk preferences, so you can grow your money faster
              and stay on track to achieve your financial objectives.
            </p>
          </div>
          <hr className="decision-pattern-preferences-hr text-surface-400" />
          <div className="flex flex-col gap-3">
            <h2 className="font-semibold">How we can help</h2>
            <p>
              <Trans id="reports-firm-message-1">
                Your relationship manager is here to help you navigate the world of investing and provide personalised guidance tailored to your unique needs
                and preferences.
              </Trans>
            </p>
            <p>
              <Trans id="reports-firm-message-2">
                If you've requested a follow-up discussion, your relationship manager will be in touch shortly to schedule a meeting.
              </Trans>
            </p>
            <p>
              <Trans id="reports-firm-message-3">
                Otherwise, feel free to reach out to your relationship manager at anytime to discuss your investment style and financial goals. You can schedule
                a meeting by {firmMessageLink}.
              </Trans>
            </p>
          </div>
          {theme.clientLogo && <img src={theme.clientLogo} className="max-w-21" alt="Firm logo" />}
        </div>
      )}
      {theme.reports?.decisionPatternPage?.scienceFooter?.show ? (
        <div className="relative h-full flex items-end">
          <div className="w-full h-[252px] bg-neutral-50">
            <div className="mx-9 h-full">
              <div className="flex mx-10 my-12">
                <img className="w-20 h-20 self-start" src={theme.reports?.decisionPatternPage?.scienceFooter.icon ?? leaves} alt="science icon" aria-hidden />
                <div className="report-decision-science-text-container flex flex-col ml-6 justify-center self-start">
                  <h2 className="font-semibold text-sec leading-5">The science behind your results</h2>
                  <p className="font-normal text-sec leading-5">
                    This activity was developed by a decision science, technology and research team of leading economists. It is underpinned by internationally
                    validated decision science and behavioral economics research.
                  </p>
                </div>
              </div>
              <Footer pageNum={page} client={client} household={household} firm={firm} className="relative" />
            </div>
          </div>
        </div>
      ) : (
        <div className="relative h-full flex items-end px-10">
          <Footer pageNum={page} client={client} household={household} firm={firm} className="relative" />
        </div>
      )}
    </Container>
  )
}

export default DecisionPatternPage
