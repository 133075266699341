import format from "date-fns/format"
import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import { NewClientRequest, createClient } from "../../api/clients"
import CreateClientForm from "../../components/CreateClient/CreateClientForm"
import DollarIcon from "../../components/DolarIcon/DollarIcon"
import NumberInput from "../../components/NumberInput/NumberInput"
import RadioButton from "../../components/RadioButton/RadioButton"
import SelectAdvisor from "../../components/SelectAdvisor/SelectAdvisor"
import Switch from "../../components/Switch/Switch"
import { FirmContext } from "../../contexts/FirmContext"
import { useTheme } from "../../contexts/ThemeContext"
import useMyAdvisor from "../../hooks/useMyAdvisor"
import useTrackViewEvent from "../../hooks/useTrackViewEvent"
import { validate } from "../../lib/clients"
import { Advisor } from "../../models/Advisor"
import ErrorMessage from "../../components/Error/ErrorMessage"
import { Trans } from "@lingui/react"

export type NewClient = {
  firstName?: string
  lastName?: string
  email?: string
  dob?: Date
  dobRaw?: string
  investmentAmount?: number
  currentAnnualIncome?: number
  retirementIncomeGoal?: number
  annualInvestmentContribution?: number
  otherSourcesRetirementIncome?: number
  retirementAge?: number
  existingClient?: boolean
  currentPortfolio?: string
  advisor?: Advisor
}

export type ClientErrors = {
  firstName?: string
  lastName?: string
  email?: string
  dob?: string
  investmentAmount?: string
  currentAnnualIncome?: string
  retirementIncomeGoal?: string
  annualInvestmentContribution?: string
  otherSourcesRetirementIncome?: string
  retirementAge?: string
  advisor?: string
}

const CreateIndividualClientPage = ({ onCreate, myAdvisors }: { onCreate: () => void, myAdvisors?: Advisor[] }) => {
  const { firm } = useContext(FirmContext)
  const trackViewEvent = useTrackViewEvent()
  const [newClient, setNewClient] = useState<NewClient>({})
  const [errors, setErrors] = useState<ClientErrors>({})
  const [createError, setCreateError] = useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [createClientInProgress, setCreateClientInProgress] = useState<boolean>(false)
  const currentPortfolioRef = useRef<HTMLDivElement>(null)
  const { advisor, selectAdvisor } = useMyAdvisor(myAdvisors)
  const primaryAdviserRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const theme = useTheme()
  const handleCreateClient = () => {
    trackViewEvent({ action: "click", category: "client_creation", label: "create_client_btn" })
    setShouldValidate(true)
    const errs = validate(newClient, firm!, advisor?._id)
    if (!errs || Object.keys(errs).length === 0 || Object.values(errs).every((x) => x === null || x === "" || x === undefined)) {
      setCreateError(false)
      onCreate()
      const data: NewClientRequest = {
        ...newClient,
        dob: newClient.dob ? format(newClient.dob, "yyyy-MM-dd") : undefined
      }
      createClient(advisor!._id, data)
        .then((client) => {
          navigate(`/clients/${client._id}`, { replace: true })
        })
        .catch((err) => {
          console.log("error creating client", err)
          setCreateClientInProgress(false)
          setCreateError(true)
        })
    }
  }

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validate(newClient, firm!, advisor?._id)
      setErrors(nextErrors)
    }
  }, [advisor, firm, newClient, shouldValidate])

  useEffect(() => {
    if (newClient?.existingClient) {
      currentPortfolioRef?.current?.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [newClient?.existingClient])

  useEffect(() => {
    if (errors && Object.keys(errors).length === 1 && errors.advisor) {
      primaryAdviserRef?.current?.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [errors])

  return (
    <>
      <div className="grid grid-cols-12 gap-x-4 gap-y-6 grow justify-center m-auto auto-rows-max overflow-y-auto w-full mb-4 text-main-600">
        <CreateClientForm
          client={newClient}
          errors={errors}
          onFirstNameChange={(value) => setNewClient((old) => ({ ...old, firstName: value }))}
          onLastNameChange={(value) => setNewClient((old) => ({ ...old, lastName: value }))}
          onEmailChange={(value) => setNewClient((old) => ({ ...old, email: value }))}
          onDobChange={(value: Date | null) => {
            setNewClient((old) => ({ ...old, dob: value ? value : undefined }))
          }}
          onDobRawChange={(value: string) => {
            setNewClient((old) => ({ ...old, dobRaw: value }))
          }}
        >
          <NumberInput
            className="col-start-4 col-span-6"
            label="Investment amount"
            name="investmentAmount"
            value={newClient.investmentAmount}
            onChange={(valueAsNumber) => {
              setNewClient((old) => ({ ...old, investmentAmount: valueAsNumber }))
            }}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            error={errors.investmentAmount}
          />
          {myAdvisors && myAdvisors.length > 1 && (
            <div className="col-start-4 col-span-6" ref={primaryAdviserRef}>
              <SelectAdvisor
                error={errors?.advisor}
                label={<span>Select primary adviser to manage this client</span>}
                advisors={myAdvisors}
                onSelect={(value: Advisor) => {
                  selectAdvisor(value)
                }}
                selectedAdvisor={advisor}
                verticalAlign="top"
              />
            </div>
          )}
          {firm?.uiConfig?.CURRENT_INCOME && (
            <NumberInput
              className="col-start-4 col-span-6"
              label="Current annual income (before tax)"
              name="currentAnnualIncome"
              value={newClient.currentAnnualIncome}
              onChange={(valueAsNumber) => {
                setNewClient((old) => ({ ...old, currentAnnualIncome: valueAsNumber }))
              }}
              prefix={
                <div className="pl-3">
                  <DollarIcon />
                </div>
              }
              suffix="p/y"
              error={errors.currentAnnualIncome}
            />
          )}
          {firm?.uiConfig?.RETIREMENT_GOAL_ATTRIBUTES && (
            <>
              <NumberInput
                className="col-start-4 col-span-6"
                label="Retirement income goal"
                name="retirementIncomeGoal"
                value={newClient.retirementIncomeGoal}
                onChange={(valueAsNumber) => {
                  setNewClient((old) => ({ ...old, retirementIncomeGoal: valueAsNumber }))
                }}
                prefix={
                  <div className="pl-3">
                    <DollarIcon />
                  </div>
                }
                suffix="p/y"
                error={errors.retirementIncomeGoal}
              />
              <NumberInput
                className="col-start-4 col-span-6"
                label="Annual investment contribution"
                name="annualInvestmentContribution"
                value={newClient.annualInvestmentContribution}
                onChange={(valueAsNumber) => {
                  setNewClient((old) => ({ ...old, annualInvestmentContribution: valueAsNumber }))
                }}
                prefix={
                  <div className="pl-3">
                    <DollarIcon />
                  </div>
                }
                suffix="p/y"
                error={errors.annualInvestmentContribution}
              />
              <NumberInput
                className="col-start-4 col-span-6"
                label="Annual retirement income from other sources"
                name="otherSourcesRetirementIncome"
                value={newClient.otherSourcesRetirementIncome}
                onChange={(valueAsNumber) => {
                  setNewClient((old) => ({ ...old, otherSourcesRetirementIncome: valueAsNumber }))
                }}
                prefix={
                  <div className="pl-3">
                    <DollarIcon />
                  </div>
                }
                suffix="p/y"
                error={errors.otherSourcesRetirementIncome}
              />
              <NumberInput
                className="col-start-4 col-span-3"
                label="Expected retirement age"
                name="retirementAge"
                value={newClient.retirementAge}
                onChange={(valueAsNumber) => {
                  setNewClient((old) => ({ ...old, retirementAge: valueAsNumber }))
                }}
                suffix="years"
                error={errors.retirementAge}
              />
            </>
          )}

          {theme.pages.createClient.individual?.formFields.showExistingClientSwitch && <div className="col-start-4 col-span-full">
            <Switch
              checked={newClient.existingClient}
              aria-checked={newClient.existingClient}
              id={"existing-client"}
              label={<Trans id="create-individual-client-existing-client-label" />}
              onChange={() => {
                setNewClient((old) => ({ ...old, existingClient: !old.existingClient, currentPortfolio: undefined }))
              }}
            />
          </div>}
          {newClient.existingClient && (
            <div className="col-start-4 col-span-6" ref={currentPortfolioRef}>
              <fieldset>
                <legend className="mb-2">Add your client's selected investment</legend>
                <RadioButton
                  checked={!newClient.currentPortfolio}
                  aria-checked={!newClient.currentPortfolio}
                  id="no-portfolio"
                  label="No selected investment"
                  name="currentPortfolio"
                  onChange={() => {
                    setNewClient((old) => ({ ...old, currentPortfolio: undefined }))
                  }}
                  className="mb-2"
                />
                {firm?.modelPortfolios?.map((modelPortfolio) => (
                  <RadioButton
                    key={modelPortfolio.id}
                    checked={newClient.currentPortfolio === modelPortfolio.id}
                    aria-checked={newClient.currentPortfolio === modelPortfolio.id}
                    id={modelPortfolio.id}
                    label={modelPortfolio.name}
                    name="currentPortfolio"
                    onChange={() => {
                      setNewClient((old) => ({ ...old, currentPortfolio: modelPortfolio.id }))
                    }}
                    className="mb-2"
                  />
                ))}
              </fieldset>
            </div>
          )}
        </CreateClientForm>
      </div>
      <div className="footer shadow-md pb-12 grid grid-cols-12 gap-x-4 col-start-1 col-span-full">
        <button type="button" className="btn btn-medium btn-secondary col-start-5 col-span-2 mt-4" onClick={() => navigate(-1)}>
          Cancel
        </button>
        <button className="btn btn-primary btn-medium col-start-7 col-span-2 mt-4" onClick={handleCreateClient} disabled={createClientInProgress}>
          Create client
        </button>
        <div role="alert">
          {createError &&
            <div className="col-span-12 text-center">
              <ErrorMessage id="create-client-page" message="Error creating client, please try again." />
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default CreateIndividualClientPage
