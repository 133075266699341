import React from "react"
import { Options } from "../../rmjourney/goalExplorer/components/SelectPortfolio/SelectPortfolio"
import ProjectorChartPage from "./ProjectorChartPage"
import ProjectorTablePage from "./ProjectorTablePage"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"

interface GoalProjectorProps {
  goalOptions: Options[]
  client: Client
  household: Household
  page: number
}

const GoalProjectorPage = ({ goalOptions, page, client, household }: GoalProjectorProps) => {
  return (
    <>
      {goalOptions?.map((option, index) => (
        <React.Fragment key={`chart-${option?.goal?.type}-${index}`}>
          <ProjectorChartPage page={++page} client={client} household={household} goal={option.goal} options={option} />
          <ProjectorTablePage page={++page} client={client} household={household} goal={option.goal} options={option} />
        </React.Fragment>
      ))}
    </>
  )
}

export default GoalProjectorPage
