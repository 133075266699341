import { Trans } from "@lingui/react"
import AmountFrequencyInput from "../../../../../components/AmountFrequencyInput/AmountFrequencyInput"
import { Goal, GoalsDetails } from "../../../../../models/Client"
import PlaceholderRow from "../AssetsList/PlaceholderRow"
import { tt } from "../../../../../lib/translations"
import useNumberFormatter from "../../../../../hooks/useNumberFormatter"
import { useTheme } from "../../../../../contexts/ThemeContext"
import ErrorMessage from "../../../../../components/Error/ErrorMessage"
import { clsx } from "clsx"

interface Props {
  goalType: Goal
  id?: string
  isEditing: boolean
  label?: JSX.Element | string | null
  lastUpdatedDate?: boolean
  goalsData?: GoalsDetails
  onChange: (updated: GoalsDetails) => void
}

const GoalRow: React.FC<Props> = ({ goalType, id, isEditing, label, lastUpdatedDate, goalsData, onChange }) => {
  const theme = useTheme()
  const numberFormatter = useNumberFormatter(theme.defaultLocale || "en-US")
  const data = goalsData?.goalDetails?.find((goal) => goal.type === goalType && (goal.id === id || (!goal.id && !id)))?.contributions
  const MAX_AMOUNT = 1000000
  const error = data?.value && data?.value > MAX_AMOUNT ? tt({ id: "pfs-results-page-input-amount-range-error-text" }) : undefined

  if (!goalsData?.goalDetails?.length && !isEditing) {
    return <PlaceholderRow type={goalType} isGoal />
  }

  return (
    <div className="flex flex-col items-center p-3 border-b border-b-surface-300 text-sec">
      <div className="flex justify-between gap-x-2 items-center w-full">
        <label className="whitespace-nowrap overflow-hidden text-ellipsis" htmlFor={`${goalType}${id ?? ""}`}>
          {label ?? <Trans id={`pfs-title-goal-${goalType}`} />}
        </label>
        {isEditing ? (
          <div className="flex gap-x-2 items-center">
            <AmountFrequencyInput
              id={`${goalType}${id ?? ""}`}
              className="mr-8"
              inputClassName="!w-25"
              inputFieldClassName="text-sec"
              selectClassName="w-[87px]"
              frequencyValue={data?.frequency ?? "monthly"}
              onFrequencyChange={(frequency) =>
                onChange({
                  ...goalsData,
                  goalDetails: !goalsData?.goalDetails?.find((a) => a.type === goalType && (a.id === id || (!a.id && !id)))
                    ? [
                        ...(goalsData?.goalDetails ?? []),
                        { type: goalType, id, contributions: { frequency, value: data?.value ?? 0 } } // Preserve value
                      ]
                    : goalsData?.goalDetails?.map((prevGoal) =>
                        prevGoal.type === goalType && (prevGoal.id === id || (!prevGoal.id && !id))
                          ? { ...prevGoal, contributions: { ...prevGoal.contributions, frequency } } // Preserve value
                          : prevGoal
                      )
                })
              }
              amountValue={data?.value}
              onAmountChange={(amount) =>
                onChange({
                  ...goalsData,
                  goalDetails: !goalsData?.goalDetails?.find((a) => a.type === goalType && (a.id === id || (!a.id && !id)))
                    ? [...(goalsData?.goalDetails ?? []), { type: goalType, contributions: { value: amount, frequency: data?.frequency ?? "monthly" } }]
                    : goalsData?.goalDetails?.map((prevGoal) =>
                        prevGoal.type === goalType && (prevGoal.id === id || (!prevGoal.id && !id))
                          ? { ...prevGoal, contributions: { value: amount, frequency: data?.frequency ?? "monthly" } }
                          : prevGoal
                      )
                })
              }
              isError={!!error}
            />
          </div>
        ) : (
          <span className={clsx(!lastUpdatedDate ? "text-main-300" : "text-red-600")}>
            <Trans id="currency-symbol">$</Trans>
            {Number.isFinite(data?.value) && `${numberFormatter.format(data!.value!)}${tt({ id: `${data?.frequency ?? "monthly"}-option-postfix` })}`}
          </span>
        )}
      </div>
      <div role="alert" className="w-full">
        {error && isEditing && <ErrorMessage id="goal-row" message={error} />}
      </div>
    </div>
  )
}

export default GoalRow
