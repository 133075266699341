import React from "react"
import Container from "../components/Container"
import Footer from "../components/Footer"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { Firm } from "../../../../models/Firm"
import { Trans } from "@lingui/macro"
import GoalProjectionChart from "../components/GoalProjection/GoalProjectionChart"
import { useTheme } from "../../../../contexts/ThemeContext"
import leaves from "./assets/leaves.svg"

const SmartInvestmentPage = ({ page, client, household, firm }: { page: number; client: Client; household: Household; firm: Firm }) => {
  const theme = useTheme()
  const clientOrHousehold = client ?? household
  const investmentTimeframeText = clientOrHousehold.investmentTimeframe
    ? clientOrHousehold?.investmentTimeframe < 1
      ? "You told us you typically stay invested for less than 1 year."
      : clientOrHousehold?.investmentTimeframe > 1 && clientOrHousehold?.investmentTimeframe < 3
      ? "You told us you typically stay invested for 1 to 3 years."
      : clientOrHousehold?.investmentTimeframe > 3 && clientOrHousehold?.investmentTimeframe < 7
      ? "You told us you typically stay invested for 3 to 7 years."
      : "You told us you typically stay invested for more than 7 years."
    : ""

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="flex flex-col gap-6 px-10">
        <div>
          <h2 className="report-smart-investment-page-title mb-2 text-main-600 text-sm font-semibold tracking-wide uppercase">
            <Trans id="report-smart-investment-page-title">Are your investments aligned with your style?</Trans>
          </h2>
          <h3 className="font-semibold text-main-600 text-h4 report-smart-investment-page-subtitle">Growing your wealth</h3>
        </div>
        <div className="flex flex-col gap-3 text-sec leading-tight text-main-500">
          <p className="report-smart-investment-page-description">
            <Trans id="report-smart-investment-page-description">Your relationship manager can help you align with your investing style.</Trans>
          </p>
          <p className="report-smart-investment-page-description">
            <Trans id="report-smart-investment-page-description2">
              The investment choices you make can significantly impact your growth outcomes. By aligning to your investment style, you are more likely to stay on plan and achieve your financial goals.
            </Trans>
          </p>
          <p className="report-smart-investment-page-description">
            <Trans id="report-smart-investment-page-description3">
              By aligning to your investment style, you are more likely to stay on plan and achieve your financial goals.
            </Trans>
          </p>
        </div>
        <div className="h-70">
          <p className="report-smart-projection-chart-title text-sec font-medium text-center">
            <Trans id="report-smart-projection-chart-title">Projected growth over 10 years</Trans>
          </p>
          <GoalProjectionChart
            forReport
            customDatapoints={[
              { x: 2035, y: 155297 },
              { x: 2035, y: 243333 }
            ]}
            chartPadding={{ top: 30, bottom: 60, left: 100, right: 10 }}
            showSmartProjection={true}
            showGoalTargetyear={false}
            showCurrencyUnit={false}
            tickCounts={{ xAxisTickCount: 5 }}
            customYAxisTicks={[0, 50000, 100000, 150000, 200000, 250000]}
            isFormatCurrency={true}
            graphData={{
              goal: 151610.26360704,
              portfolioExpectedValues: [
                100000, 104500, 109203, 114117, 119252, 124618, 130226, 136086, 142210, 148610, 155297
              ],
              portfolioHighValues: [
                100000, 109300, 119465, 130575, 142719, 155991, 170499, 186355, 203686, 222629, 243333
              ],
              portfolioLowValues: [],
              portfolioYearValues: [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036]
            }}
            shouldHideWealthGoalDetails={true}
            formatDatapoint={"long"}
          />
        </div>
        <div className="flex justify-center gap-x-2">
          <p className="w-auto h-full flex justify-center items-center gap-x-1 text-sm">
            <span className="report-smart-projection-chart-legend-1 w-1.5 h-1.5 bg-interactive-600 rounded-full" />
            <Trans id="report-smart-projection-chart-legend-1">Moderate</Trans>
          </p>
          <p className="w-auto h-full flex justify-center items-center gap-x-1 text-sm">
            <span className="report-smart-projection-chart-legend-2 w-1.5 h-1.5 bg-interactive-600 rounded-full" />
            <Trans id="report-smart-projection-chart-legend-2">Defensive</Trans>
          </p>
        </div>
        <p className="report-smart-investment-page-disclaimer text-xs text-main-400">These projections are designed for illustrative purposes only.</p>
      </div>
      <div className="report-smart-investment-timeframe-notes mx-10 text-sec mt-8 bg-surface-100 rounded-2 shadow">
        <p className="p-4">
          <Trans id="report-smart-investment-timeframe-notes">
            {investmentTimeframeText} Your relationship manager can help tailor your investment strategy to match your investment timeframe and financial goals
          </Trans>
        </p>
      </div>
      {theme.reports?.smartInvestmentPage?.scienceFooter?.show ? (
        <div className="relative h-full flex items-end">
          <div className="w-full h-[252px] bg-neutral-50">
            <div className="mx-9 h-full flex flex-col justify-between">
              <div className="flex mx-10 mt-12">
                <img className="w-18 h-18 my-auto self-start" src={theme.reports?.smartInvestmentPage?.scienceFooter.icon ?? leaves} alt="science icon" aria-hidden />
                <div className="report-decision-science-text-container flex flex-col ml-6 justify-center self-start">
                  <h2 className="font-semibold text-sec leading-5">The science behind your results</h2>
                  <p className="font-normal text-sec leading-5">
                    This activity was developed by a decision science, technology and research team of leading economists. It is underpinned by internationally
                    validated decision science and behavioral economics research.
                  </p>
                </div>
              </div>
              <Footer pageNum={page} client={client} household={household} firm={firm} className="relative" />
            </div>
          </div>
        </div>
      ) : (
        <div className="relative h-full flex items-end">
          <Footer pageNum={page} client={client} household={household} firm={firm} className="relative" />
        </div>
      )}
    </Container>
  )
}

export default SmartInvestmentPage
